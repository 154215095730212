import DocumentDetails from "@/components/DocumentDetails/DocumentDetails";
import ProtectedRoute from "@/components/ProtectedRoute";
import AiTaskFlow from "@/features/AiTaskFlow/AiTaskFlow";
import CaseAgendaConfig from "@/features/case-agenda/CaseAgendaPage";
import CaseSettings from "@/features/case-settings/CaseSettings";
import DataSources from "@/features/case-settings/DataSources";
import CasesList from "@/features/case/case-list/CasesList";
import { CaseOverview } from "@/features/case/CaseOverview";
import { ChatPage } from "@/features/chat/ChatPage";
import FeedPage from "@/features/feed/FeedPage";
import Permissions from "@/features/settings/Permissions";
import SettingsLayout from "@/features/settings/SettingsLayout";
import UserSettings from "@/features/settings/UserSettings";
import { WikiOverview } from "@/features/wikiPage/WikiOverview";
import NotFoundPage from "@/pages/NotFoundPage";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GravixLoader from "./GravixLoader";
import GravixMicroLoader from "./MicroLoader";
import TenantSettings from "@/features/settings/TenantSettings";
import UserManagementSettings from "@/features/settings/UserManagementSettings";

const AuthContainer = lazy(
  () => import("@/features/authentication/AuthContainer")
);
const Home = lazy(() => import("@/pages/Home"));
const Cases = lazy(() => import("@/pages/Cases"));
const Documents = lazy(() => import("@/pages/Documents"));
const CaseLayout = lazy(() => import("@/pages/CaseLayout"));
const TasksPage = lazy(() => import("@/pages/TasksPage"));
const CaseWiki = lazy(() => import("@/pages/CaseWiki"));

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<GravixLoader />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="loader"
        element={
          <>
            <GravixLoader />
            <GravixMicroLoader />
          </>
        }
      />

      <Route
        path="cases"
        element={
          <ProtectedRoute>
            <Suspense fallback={<GravixLoader />}>
              <Cases />
            </Suspense>
          </ProtectedRoute>
        }
      >
        <Route index element={<CasesList />} />
        <Route
          path=":caseId"
          element={
            <Suspense fallback={<GravixLoader />}>
              <CaseLayout />
            </Suspense>
          }
        >
          <Route index element={<CaseOverview />} />
          <Route path="agenda" element={<CaseAgendaConfig />} />
          <Route path="feed" element={<FeedPage />}>
            <Route path=":feedType" element={<FeedPage />} />
          </Route>
          <Route path="wiki" element={<WikiOverview />} />
          <Route
            path="wiki/:entityId"
            element={
              <Suspense fallback={<GravixLoader />}>
                <CaseWiki />
              </Suspense>
            }
          />
          <Route
            path="documents"
            element={
              <Suspense fallback={<GravixLoader />}>
                <Documents />
              </Suspense>
            }
          />
          <Route path="documents/:documentId" element={<DocumentDetails />} />
          <Route
            path="tasks"
            element={
              <Suspense fallback={<GravixLoader />}>
                <TasksPage />
              </Suspense>
            }
          />
          <Route path="chat" element={<ChatPage />}>
            <Route path=":chatId" element={<ChatPage />} />
          </Route>
          <Route path="settings" element={<CaseSettings />}>
            <Route index element={<Navigate to="data-sources" replace />} />
            <Route path="data-sources" element={<DataSources />} />
          </Route>
          {/* <Route path="claims" element={<ClaimsTable />} /> */}
          <Route path="ai-task-flow" element={<AiTaskFlow />} />
        </Route>
      </Route>

      <Route
        path="settings"
        element={
          <ProtectedRoute>
            <SettingsLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="user" replace />} />
        <Route path="user" element={<UserSettings />} />
        <Route path="case-permissions" element={<Permissions />} />
        <Route path="user-management" element={<UserManagementSettings />} />
        <Route path="tenant" element={<TenantSettings />} />
      </Route>

      <Route
        path="login"
        element={
          <Suspense fallback={<GravixLoader />}>
            <AuthContainer />
          </Suspense>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
