import React from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { AgendaDetails } from "@/store/agendaStore";

interface CaseDetailsProps {
  agendaDetails: AgendaDetails;
  handleAgendaDetailChange: (field: keyof AgendaDetails, value: string) => void;
}

const CaseDetails: React.FC<CaseDetailsProps> = ({
  agendaDetails,
  handleAgendaDetailChange,
}) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Client Name
        </label>
        <Input
          value={agendaDetails.clientName}
          onChange={(e) =>
            handleAgendaDetailChange("clientName", e.target.value)
          }
          placeholder="Enter client's name"
          className="w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Case Description
        </label>
        <Textarea
          value={agendaDetails.description}
          onChange={(e) =>
            handleAgendaDetailChange("description", e.target.value)
          }
          placeholder="Briefly describe the case"
          className="w-full"
          rows={3}
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Main Claim
        </label>
        <Input
          value={agendaDetails.mainClaim}
          onChange={(e) =>
            handleAgendaDetailChange("mainClaim", e.target.value)
          }
          placeholder="What's the primary claim?"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default CaseDetails;
