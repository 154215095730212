import React from "react";
import { useParams } from "react-router-dom";
import { useDocumentStore } from "@/store/documentStore";
import QuickStatsCard from "./QuickStatsCard";
import StorageBreakdownCard from "./StorageBreakdownCard";
import UploadCard from "./UploadCard";

interface StorageItem {
  name: string;
  value: number;
  color: string;
}

const DataSources: React.FC = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { uploadResults } = useDocumentStore();

  // Mock data for the storage breakdown
  const storageData: StorageItem[] = [
    { name: "PDF", value: 400, color: "#1A365D" },
    { name: "DOCX", value: 300, color: "#00A9A5" },
    { name: "TXT", value: 200, color: "#4A5568" },
    { name: "Other", value: 100, color: "#F0F4F8" },
  ];

  // Convert upload results to file format for QuickStatsCard
  const files = uploadResults.map((result, index) => ({
    id: index + 1,
    name: result.filename,
    type: result.filename.split(".").pop()?.toUpperCase() || "Unknown",
    size: "N/A",
    uploadDate: new Date().toISOString().split("T")[0], // Current date as upload date
  }));

  if (!caseId) {
    return <div>Error: No case ID provided</div>;
  }

  return (
    <div className="bg-light-gray flex-grow">
      <h3 className="text-xl font-bold text-primary mb-6">Data Sources</h3>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <UploadCard caseId={caseId} />
        <StorageBreakdownCard storageData={storageData} />
        <QuickStatsCard files={files} />
      </div>
    </div>
  );
};

export default DataSources;
