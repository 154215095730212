import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAgendaStore } from "@/store/agendaStore";
import { Briefcase, FileText, Scale } from "lucide-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CaseDetails from "./AgendaDetails";
import CaseDocuments from "./AgendaDocuments";
import CaseStrategy from "./AgendaStrategy";
import { toast } from "@/hooks/use-toast";
import { useCaseUiStore } from "../case/caseUiStore";
import GravixLoader from "@/components/GravixLoader";

const CaseAgendaConfig = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const {
    agendaDetails,
    isLoading,
    error,
    fetchAgenda,
    updateAgendaDetails,
    updateDocument,
    addDocument,
    removeDocument,
    saveAgenda,
  } = useAgendaStore();

  const { setSecondaryHeader } = useCaseUiStore();

  useEffect(() => {
    setSecondaryHeader("Case Agenda");
  }, [setSecondaryHeader]);

  useEffect(() => {
    if (caseId) {
      fetchAgenda(caseId);
    }
  }, [caseId, fetchAgenda]);

  const handleSave = async () => {
    await saveAgenda();
    toast({
      title: "Success",
      description: "Agenda saved successfully",
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center pt-12">
        <GravixLoader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto pt-0 p-6 space-y-8">
      <Tabs defaultValue="details" className="w-full">
        <TabsList className="grid w-full grid-cols-3 mb-6">
          <TabsTrigger value="details" className="flex items-center">
            <Briefcase className="w-4 h-4 mr-2" /> Details
          </TabsTrigger>
          <TabsTrigger value="documents" className="flex items-center">
            <FileText className="w-4 h-4 mr-2" /> Documents
          </TabsTrigger>
          <TabsTrigger value="strategy" className="flex items-center">
            <Scale className="w-4 h-4 mr-2" /> Strategy
          </TabsTrigger>
        </TabsList>

        <TabsContent value="details">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <CaseDetails
              agendaDetails={agendaDetails}
              handleAgendaDetailChange={updateAgendaDetails}
            />
          </div>
        </TabsContent>

        <TabsContent value="documents">
          <CaseDocuments
            documents={agendaDetails.documents}
            handleDocumentChange={updateDocument}
            handleAddDocument={addDocument}
            handleRemoveDocument={removeDocument}
          />
        </TabsContent>

        <TabsContent value="strategy">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <CaseStrategy
              strategy={agendaDetails.strategy}
              handleStrategyChange={(value: string) =>
                updateAgendaDetails("strategy", value)
              }
            />
          </div>
        </TabsContent>
      </Tabs>
      <Button
        onClick={handleSave}
        className="bg-accent hover:bg-accent/90 text-accent-foreground"
      >
        Save Changes
      </Button>
    </div>
  );
};

export default CaseAgendaConfig;
