import React from "react";
import { Button } from "@/components/ui/button";
import { PlusCircle, FileText, Trash2 } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { AgendaDocument } from "@/store/agendaStore";

interface CaseDocumentsProps {
  documents: AgendaDocument[];
  handleAddDocument: () => void;
  handleRemoveDocument: (id: string) => void;
  handleDocumentChange: (
    id: string,
    field: keyof AgendaDocument,
    value: string
  ) => void;
}

const CaseDocuments: React.FC<CaseDocumentsProps> = ({
  documents,
  handleAddDocument,
  handleRemoveDocument,
  //   handleDocumentChange,
}) => {
  const getImportanceColor = (importance: string) => {
    switch (importance.toLowerCase()) {
      case "critical":
        return "bg-destructive text-destructive-foreground";
      case "high":
        return "bg-accent text-accent-foreground";
      case "medium":
        return "bg-primary text-primary-foreground";
      default:
        return "bg-secondary text-secondary-foreground";
    }
  };

  return (
    <div className="space-y-6 bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-primary">Main Documents</h2>
        <Button
          onClick={handleAddDocument}
          className="bg-accent hover:bg-accent/90 text-accent-foreground"
        >
          <PlusCircle className="mr-2 h-4 w-4" /> Add Document
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((doc) => (
          <Card
            key={doc.id}
            className="hover:shadow-lg transition-shadow duration-300 border-border overflow-hidden"
          >
            <CardHeader className="bg-gradient-to-r from-primary to-accent p-4">
              <CardTitle className="flex items-center text-white text-lg">
                <FileText className="mr-2 h-5 w-5" />
                {doc.name}
              </CardTitle>
              <CardDescription className="text-white/80 font-medium">
                {doc.title}
              </CardDescription>
            </CardHeader>
            <CardContent className="p-4">
              <Badge className={`${getImportanceColor(doc.importance)}`}>
                {doc.importance}
              </Badge>
            </CardContent>
            <CardFooter className="justify-between bg-light-gray p-4">
              <Button
                variant="outline"
                size="sm"
                className="text-primary hover:bg-primary hover:text-primary-foreground"
                onClick={() => {
                  console.log(`Opening document: ${doc.name}`);
                }}
              >
                Open
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="text-destructive hover:text-destructive/90"
                onClick={() => handleRemoveDocument(doc.id)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default CaseDocuments;
