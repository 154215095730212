import {
  MediaItem,
  SocialMediaItem,
  ActionItem,
  NewFile,
} from "@/store/feedStore";

export const mockMediaItems: MediaItem[] = [
  {
    id: "1",
    seen: false,
    title: `Johnny Depp and Amber Heard's Trial Continues`,
    source: "The New York Times",
    date: "2022-05-10",
    summary:
      "The high-profile defamation trial between Johnny Depp and Amber Heard continues, with both parties presenting evidence and testimony.",
    href: "https://www.nytimes.com/2022/04/21/arts/johnny-depp-amber-heard-trial.html#:~:text=The%20jury%20in%20the%20defamation%20trial%20in%20Virginia%20between%20the",
  },
  {
    id: "2",
    seen: true,
    title: "Expert Witness Testifies in Depp-Heard Trial",
    source: "CNN",
    date: "2022-05-09",
    summary:
      "A forensic psychologist takes the stand as an expert witness, providing insights into the psychological aspects of the case.",
    href: "https://edition.cnn.com/videos/us/2022/05/23/johnny-depp-amber-heard-trial-doctor-witness-finger-injury-nr-vpx.cnn#:~:text=CNN's%20Jean%20Casarez%20gives%20an%20update%20on%20the%20ongoing%20trial",
  },
  {
    id: "3",
    seen: true,
    title: `Depp's Lawyers Question Heard's Credibility`,
    source: "Reuters",
    date: "2022-05-08",
    summary: `Johnny Depp's legal team challenges Amber Heard's testimony, focusing on inconsistencies in her statements.`,
    href: "https://www.reuters.com/business/media-telecom/johnny-depps-lawyers-resume-questioning-ex-wife-defamation-fight-2022-05-17/#:~:text=LOS%20ANGELES,%20May%2017%20(Reuters)%20-%20Johnny%20Depp's%20attorneygrilled%20the",
  },
];

export const mockSocialMediaItems: SocialMediaItem[] = [
  {
    seen: true,
    link: "",
    id: "1",
    platform: "Twitter",
    content:
      "The #DeppvHeard trial is trending worldwide as fans discuss the latest developments.",
    date: "2022-05-10T14:30:00Z",
    author: "@LegalAnalyst",
  },
  {
    id: "2",
    seen: false,
    link: "",
    platform: "Instagram",
    content:
      "Amber Heard shares a statement on her Instagram account regarding the ongoing trial.",
    date: "2022-05-09T18:45:00Z",
    author: "@amberheard",
  },
  {
    id: "3",
    seen: true,
    link: "",
    platform: "TikTok",
    content:
      "Video clips from the trial go viral on TikTok, with users creating memes and analysis videos.",
    date: "2022-05-08T10:15:00Z",
    author: "@trialwatcher",
  },
];

export const mockActionItems: ActionItem[] = [
  {
    id: "1",
    completed: false,
    seen: false,
    task: "Prepare cross-examination questions for upcoming witness",
    dueDate: "2022-05-12",
    priority: "high",
  },
  {
    id: "2",
    completed: true,
    seen: false,
    task: "Review new evidence submitted by opposing counsel",
    dueDate: "2022-05-11",
    priority: "medium",
  },
  {
    id: "3",
    completed: false,
    seen: false,
    task: "Schedule meeting with expert witness to discuss testimony",
    dueDate: "2022-05-13",
    priority: "medium",
  },
];

export const mockNewFiles: NewFile[] = [
  {
    id: "1",
    seen: false,
    name: "Audio_Recording_05082022.mp3",
    type: "Audio Evidence",
    impact: "Potentially crucial evidence of a conversation between parties",
    addedDate: "2022-05-10T09:30:00Z",
  },
  {
    id: "2",
    seen: true,
    name: "Expert_Witness_Report.pdf",
    type: "Document",
    impact: "Expert analysis of psychological factors in the case",
    addedDate: "2022-05-09T16:45:00Z",
  },
  {
    id: "3",
    seen: false,
    name: "Property_Damage_Photos.zip",
    type: "Image Evidence",
    impact: "Visual evidence of alleged property damage",
    addedDate: "2022-05-08T11:20:00Z",
  },
];
