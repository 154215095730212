import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileText, BarChart2 } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/lib/utils";
import { useTimelineStore, TimelineEvent } from "@/store/timelineStore";
import { CaseTimeline } from "./CaseTimeline";
import { TimelineDetailCard } from "./TimelineDetailCard";
import { TimelineGhostCard } from "./TimelineGhostCard";

type TimelineType = "evidential" | "case";

export const CaseTimelineCard: React.FC<{ caseId: string }> = ({ caseId }) => {
  const [activeTimeline, setActiveTimeline] =
    useState<TimelineType>("evidential");
  const [selectedEvent, setSelectedEvent] = useState<TimelineEvent | null>(
    null
  );

  const { events, toggleStatus, removeIrrelevant } = useTimelineStore();

  const timelineEvents = events.filter(
    (event) => event.type === activeTimeline
  );

  return (
    <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
      <Card className="hover:shadow-lg transition-shadow bg-card lg:col-span-1">
        <CardHeader className="pb-2">
          <div className="flex flex-col items-center space-y-4">
            <h3 className="text-2xl font-semibold text-primary">Timeline</h3>
            <div className="p-1 bg-secondary rounded-lg w-full max-w-md">
              <div className="flex rounded-md overflow-hidden">
                <Button
                  variant="ghost"
                  className={cn(
                    "flex-1 px-4 py-2 transition-colors duration-200",
                    activeTimeline === "evidential"
                      ? "bg-primary text-primary-foreground cursor-default hover:bg-primary"
                      : "bg-card text-foreground hover:bg-muted hover:text-foreground"
                  )}
                  onClick={() => setActiveTimeline("evidential")}
                >
                  <FileText className="w-4 h-4 mr-2" />
                  Evidential
                </Button>
                <Button
                  variant="ghost"
                  className={cn(
                    "ml-2 flex-1 px-4 py-2 transition-colors duration-200",
                    activeTimeline === "case"
                      ? "bg-primary text-primary-foreground cursor-default hover:bg-primary"
                      : "bg-card text-foreground hover:bg-muted hover:text-foreground"
                  )}
                  onClick={() => setActiveTimeline("case")}
                >
                  <BarChart2 className="w-4 h-4 mr-2" />
                  Case
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent className="flex flex-col items-stretch">
          <CaseTimeline
            events={timelineEvents}
            onToggleStatus={toggleStatus}
            onRemoveIrrelevant={removeIrrelevant}
            onSelectEvent={setSelectedEvent}
            selectedEventId={selectedEvent?.id || null}
          />
          <div className="mt-4 flex justify-end">
            <Link to={`/cases/${caseId}/timeline/${activeTimeline}`}>
              <Button className="bg-accent text-accent-foreground hover:bg-accent/90 transition-colors duration-200">
                View Full Timeline
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>
      <AnimatePresence mode="wait">
        <motion.div
          key={selectedEvent ? "detail" : "ghost"}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ duration: 0.3 }}
          className="lg:col-span-2"
        >
          {selectedEvent ? (
            <TimelineDetailCard event={selectedEvent} />
          ) : (
            <TimelineGhostCard />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
