import GravixLoader from "@/components/GravixLoader";
import { CaseTimelineCard } from "@/features/timeline/CaseTimelineCard";
import { CaseSummary } from "@/features/wikiPage/CaseSummary";
import { CaseData } from "@/types/shared";
import React, { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useCaseUiStore } from "./caseUiStore";

interface CaseLayoutContext {
  isLoading: boolean;
  caseData: CaseData | null;
}

export const CaseOverview: React.FC = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { isLoading, caseData } = useOutletContext<CaseLayoutContext>();
  const { setSecondaryHeader } = useCaseUiStore();

  useEffect(() => {
    setSecondaryHeader("Summary");
  }, [setSecondaryHeader]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <GravixLoader />
      </div>
    );
  }

  if (!caseData) {
    return (
      <div className="text-destructive text-center text-lg">
        Error: Case data not found
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <CaseSummary caseData={caseData} />
      <CaseTimelineCard caseId={caseId as string} />
    </div>
  );
};
