import AccessDenied from "@/components/AccessDenied";
import GravixLoader from "@/components/GravixLoader";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import UserCreationForm from "@/components/UserCreationForm";
import { toast } from "@/hooks/use-toast";
import { useAuthStore } from "@/store/authStore";
import { useSettingsStore } from "@/store/userSettingsStore";
import { User, UserRole } from "@/types/shared";
import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { X } from "lucide-react";

const UserManagementSettings: React.FC = () => {
  const { users, fetchUsers, createUser, updateUser, removeUser } =
    useSettingsStore();
  const { isInitializing, user } = useAuthStore();
  const [isLoading, setIsLoading] = useState(true);
  const [editingUsers, setEditingUsers] = useState<Partial<User>[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState<string[]>([]);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [originalUsers, setOriginalUsers] = useState<Partial<User>[]>([]);

  useEffect(() => {
    if (!isInitializing) {
      setIsLoading(false);
    }
  }, [isInitializing]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    setEditingUsers(users);
    setOriginalUsers(users);
  }, [users]);

  const handleRoleChange = (userEmail: string, newRole: UserRole) => {
    setEditingUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.email === userEmail ? { ...u, role: newRole } : u
      )
    );
  };

  const handleRemoveUser = (userEmail: string) => {
    setUsersToDelete((prev) => [...prev, userEmail]);
  };

  const handleSaveChanges = async () => {
    try {
      for (const editedUser of editingUsers) {
        if (editedUser.email && editedUser.role) {
          await updateUser(editedUser.email, { role: editedUser.role });
        }
      }
      for (const userEmail of usersToDelete) {
        await removeUser(userEmail);
      }
      toast({
        title: "Success",
        description: "User changes saved successfully.",
      });
      setIsEditing(false);
      setUsersToDelete([]);
      fetchUsers();
    } catch (error) {
      console.error("Failed to save user changes:", error);
      toast({
        title: "Error",
        description: "Failed to save user changes. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleUndoChanges = () => {
    setEditingUsers(originalUsers);
    setUsersToDelete([]);
  };

  if (isInitializing || isLoading) {
    return <GravixLoader />;
  }

  if (user?.role !== "sys_admin" && user?.role !== "tenant_admin") {
    return <AccessDenied />;
  }

  return (
    <div className="space-y-8">
      <Card className="w-full max-w-4xl mx-auto mt-8">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-primary">
            Create New User
          </CardTitle>
        </CardHeader>
        <CardContent>
          <UserCreationForm
            createUser={createUser}
            tenantName={user?.tenantName}
          />
        </CardContent>
      </Card>

      <Card className="w-full max-w-4xl mx-auto mt-8">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-primary flex justify-between items-center">
            User List
            <Button onClick={() => setIsEditing(!isEditing)} variant="outline">
              {isEditing ? "Cancel" : "Edit Users"}
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {isEditing && (
            <div className="flex items-center mb-4">
              <Button
                onClick={handleSaveChanges}
                className="bg-accent hover:bg-accent/90 text-white"
              >
                Save Changes
              </Button>
              <Button
                onClick={handleUndoChanges}
                variant="outline"
                className="ml-2"
              >
                Undo Changes
              </Button>
            </div>
          )}
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="!font-bold !text-primary">Name</TableHead>
                <TableHead className="!font-bold !text-primary">
                  Email
                </TableHead>
                <TableHead className="!font-bold !text-primary">Role</TableHead>
                {isEditing && (
                  <TableHead className="!font-bold !text-primary">
                    Actions
                  </TableHead>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {editingUsers.map((user) => (
                <TableRow
                  key={user.email}
                  className={
                    usersToDelete.includes(user.email!) ? "opacity-50" : ""
                  }
                >
                  <TableCell>
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <Select
                        value={user.role}
                        onValueChange={(value: UserRole) =>
                          handleRoleChange(user.email!, value)
                        }
                        disabled={usersToDelete.includes(user.email!)}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select role" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="tenant_admin">
                            Tenant Admin
                          </SelectItem>
                          <SelectItem value="tenant_user">
                            Tenant User
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    ) : (
                      user.role
                    )}
                  </TableCell>

                  {isEditing && (
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <Button
                              onClick={() => setUserToDelete(user.email!)}
                              variant="destructive"
                              size="sm"
                              disabled={usersToDelete.includes(user.email!)}
                            >
                              {usersToDelete.includes(user.email!)
                                ? "Pending Deletion"
                                : "Remove"}
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                              <AlertDialogDescription>
                                This action will mark the user for deletion. The
                                change will be applied when you save all
                                changes.
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>Cancel</AlertDialogCancel>
                              <AlertDialogAction
                                onClick={() => handleRemoveUser(userToDelete!)}
                              >
                                Confirm
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                        {usersToDelete.includes(user.email!) && (
                          <Button
                            onClick={() =>
                              setUsersToDelete((prev) =>
                                prev.filter((email) => email !== user.email)
                              )
                            }
                            variant="ghost"
                            size="sm"
                            className="p-0"
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserManagementSettings;
