import { create } from "zustand";
import {
  mockMediaItems,
  mockSocialMediaItems,
  mockActionItems,
  mockNewFiles,
} from "./mocks/depp_v_heard_feed";

export interface BaseItem {
  id: string;
  seen: boolean;
}

export interface MediaItem extends BaseItem {
  title: string;
  source: string;
  date: string;
  summary: string;
  href: string;
}

export interface SocialMediaItem extends BaseItem {
  platform: string;
  content: string;
  date: string;
  author: string;
  link: string;
}

export interface ActionItem extends BaseItem {
  task: string;
  dueDate: string;
  priority: "low" | "medium" | "high";
  completed: boolean;
}

export interface NewFile extends BaseItem {
  name: string;
  type: string;
  impact: string;
  addedDate: string;
}

type ItemTypes = "media" | "social" | "action" | "file";

interface FeedStore {
  mediaItems: MediaItem[];
  socialMediaItems: SocialMediaItem[];
  actionItems: ActionItem[];
  newFiles: NewFile[];
  fetchItems: (type: ItemTypes) => Promise<void>;
  markItemAsSeen: (type: ItemTypes, id: string) => void;
  markActionItemAsCompleted: (id: string, completed: boolean) => void;
}

const useFeedStore = create<FeedStore>((set) => ({
  mediaItems: [],
  socialMediaItems: [],
  actionItems: [],
  newFiles: [],

  fetchItems: async (type) => {
    // Simulating an API call with a delay
    await new Promise((resolve) => setTimeout(resolve, 500));

    set((state) => {
      switch (type) {
        case "media":
          return {
            mediaItems: mockMediaItems.map((item) => ({
              ...item,
            })),
          };
        case "social":
          return {
            socialMediaItems: mockSocialMediaItems.map((item) => ({
              ...item,

              link: `https://${item.platform.toLowerCase()}.com/${item.author}`,
            })),
          };
        case "action":
          return {
            actionItems: mockActionItems.map((item) => ({
              ...item,
              completed: false,
            })),
          };
        case "file":
          return {
            newFiles: mockNewFiles.map((item) => ({
              ...item,
            })),
          };
        default:
          return state;
      }
    });
  },

  markItemAsSeen: (type, id) =>
    set((state) => {
      const updateItems = <T extends BaseItem>(items: T[]): T[] =>
        items.map((item) => (item.id === id ? { ...item, seen: true } : item));

      switch (type) {
        case "media":
          return { mediaItems: updateItems(state.mediaItems) };
        case "social":
          return { socialMediaItems: updateItems(state.socialMediaItems) };
        case "action":
          return { actionItems: updateItems(state.actionItems) };
        case "file":
          return { newFiles: updateItems(state.newFiles) };
        default:
          return state;
      }
    }),

  markActionItemAsCompleted: (id, completed) =>
    set((state) => ({
      actionItems: state.actionItems.map((item) =>
        item.id === id ? { ...item, completed } : item
      ),
    })),
}));

export default useFeedStore;
