import { v4 as uuidv4 } from "uuid";
import type { ChatMessage, ChatConversation } from "./types";

export interface ServerMessage {
  id?: string;
  role: "user" | "assistant" | "system";
  content: string;
  timestamp: string | number | Date;
  operation_type?: string;
  metadata?: Record<string, string>;
}

export interface ServerConversation {
  id: string;
  case_id: string;
  title: string;
  messages: ServerMessage[];
  last_activity: string | number | Date;
}

export interface ApiError {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
  };
  message?: string;
}

export const parseServerMessage = (
  serverMessage: ServerMessage
): ChatMessage => {
  return {
    id: serverMessage.id || uuidv4(),
    role: serverMessage.role,
    content: serverMessage.content,
    timestamp: new Date(serverMessage.timestamp),
    operation_type: serverMessage.operation_type,
    metadata: serverMessage.metadata,
    syncStatus: "synced",
  };
};

export const mapServerConversation = (
  conversation: ServerConversation
): ChatConversation => {
  return {
    id: conversation.id,
    caseId: conversation.case_id,
    title: conversation.title || `Chat ${conversation.id.slice(0, 8)}`,
    messages: Array.isArray(conversation.messages)
      ? conversation.messages.map(parseServerMessage)
      : [],
    lastActivity: new Date(conversation.last_activity || Date.now()),
    syncStatus: "synced",
  };
};

export const mapServerConversations = (
  conversations: ServerConversation[]
): ChatConversation[] => {
  return conversations.map(mapServerConversation);
};

export const handleApiError = (error: ApiError): string => {
  if (error.response?.data?.message) {
    return error.response.data.message;
  }

  if (error.response) {
    return "Server error occurred";
  }

  return error.message || "An unexpected error occurred";
};
