import { create } from "zustand";
import { useStore as useCaseStore } from "./caseStore";
import { CaseDocument, useDocumentStore } from "./documentStore";
import { useTaskStore } from "./taskStore";
import { apiCall, base64ToBlob } from "@/lib/utils";
import pako from "pako";
import { DocumentData } from "@/components/PDFViewer_V2/types";

interface Entity {
  id: string;
  name: string;
  type: string;
  role?: string;
  description?: string;
}

interface LinkedDocument {
  id: string;
  title: string;
  type: string;
}

interface Task {
  id: string;
  title: string;
  status: string;
}

interface SpecificDocumentState {
  caseDocument: CaseDocument | null;
  relatedEntities: Entity[];
  linkedDocuments: LinkedDocument[];
  currentTasks: Task[];
  isLoading: boolean;
  error: string | null;
  previewBlob: Blob | null;
  transcriptData: DocumentData | null; // Add transcript data to the state
  fetchDocumentDetails: (caseId: string, documentId: string) => Promise<void>;
  fetchDocumentPreview: (fileId: string) => Promise<void>;
  clearDocumentDetails: () => void;
}

export const useSpecificDocumentStore = create<SpecificDocumentState>(
  (set, get) => ({
    caseDocument: null,
    relatedEntities: [],
    linkedDocuments: [],
    currentTasks: [],
    isLoading: false,
    error: null,
    previewBlob: null,
    transcriptData: null,

    fetchDocumentDetails: async (caseId: string, documentId: string) => {
      set({ isLoading: true, error: null });

      try {
        // Fetch document details
        const documentStore = useDocumentStore.getState();
        const document = await documentStore.getDocumentById(
          documentId,
          caseId
        );

        if (!document) {
          throw new Error("Document not found");
        }

        let relatedEntities: Entity[] = [];
        // let linkedDocuments: LinkedDocument[] = [];
        let currentTasks: Task[] = [];

        const caseStore = useCaseStore.getState();
        const caseData = caseStore.getCaseById(caseId);

        if (caseData) {
          // Fetch related entities
          relatedEntities = [
            ...caseData.entities.people.map((p) => ({ ...p, type: "Person" })),
            ...caseData.entities.locations.map((l) => ({
              ...l,
              type: "Location",
            })),
            ...caseData.entities.organizations.map((o) => ({
              ...o,
              type: "Organization",
            })),
            ...caseData.entities.events.map((e) => ({ ...e, type: "Event" })),
          ];

          //   WIP: Need to rethink that
          // Fetch linked documents
          //   const linkedDocumentsPromises = caseData.keyDocuments
          //     .filter((doc) => doc.id !== documentId)
          //     .map(async (doc) => {
          //       const fullDoc = await documentStore.getDocumentById(
          //         doc.id,
          //         caseId
          //       );
          //       return fullDoc
          //         ? { id: fullDoc.id, title: fullDoc.title, type: fullDoc.type }
          //         : null;
          //     });

          //   linkedDocuments = (await Promise.all(linkedDocumentsPromises)).filter(
          //     (doc): doc is LinkedDocument => doc !== null
          //   );

          // Fetch current tasks
          const taskStore = useTaskStore.getState();
          currentTasks = taskStore
            .getTasksByCaseId(caseId)
            .filter((task) => task.status === "in_progress")
            .slice(0, 3);
        }

        set({
          caseDocument: document,
          relatedEntities,
          //   linkedDocuments,
          currentTasks,
          isLoading: false,
        });

        // Fetch document preview
        await get().fetchDocumentPreview(document.id);
      } catch (error) {
        set({
          error: (error as Error).message,
          isLoading: false,
          caseDocument: null,
          relatedEntities: [],
          linkedDocuments: [],
          currentTasks: [],
          previewBlob: null,
        });
      }
    },

    fetchDocumentPreview: async (fileId: string) => {
      set({ isLoading: true, error: null });

      try {
        const response = await apiCall(
          `/ocr/download?file_id=${fileId}&mode=preview`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch document preview");
        }

        const data = await response.json();

        // Handle PDF blob
        const pdfBlob = base64ToBlob(data.pdf, "application/pdf");

        // Handle transcript if it exists
        let transcriptData = null;
        if (data.transcript) {
          // Convert base64 to Uint8Array
          const binaryString = atob(data.transcript);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Decompress gzipped data
          const decompressed = pako.inflate(bytes, { to: "string" });
          transcriptData = JSON.parse(decompressed);
        }

        set({
          previewBlob: pdfBlob,
          transcriptData,
          isLoading: false,
          error: null,
        });
      } catch (error) {
        console.error("Error fetching document preview:", error);
        set({
          error: "Failed to load document preview",
          isLoading: false,
          previewBlob: null,
          transcriptData: null,
        });
      }
    },

    clearDocumentDetails: () => {
      set({
        caseDocument: null,
        relatedEntities: [],
        linkedDocuments: [],
        currentTasks: [],
        isLoading: false,
        error: null,
        previewBlob: null,
        transcriptData: null,
      });
    },
  })
);
