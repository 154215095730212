import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { PlusCircle } from "lucide-react";

interface AddCaseCardProps {
  onClick: () => void;
}

export const AddCaseCard: React.FC<AddCaseCardProps> = ({ onClick }) => (
  <Card
    className="hover:shadow-lg transition-all border-dashed border-2 border-gray-300 bg-opacity-50 bg-background cursor-pointer hover:bg-opacity-100"
    onClick={onClick}
  >
    <CardContent className="flex flex-col items-center justify-center h-full p-6">
      <div className="flex flex-col items-center justify-center flex-grow">
        <PlusCircle className="w-12 h-12 text-gray-400 mb-2" />
        <p className="text-gray-500 font-medium text-center">Add New Case</p>
      </div>
    </CardContent>
  </Card>
);
