import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useCasesMetadataStore } from "@/store/casesMetadataStore";
import { toast } from "@/hooks/use-toast";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useAuthStore } from "@/store/authStore";

interface AddCaseDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface ValidationErrors {
  name?: string;
  year?: string;
  type?: string;
  summary?: string;
}

export const AddCaseDialog: React.FC<AddCaseDialogProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { user } = useAuthStore();
  const [newCase, setNewCase] = useState({
    case: {
      name: "",
      tenant_name: user?.tenantName || "",
    },
    new_params: {
      details: {
        year: "",
        status: "On-boarding",
        type: "",
        summary: "",
      },
    },
  });

  const [errors, setErrors] = useState<ValidationErrors>({});

  const { createCase, error, isLoading } = useCasesMetadataStore();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "name") {
      setNewCase((prev) => ({
        ...prev,
        case: { ...prev.case, [name]: value },
      }));
    } else {
      setNewCase((prev) => ({
        ...prev,
        new_params: {
          ...prev.new_params,
          details: { ...prev.new_params.details, [name]: value },
        },
      }));
    }
    // Clear error when user starts typing
    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setNewCase((prev) => ({
      ...prev,
      new_params: {
        ...prev.new_params,
        details: { ...prev.new_params.details, [name]: value },
      },
    }));
    // Clear error when user selects a value
    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const validateInputs = (): boolean => {
    const newErrors: ValidationErrors = {};
    if (!newCase.case.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!newCase.new_params.details.year.trim()) {
      newErrors.year = "Year is required";
    } else if (!/^\d{4}$/.test(newCase.new_params.details.year)) {
      newErrors.year = "Year must be a 4-digit number";
    }
    if (!newCase.new_params.details.type) {
      newErrors.type = "Type is required";
    }
    if (!newCase.new_params.details.summary.trim()) {
      newErrors.summary = "Summary is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }
    try {
      await createCase(newCase);
      setIsOpen(false);
      setNewCase({
        case: {
          name: "",
          tenant_name: user?.tenantName || "",
        },
        new_params: {
          details: {
            year: "",
            status: "On-boarding",
            type: "",
            summary: "",
          },
        },
      });
      toast({
        title: "Success",
        description: "New case created successfully",
      });
    } catch (err) {
      console.error("Failed to create case:", err);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add New Case</DialogTitle>
          <DialogDescription>
            Enter the details for the new case. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <div className="col-span-3">
                <Input
                  id="name"
                  name="name"
                  value={newCase.case.name}
                  onChange={handleInputChange}
                  className={errors.name ? "border-red-500" : ""}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="year" className="text-right">
                Year
              </Label>
              <div className="col-span-3">
                <Input
                  id="year"
                  name="year"
                  value={newCase.new_params.details.year}
                  onChange={handleInputChange}
                  className={errors.year ? "border-red-500" : ""}
                />
                {errors.year && (
                  <p className="text-red-500 text-sm mt-1">{errors.year}</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="type" className="text-right">
                Type
              </Label>
              <div className="col-span-3">
                <Select
                  onValueChange={(value) => handleSelectChange("type", value)}
                  value={newCase.new_params.details.type}
                >
                  <SelectTrigger
                    className={errors.type ? "border-red-500" : ""}
                  >
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Civil">Civil</SelectItem>
                    <SelectItem value="Criminal">Criminal</SelectItem>
                    <SelectItem value="Commercial">Commercial</SelectItem>
                    <SelectItem value="Civil Rights">Civil Rights</SelectItem>
                  </SelectContent>
                </Select>
                {errors.type && (
                  <p className="text-red-500 text-sm mt-1">{errors.type}</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="summary" className="text-right">
                Summary
              </Label>
              <div className="col-span-3">
                <Textarea
                  id="summary"
                  name="summary"
                  value={newCase.new_params.details.summary}
                  onChange={handleInputChange}
                  className={errors.summary ? "border-red-500" : ""}
                  rows={3}
                />
                {errors.summary && (
                  <p className="text-red-500 text-sm mt-1">{errors.summary}</p>
                )}
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Creating..." : "Create Case"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
