import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Users, Link as LinkIcon } from "lucide-react";
import { useSpecificDocumentStore } from "@/store/specificDocumentStore";
import RelatedEntities from "./DocumentRelatedEntities";
import LinkedDocuments from "./LinkedDocuments";
import CurrentActions from "./DocumentActions";

interface DocumentSidebarProps {
  caseId: string;
}

const DocumentSidebar: React.FC<DocumentSidebarProps> = ({ caseId }) => {
  const { relatedEntities, linkedDocuments, currentTasks } =
    useSpecificDocumentStore();

  return (
    <aside className="lg:w-1/3">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            <Users className="mr-2 h-5 w-5" />
            Related Entities
          </CardTitle>
        </CardHeader>
        <CardContent>
          <RelatedEntities entities={relatedEntities} />
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            <LinkIcon className="mr-2 h-5 w-5" />
            Linked Documents
          </CardTitle>
        </CardHeader>
        <CardContent>
          <LinkedDocuments documents={linkedDocuments} caseId={caseId} />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Current Actions</CardTitle>
        </CardHeader>
        <CardContent>
          <CurrentActions tasks={currentTasks} />
        </CardContent>
      </Card>
    </aside>
  );
};

export default DocumentSidebar;
