import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const scaleVariants = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  return (
    <div className="bg-light-gray flex items-center justify-center p-4 flex-grow">
      <motion.div
        className="text-center"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h1
          className="text-8xl font-bold text-primary mb-4"
          variants={itemVariants}
        >
          404
        </motion.h1>
        <motion.div
          className="text-6xl font-bold text-accent mb-8"
          variants={itemVariants}
        >
          Case File Not Found
        </motion.div>
        <motion.p
          className="text-xl text-charcoal mb-4"
          variants={itemVariants}
        >
          Objection! It seems this legal document has gone missing.
        </motion.p>
        <motion.p
          className="text-lg text-charcoal mb-8"
          variants={itemVariants}
        >
          Our AI is still combing through the evidence, but we can't locate the
          requested file.
        </motion.p>
        <motion.div
          className="relative w-64 h-64 mx-auto mb-8"
          animate={{ rotate: 360 }}
          transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
        >
          <motion.div
            className="absolute inset-0 bg-primary rounded-full opacity-20"
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 3, repeat: Infinity }}
          />
          <motion.div
            className="absolute inset-2 bg-accent rounded-full opacity-40"
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
          <motion.div className="absolute inset-4 bg-white rounded-full flex items-center justify-center">
            <span className="text-4xl">⚖️</span>
          </motion.div>
        </motion.div>
        <motion.p
          className="text-lg text-charcoal mb-8"
          variants={itemVariants}
        >
          Let's redirect you to a page that exists. No appeal necessary!
        </motion.p>
        <motion.button
          className="bg-accent text-white px-6 py-3 rounded-full text-lg font-semibold flex items-center justify-center mx-auto"
          variants={scaleVariants}
          whileHover="hover"
          whileTap="tap"
          onClick={() => navigate("/")}
        >
          <ArrowLeft className="mr-2" />
          Return to Case Dashboard
        </motion.button>
      </motion.div>
    </div>
  );
};

export default NotFoundPage;
