import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FileText, ChevronRight } from "lucide-react";

interface KeyDocumentsCardProps {
  caseData: {
    id: string;
    keyDocuments: Array<{ id: string; name: string }>;
  };
}

export const KeyDocumentsCard: React.FC<KeyDocumentsCardProps> = ({
  caseData,
}) => {
  return (
    <Card className="hover:shadow-lg transition-shadow">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span className="flex items-center">
            <FileText className="mr-2 h-5 w-5" />
            Key Documents
          </span>
          <Link
            to={`/cases/${caseData.id}/documents`}
            className="text-xs text-accent hover:underline flex items-center"
          >
            View all
            <ChevronRight className="h-3 w-3 ml-1" />
          </Link>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {caseData.keyDocuments.map((doc) => (
            <li key={doc.id}>
              <Link
                to={`/cases/${caseData.id}/documents/${doc.id}`}
                className="text-accent hover:underline"
              >
                {doc.name}
              </Link>
              <span className="text-sm text-muted-foreground ml-2">
                (Document)
              </span>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};
