import { create } from "zustand";

type Breadcrumb = {
  label: string;
  path: string;
};

type BreadcrumbStore = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  addBreadcrumb: (breadcrumb: Breadcrumb) => void;
  clearBreadcrumbs: () => void;
};

export const useBreadcrumbStore = create<BreadcrumbStore>((set) => ({
  breadcrumbs: [],
  setBreadcrumbs: (breadcrumbs) => set({ breadcrumbs }),
  addBreadcrumb: (breadcrumb) =>
    set((state) => ({ breadcrumbs: [...state.breadcrumbs, breadcrumb] })),
  clearBreadcrumbs: () => set({ breadcrumbs: [] }),
}));
