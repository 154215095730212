import ReactMarkdown, { Components } from "react-markdown";

export const CustomMarkdown: React.FC<{ content: string }> = ({ content }) => {
  const components = {
    h1: ({ children }: { children: React.ReactNode }) => (
      <h1 className="text-primary text-2xl font-semibold mb-4">{children}</h1>
    ),
    h2: ({ children }: { children: React.ReactNode }) => (
      <h2 className="text-primary text-xl font-semibold mb-3">{children}</h2>
    ),
    h3: ({ children }: { children: React.ReactNode }) => (
      <h3 className="text-primary text-lg font-semibold mb-2">{children}</h3>
    ),
    p: ({ children }: { children: React.ReactNode }) => (
      <p className="text-charcoal leading-relaxed mb-4">{children}</p>
    ),
    ul: ({ children }: { children: React.ReactNode }) => (
      <ul className="list-disc pl-6 mb-4 space-y-2">{children}</ul>
    ),
    ol: ({ children }: { children: React.ReactNode }) => (
      <ol className="list-decimal pl-6 mb-4 space-y-2">{children}</ol>
    ),
    li: ({ children }: { children: React.ReactNode }) => (
      <li className="text-charcoal">{children}</li>
    ),
    blockquote: ({ children }: { children: React.ReactNode }) => (
      <blockquote className="border-l-4 border-accent pl-4 italic my-4 text-charcoal/80">
        {children}
      </blockquote>
    ),
    code: ({ children }: { children: React.ReactNode }) => (
      <code className="bg-secondary/50 rounded px-1 py-0.5 text-sm text-charcoal">
        {children}
      </code>
    ),
    pre: ({ children }: { children: React.ReactNode }) => (
      <pre className="bg-secondary/50 rounded p-4 my-4 overflow-x-auto">
        {children}
      </pre>
    ),
  };

  return (
    <ReactMarkdown components={components as Partial<Components>}>
      {content}
    </ReactMarkdown>
  );
};
