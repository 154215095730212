import { Badge } from "@/components/ui/badge";
import useFeedStore from "@/store/feedStore";
import { formatDistanceToNow } from "date-fns";
import { FileIcon } from "lucide-react";
import React, { useEffect, useState } from "react";

const NewFiles: React.FC = () => {
  const { newFiles, fetchItems, markItemAsSeen } = useFeedStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadNewFiles = async () => {
      setIsLoading(true);
      setError(null);
      try {
        await fetchItems("file");
      } catch (err) {
        setError(
          `Failed to load new files. Please try again. Message: ${
            (err as Error).message
          }`
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadNewFiles();
  }, [fetchItems]);

  const handleClick = (id: string) => {
    markItemAsSeen("file", id);
    // Add file opening logic here if needed
  };

  if (isLoading) return <p>Loading new files...</p>;
  if (error) return <p className="text-destructive">{error}</p>;

  return (
    <ul className="space-y-4">
      {newFiles.map((file) => (
        <li key={file.id} className="border-b pb-4">
          <div className="flex justify-between items-start">
            <div className="flex items-center">
              <FileIcon size={20} className="mr-2 text-accent" />
              <h3
                className="font-semibold text-accent cursor-pointer hover:underline"
                onClick={() => handleClick(file.id)}
              >
                {file.name}
              </h3>
            </div>
            <div className="flex items-center">
              {!file.seen && (
                <Badge
                  variant="outline"
                  className="mr-2 text-accent border-accent"
                >
                  New
                </Badge>
              )}
            </div>
          </div>
          <div className="mt-2 text-sm text-muted-foreground">
            <p>Type: {file.type}</p>
            <p>Impact: {file.impact}</p>
            <p>
              Added:{" "}
              {formatDistanceToNow(new Date(file.addedDate), {
                addSuffix: true,
              })}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default NewFiles;
