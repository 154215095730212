import { DocumentTimeline } from "@/components/DocumentTimeline";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@/components/ui/table";
import { apiCall } from "@/lib/utils";
import { CaseDocument } from "@/store/documentStore";
import { FileText } from "lucide-react";
import React, { useEffect, useState } from "react";
import { DocumentActionButtons } from "./DocumentLoadingStates";
import DocumentPreview from "./DocumentPreview";
import DocumentTags from "./DocumentTags";

interface DocumentMainContentProps {
  caseDocument: CaseDocument;
  caseId: string;
  documentId: string;
  previewBlob: Blob | null;
  onViewPDFV2: () => void;
  canShowPDF: boolean;
}

const DocumentMainContent: React.FC<DocumentMainContentProps> = ({
  caseDocument,
  documentId,
  onViewPDFV2,
  canShowPDF,
}) => {
  const [previewWidth, setPreviewWidth] = useState(400);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const updatePreviewWidth = () => {
      const containerWidth =
        document.querySelector(".preview-container")?.clientWidth ?? 400;
      setPreviewWidth(Math.min(containerWidth - 32, 400));
    };

    updatePreviewWidth();
    window.addEventListener("resize", updatePreviewWidth);
    return () => window.removeEventListener("resize", updatePreviewWidth);
  }, []);

  const handleDownloadPDF = async () => {
    if (!documentId || !caseDocument?.id || isDownloading) return;

    setIsDownloading(true);
    try {
      const response = await apiCall(
        `/ocr/download?file_id=${caseDocument.id}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) throw new Error("Failed to fetch PDF");

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${caseDocument.title}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error downloading PDF:", err);
    } finally {
      setIsDownloading(false);
    }
  };

  const timelineEvents = [
    {
      date: caseDocument.dateProduced,
      title: "Document Produced",
      description: `${caseDocument.title} produced by ${caseDocument.producedBy}`,
    },
    {
      date: "Current",
      title: "Ongoing Review",
      description: `${caseDocument.status}`,
    },
  ];

  return (
    <main className="flex-grow mt-6">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            <FileText className="mr-2 h-5 w-5" />
            Document Details
          </CardTitle>
        </CardHeader>
        <CardContent>
          <DocumentTags tags={caseDocument.tags} status={caseDocument.status} />

          <div className="bg-light-gray p-4 rounded mb-6">
            <h3 className="font-semibold mb-2">Access Full Document</h3>
            <p className="mb-4">
              The complete document is available in the Gravix secure document
              repository.
            </p>
            <DocumentActionButtons
              onView={onViewPDFV2}
              onDownload={handleDownloadPDF}
              isViewLoading={!canShowPDF}
              isDownloading={isDownloading}
              canShowPDF={canShowPDF}
            />
          </div>

          <Table>
            <TableBody>
              <TableRow>
                <TableHead>Document ID</TableHead>
                <TableCell>{caseDocument.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableHead>Date Produced</TableHead>
                <TableCell>{caseDocument.dateProduced}</TableCell>
              </TableRow>
              <TableRow>
                <TableHead>Produced By</TableHead>
                <TableCell>{caseDocument.producedBy}</TableCell>
              </TableRow>
              <TableRow>
                <TableHead>Document Type</TableHead>
                <TableCell>{caseDocument.type}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <h3 className="font-semibold mt-6 mb-2">Document Summary</h3>
          <p>{caseDocument.content}</p>

          <h3 className="font-semibold mt-6 mb-2">Document Preview</h3>
          <div className="preview-container">
            <DocumentPreview
              fileId={caseDocument.id}
              previewWidth={previewWidth}
            />
          </div>

          <h3 className="font-semibold mt-6 mb-2">Document Timeline</h3>
          <DocumentTimeline events={timelineEvents} />
        </CardContent>
      </Card>
    </main>
  );
};

export default DocumentMainContent;
