import React from "react";
import { Case } from "../types";
import { AddCaseCard } from "./AddCaseCard";
import { CaseCard } from "./CaseCard";

interface CaseGridProps {
  cases: Case[];
  onAddCase: () => void;
}

export const CaseGrid: React.FC<CaseGridProps> = ({ cases, onAddCase }) => {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mb-6">
      <AddCaseCard onClick={onAddCase} />
      {cases.map((caseItem) => (
        <CaseCard key={caseItem.name} caseItem={caseItem} />
      ))}
    </div>
  );
};
