import { useCasesMetadataStore } from "@/store/casesMetadataStore";
import React, { useEffect, useMemo, useState } from "react";
import { CaseGrid } from "./CaseGrid";
import { SearchBar } from "@/components/SearchBar";
import { AddCaseDialog } from "./AddCaseDialog";
import { FilterBar } from "./CasesFilterBar";
import CasesListSkeleton from "./CasesLoadingSkeleton";
import { ErrorDisplay } from "./ErrorDisplay";

export const CasesList: React.FC = () => {
  const { cases, isLoading, error, fetchCases } = useCasesMetadataStore();
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const caseTypes = useMemo(
    () => [...new Set(cases.map((c) => c.details.type))],
    [cases]
  );
  const caseStatuses = useMemo(
    () => [...new Set(cases.map((c) => c.details.status))],
    [cases]
  );

  const filteredCases = useMemo(() => {
    return cases.filter(
      (caseItem) =>
        caseItem.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (typeFilter === null || caseItem.details.type.includes(typeFilter)) &&
        (statusFilter === null || caseItem.details.status === statusFilter)
    );
  }, [cases, searchTerm, typeFilter, statusFilter]);

  const clearFilters = () => {
    setSearchTerm("");
    setTypeFilter(null);
    setStatusFilter(null);
  };

  if (isLoading) {
    return <CasesListSkeleton />;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <div className="mb-6 space-y-4">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <FilterBar
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          caseTypes={caseTypes}
          caseStatuses={caseStatuses}
          clearFilters={clearFilters}
        />
      </div>

      <CaseGrid
        cases={filteredCases}
        onAddCase={() => setIsAddDialogOpen(true)}
      />

      <AddCaseDialog isOpen={isAddDialogOpen} setIsOpen={setIsAddDialogOpen} />
    </>
  );
};

export default CasesList;
