import React, { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";
import { usePDFViewerStore } from "./pdfViewerStore";
import { Block } from "./types";

interface SearchSegmentCardProps {
  onOpenPDFViewer: () => void;
}

const SearchSegmentCard: React.FC<SearchSegmentCardProps> = ({
  onOpenPDFViewer,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    documentData,
    setSelectedSegment,
    setCurrentPage,
    setControlledScrollTarget,
  } = usePDFViewerStore();

  const handleSearch = () => {
    if (!documentData || !searchTerm.trim()) return;

    for (const page of documentData.pages) {
      for (const [blockIndex, block] of page.blocks.entries()) {
        const blockText = extractTextFromBlock(block, documentData.text);
        if (blockText.toLowerCase().includes(searchTerm.toLowerCase())) {
          const segmentId = `page-${page.pageNumber}-block-${blockIndex}`;
          setSelectedSegment(segmentId);
          setCurrentPage(page.pageNumber);
          setControlledScrollTarget(segmentId);
          onOpenPDFViewer();
          return;
        }
      }
    }

    alert("Text not found in the document.");
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Search In Document</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex space-x-2">
          <Input
            type="text"
            placeholder="Enter text to search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button onClick={handleSearch}>
            <Search className="w-4 h-4 mr-2" />
            Search
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

function extractTextFromBlock(block: Block, fullText: string): string {
  const textSegments = block.layout.textAnchor.textSegments;
  const startIndex = parseInt(textSegments[0].startIndex || "0", 10);
  const endIndex = parseInt(textSegments[0].endIndex, 10);
  return fullText.substring(startIndex, endIndex).trim();
}

export default SearchSegmentCard;
