import { create } from "zustand";
import {
  evidentialTimelineMockData,
  caseTimelineMockData,
} from "./mocks/timelineData";

export type TimelineEvent = {
  id: string;
  date: string;
  title: string;
  description: string;
  content: string;
  status: "important" | "neutral" | "irrelevant";
  type: "evidential" | "case";
};

type TimelineStore = {
  events: TimelineEvent[];
  setEvents: (events: TimelineEvent[]) => void;
  toggleStatus: (id: string, action: "star" | "chevron") => void;
  removeIrrelevant: (id: string) => void;
};

export const useTimelineStore = create<TimelineStore>((set) => ({
  events: [
    ...evidentialTimelineMockData.timelineEvents,
    ...caseTimelineMockData.timelineEvents,
  ] as TimelineEvent[],
  setEvents: (events) => set({ events }),
  toggleStatus: (id, action) =>
    set((state) => ({
      events: state.events.map((event) =>
        event.id === id
          ? {
              ...event,
              status:
                action === "star"
                  ? event.status === "important"
                    ? "neutral"
                    : "important"
                  : event.status === "irrelevant"
                  ? "neutral"
                  : "irrelevant",
            }
          : event
      ),
    })),
  removeIrrelevant: (id) =>
    set((state) => ({
      events: state.events.map((event) =>
        event.id === id && event.status === "irrelevant"
          ? { ...event, status: "neutral" }
          : event
      ),
    })),
}));
