import { Alert, AlertDescription } from "@/components/ui/alert";
import { cn } from "@/lib/utils";
import { Info } from "lucide-react";
import React, { AnchorHTMLAttributes } from "react";
import ReactMarkdown, { Components } from "react-markdown";
import { GroundedText } from "@/components/GroundedText/GroundedText";

const MarkdownContent = ({
  content,
  className,
  isAIMessage = true,
}: {
  content: string;
  className?: string;
  isAIMessage?: boolean;
}) => {
  const components: Components = {
    // Basic text content
    p({ children }) {
      const renderGroundedContent = (content: React.ReactNode) => {
        if (typeof content !== "string") return content;

        const parts = content.split(/(\[.*?\])/g);
        return parts.map((part, index) => {
          if (part.startsWith("[") && part.endsWith("]")) {
            const [text, source] = part.slice(1, -1).split("|");
            return <GroundedText key={index} text={text} source={source} />;
          }
          return part;
        });
      };

      return (
        <p className="text-base leading-relaxed mb-4 last:mb-0 break-words whitespace-pre-wrap overflow-x-auto max-w-full">
          {React.Children.map(children, (child) =>
            renderGroundedContent(child)
          )}
        </p>
      );
    },

    // Simple lists
    ul({ children }) {
      return (
        <ul className="space-y-2 mb-4 ml-4 list-disc break-words">
          {children}
        </ul>
      );
    },
    ol({ children }) {
      return (
        <ol className="space-y-2 mb-4 ml-4 list-decimal break-words">
          {children}
        </ol>
      );
    },
    li({ children }) {
      return <li className="leading-relaxed break-words">{children}</li>;
    },

    // Pre-formatted and code content
    pre({ children }) {
      return (
        <pre className="whitespace-pre-wrap break-words overflow-x-auto max-w-full text-sm bg-black/5 rounded-md p-4">
          {children}
        </pre>
      );
    },
    code({ children }) {
      return (
        <code className="whitespace-pre-wrap break-all font-mono text-sm bg-black/5 rounded-sm px-1">
          {children}
        </code>
      );
    },

    // Headings
    h1({ children }) {
      return (
        <h1
          className={cn(
            "text-xl font-semibold mb-3 break-words",
            isAIMessage ? "text-accent" : "text-primary-foreground"
          )}
        >
          {children}
        </h1>
      );
    },
    h2({ children }) {
      return (
        <h2
          className={cn(
            "text-lg font-medium mb-2 break-words",
            isAIMessage ? "text-accent" : "text-primary-foreground"
          )}
        >
          {children}
        </h2>
      );
    },
    h3({ children }) {
      return (
        <h3
          className={cn(
            "text-base font-medium mb-2 break-words",
            isAIMessage ? "text-accent-foreground" : "text-primary-foreground"
          )}
        >
          {children}
        </h3>
      );
    },

    // Text styling
    em({ children }) {
      return <em className="italic break-words">{children}</em>;
    },
    strong({ children }) {
      return <strong className="font-semibold break-words">{children}</strong>;
    },

    // Blockquotes
    blockquote({ children }) {
      return (
        <Alert
          className={cn(
            "my-3 border-l-4",
            isAIMessage
              ? "border-accent bg-accent/10"
              : "border-primary-foreground/50 bg-primary-foreground/10"
          )}
        >
          <Info className="h-4 w-4 shrink-0" />
          <AlertDescription className="break-words whitespace-pre-wrap overflow-x-auto">
            {children}
          </AlertDescription>
        </Alert>
      );
    },

    // Separator
    hr() {
      return <hr className="my-6 border-t border-current/10" />;
    },

    // Links
    a({ children, href }: AnchorHTMLAttributes<HTMLAnchorElement>) {
      return (
        <a
          href={href}
          className={cn(
            "underline underline-offset-4 hover:opacity-80 break-words",
            isAIMessage ? "text-accent" : "text-primary-foreground"
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  };

  return (
    <div
      className={cn(
        "markdown-content break-words max-w-full overflow-hidden",
        className
      )}
    >
      <ReactMarkdown components={components}>{content}</ReactMarkdown>
    </div>
  );
};

export default MarkdownContent;
