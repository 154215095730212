import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FileText } from "lucide-react";

interface StorageItem {
  name: string;
  value: number;
  color: string;
}

interface StorageBreakdownCardProps {
  storageData: StorageItem[];
}

const StorageBreakdownCard: React.FC<StorageBreakdownCardProps> = ({
  storageData,
}) => {
  const totalStorage = storageData.reduce((acc, item) => acc + item.value, 0);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center text-primary">
          <FileText className="mr-2" size={24} />
          Storage Breakdown
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="h-4 w-full bg-gray-200 rounded-full overflow-hidden">
            {storageData.map((item, index) => (
              <div
                key={index}
                className="h-full float-left"
                style={{
                  width: `${(item.value / totalStorage) * 100}%`,
                  backgroundColor: item.color,
                }}
              ></div>
            ))}
          </div>
          <div className="flex flex-wrap justify-between">
            {storageData.map((item, index) => (
              <div key={index} className="flex items-center mb-2">
                <div
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span className="text-sm">
                  {item.name}: {Math.round((item.value / totalStorage) * 100)}%
                </span>
              </div>
            ))}
          </div>
          <div className="text-sm text-charcoal">
            Total Storage: {(totalStorage / 1000).toFixed(1)} GB
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default StorageBreakdownCard;
