import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FileText } from "lucide-react";

interface File {
  name: string;
  // Add properties that are actually used from the file object
  // For now, we're only using the length of the files array
}

interface QuickStatsCardProps {
  files: File[];
}

const QuickStatsCard: React.FC<QuickStatsCardProps> = ({ files }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center text-primary">
          <FileText className="mr-2" size={24} />
          Quick Stats
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-light-gray rounded-lg p-4 text-center">
            <p className="text-2xl font-bold text-primary">{files.length}</p>
            <p className="text-charcoal">Total Files</p>
          </div>
          <div className="bg-light-gray rounded-lg p-4 text-center">
            <p className="text-2xl font-bold text-accent">15.2 GB</p>
            <p className="text-charcoal">Total Size</p>
          </div>
          <div className="bg-light-gray rounded-lg p-4 text-center">
            <p className="text-2xl font-bold text-primary">PDF</p>
            <p className="text-charcoal">Most Common</p>
          </div>
          <div className="bg-light-gray rounded-lg p-4 text-center">
            <p className="text-2xl font-bold text-accent">Today</p>
            <p className="text-charcoal">Last Upload</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default QuickStatsCard;
