import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import React from "react";

interface CaseSummaryProps {
  caseData: {
    upToDateSummary: string;
    caseSummary: string;
  };
}

export const CaseSummary: React.FC<CaseSummaryProps> = ({ caseData }) => {
  return (
    <>
      <Card className="mb-6 hover:shadow-lg transition-shadow">
        <CardHeader>
          <CardTitle>Overview</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground mb-4">{caseData.caseSummary}</p>
        </CardContent>
      </Card>

      <Card className="mb-6 hover:shadow-lg transition-shadow">
        <CardHeader>
          <CardTitle>Up-to-date Summary</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">{caseData.upToDateSummary}</p>
        </CardContent>
      </Card>
    </>
  );
};
