import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { ArrowLeft } from "lucide-react";

export const TimelineGhostCard: React.FC = () => {
  return (
    <Card className="h-full border-2 border-dashed border-muted-foreground/50 bg-muted/50">
      <CardContent className="flex flex-col items-center justify-center h-full text-center p-6">
        <ArrowLeft className="w-12 h-12 text-muted-foreground mb-4" />
        <h3 className="text-xl font-semibold text-muted-foreground mb-2">
          Select a Timeline Entry
        </h3>
        <p className="text-sm text-muted-foreground">
          Click on any item in the timeline to view its details here.
        </p>
      </CardContent>
    </Card>
  );
};
