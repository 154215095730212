import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileText, Upload, X } from "lucide-react";
import { useDocumentStore } from "@/store/documentStore";

interface UploadCardProps {
  caseId: string;
}

const UploadCard: React.FC<UploadCardProps> = ({ caseId }) => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const { uploadFiles, isUploading, uploadResults } = useDocumentStore();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFilesToUpload((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const removeFile = (fileToRemove: File) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const handleSubmit = async () => {
    if (filesToUpload.length > 0) {
      await uploadFiles(filesToUpload, caseId);
      setFilesToUpload([]); // Clear the file list after upload
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="flex items-center text-primary">
          <Upload className="mr-2" size={24} />
          Upload Files
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div
          {...getRootProps()}
          className={`border-2 border-dashed border-accent rounded-lg p-8 text-center cursor-pointer transition duration-300 ${
            isDragActive ? "bg-light-gray" : "hover:bg-light-gray"
          }`}
        >
          <input {...getInputProps()} />
          <FileText size={48} className="mx-auto mb-2 text-accent" />
          <p className="text-accent">
            {isDragActive
              ? "Drop the files here"
              : "Click to upload or drag PDF files here"}
          </p>
        </div>

        {filesToUpload.length > 0 && (
          <div className="mt-4">
            <h3 className="text-primary font-semibold mb-2">
              Files to upload:
            </h3>
            <ul className="space-y-2">
              {filesToUpload.map((file, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between bg-white p-2 rounded"
                >
                  <span className="text-charcoal truncate">{file.name}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => removeFile(file)}
                    className="text-destructive hover:text-destructive/90"
                  >
                    <X size={16} />
                  </Button>
                </li>
              ))}
            </ul>
            <Button
              onClick={handleSubmit}
              className="mt-4 w-full bg-accent hover:bg-accent/90 text-white"
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Upload Files"}
            </Button>
          </div>
        )}

        {uploadResults.length > 0 && (
          <div className="mt-4">
            <h3 className="text-primary font-semibold">Upload Results:</h3>
            <ul>
              {uploadResults.map((result, index) => (
                <li
                  key={index}
                  className={`text-sm ${
                    result.status === "success"
                      ? "text-accent"
                      : "text-destructive"
                  }`}
                >
                  {result.filename}: {result.status}
                  {result.error && ` - ${result.error}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default UploadCard;
