import PasswordPrompter from "@/components/PasswordPrompter";
import { useAuthStore } from "@/store/authStore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginButton from "./LoginButton";
import NavLinks from "./NavLinks";
import UserMenu from "./UserMenu";

const Nav: React.FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn, user, logout, isInitializing, login } = useAuthStore();
  const [isPasswordPromptOpen, setIsPasswordPromptOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  const handleReturnToOriginalTenant = async (password: string) => {
    if (!user) return;

    try {
      await login(user.email, password);
      setIsPasswordPromptOpen(false);
    } catch (error) {
      console.error("Failed to return to original tenant:", error);
    }
  };

  if (isInitializing) {
    return (
      <nav className="sticky top-0 z-50 bg-primary p-4 flex justify-between items-center h-16">
        <NavLinks />
        <div>{/* You can add a loading spinner or placeholder here */}</div>
      </nav>
    );
  }

  return (
    <>
      <nav className="sticky top-0 z-50 bg-primary p-4 flex justify-between items-center h-16">
        <NavLinks />
        <div>
          {isLoggedIn && user ? (
            <UserMenu user={user} onLogout={handleLogout} />
          ) : (
            <LoginButton onClick={() => navigate("/login")} />
          )}
        </div>
      </nav>

      <PasswordPrompter
        isOpen={isPasswordPromptOpen}
        onOpenChange={setIsPasswordPromptOpen}
        onSubmit={handleReturnToOriginalTenant}
        title="Return to Original Tenant"
        description={`Enter your password to return to ${user?.tenantName}`}
      />
    </>
  );
};

export default Nav;
