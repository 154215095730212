import { create } from "zustand";
import { DocumentData } from "./types";

interface PDFViewerState {
  documentData: DocumentData | null;
  hoveredSegment: string | null;
  selectedSegment: string | null;
  zoomLevel: number;
  currentPage: number;
  scrollingPanel: "left" | "right" | "both";
  controlledScrollTarget: string | null;
  setDocumentData: (data: DocumentData) => void;
  setHoveredSegment: (segmentId: string | null) => void;
  setSelectedSegment: (segmentId: string | null) => void;
  setZoomLevel: (level: number) => void;
  setCurrentPage: (page: number) => void;
  setScrollingPanel: (panel: "left" | "right" | "both") => void;
  setControlledScrollTarget: (target: string | null) => void;
}

export const usePDFViewerStore = create<PDFViewerState>((set) => ({
  documentData: null,
  hoveredSegment: null,
  selectedSegment: null,
  zoomLevel: 1,
  currentPage: 1,
  scrollingPanel: "left",
  controlledScrollTarget: null,
  setDocumentData: (data) => set({ documentData: data }),
  setHoveredSegment: (segmentId) => set({ hoveredSegment: segmentId }),
  setSelectedSegment: (segmentId) => set({ selectedSegment: segmentId }),
  setZoomLevel: (level) => set({ zoomLevel: level }),
  setCurrentPage: (page) => set({ currentPage: page }),
  setScrollingPanel: (panel) => set({ scrollingPanel: panel }),
  setControlledScrollTarget: (target) =>
    set({ controlledScrollTarget: target }),
}));
