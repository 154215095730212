import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useStore } from "@/store/caseStore";

interface RecentlyViewedSectionProps {
  caseId: string;
}

export const RecentlyViewedSection: React.FC<RecentlyViewedSectionProps> = ({
  caseId,
}) => {
  const { cases } = useStore();
  const recentlyViewed = cases[caseId]?.recentlyViewed || [];

  return (
    <Card className="mb-6 hover:shadow-lg transition-shadow">
      <CardHeader>
        <CardTitle>Recently Viewed</CardTitle>
      </CardHeader>
      <CardContent>
        {recentlyViewed.length === 0 ? (
          <p>No items viewed recently.</p>
        ) : (
          <ul className="space-y-2">
            {recentlyViewed.map((item) => (
              <li key={item.id}>
                <Link
                  to={`/cases/${caseId}/wiki/${item.id}`}
                  className="text-accent hover:underline"
                >
                  {item.title}
                </Link>
                <span className="text-sm text-muted-foreground ml-2">
                  ({new Date(item.viewedAt).toLocaleString()})
                </span>
              </li>
            ))}
          </ul>
        )}
      </CardContent>
    </Card>
  );
};
