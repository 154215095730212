import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronUp, Star, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { TimelineEvent } from "@/store/timelineStore";

interface CaseTimelineProps {
  events: TimelineEvent[];
  onToggleStatus: (id: string, action: "star" | "chevron") => void;
  onRemoveIrrelevant: (id: string) => void;
  onSelectEvent: (event: TimelineEvent) => void;
  selectedEventId: string | null;
}

export const CaseTimeline: React.FC<CaseTimelineProps> = ({
  events,
  onToggleStatus,
  onRemoveIrrelevant,
  onSelectEvent,
  selectedEventId,
}) => {
  const [expandedBatch, setExpandedBatch] = useState<number | null>(null);

  const getBatchedEvents = () => {
    const batched: (TimelineEvent | TimelineEvent[])[] = [];
    let currentBatch: TimelineEvent[] = [];

    events.forEach((event) => {
      if (event.status === "irrelevant") {
        currentBatch.push(event);
      } else {
        if (currentBatch.length > 0) {
          batched.push(currentBatch);
          currentBatch = [];
        }
        batched.push(event);
      }
    });

    if (currentBatch.length > 0) {
      batched.push(currentBatch);
    }

    return batched;
  };

  const batchedEvents = getBatchedEvents();

  const removeGroundingSyntax = (text: string) => {
    return text.replace(/\[([^|]+)\|[^\]]+\]/g, "$1");
  };

  return (
    <div className="relative">
      <div
        className="absolute left-3.5 top-0 w-0.5 bg-primary"
        style={{
          height: `calc(100% - ${batchedEvents.length > 0 ? "2rem" : "0px"})`,
        }}
      ></div>
      <AnimatePresence initial={false}>
        {batchedEvents.map((event, batchIndex) => (
          <React.Fragment key={batchIndex}>
            {Array.isArray(event) ? (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.3 }}
                className="mb-8 flex"
              >
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-muted flex items-center justify-center mr-4 z-10">
                  <div className="w-3 h-3 bg-muted-foreground rounded-full"></div>
                </div>
                <div className="flex-grow">
                  <button
                    onClick={() =>
                      setExpandedBatch(
                        expandedBatch === batchIndex ? null : batchIndex
                      )
                    }
                    className="w-full text-left p-2 rounded-md bg-muted hover:bg-muted/90 transition-colors flex items-center justify-between"
                  >
                    <span>{event.length} irrelevant items</span>
                    {expandedBatch === batchIndex ? (
                      <ChevronUp className="w-4 h-4" />
                    ) : (
                      <ChevronDown className="w-4 h-4" />
                    )}
                  </button>
                  <AnimatePresence initial={false}>
                    {expandedBatch === batchIndex && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {event.map((item) => (
                          <div
                            key={item.id}
                            className="mt-4 pl-4 border-l-2 border-muted"
                          >
                            <div className="flex items-center justify-between">
                              <div className="font-semibold text-muted-foreground">
                                {item.date}
                              </div>
                              <button
                                onClick={() => onRemoveIrrelevant(item.id)}
                                className="p-1 rounded-full transition-colors bg-muted text-muted-foreground hover:bg-muted/90"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </div>
                            <div className="font-bold mb-1 text-muted-foreground">
                              {item.title}
                            </div>
                            <p className="text-sm text-muted-foreground">
                              {removeGroundingSyntax(item.description)}
                            </p>
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.3 }}
                className={cn(
                  "mb-8 flex",
                  selectedEventId === event.id && "bg-accent/10 rounded-lg p-2"
                )}
              >
                <div
                  className={cn(
                    "flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center mr-4 z-10",
                    event.status === "important" ? "bg-accent" : "bg-primary",
                    selectedEventId === event.id &&
                      "ring-2 ring-accent ring-offset-2 ml-[-8px]"
                  )}
                >
                  <div className="w-3 h-3 bg-white rounded-full"></div>
                </div>
                <div className="flex-grow">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => onSelectEvent(event)}
                      className={cn(
                        "font-semibold hover:underline focus:outline-none",
                        selectedEventId === event.id
                          ? "text-accent"
                          : "text-primary"
                      )}
                    >
                      {event.date}
                    </button>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => onToggleStatus(event.id, "star")}
                        className={cn(
                          "p-1 rounded-full transition-colors",
                          event.status === "important"
                            ? "bg-accent text-accent-foreground"
                            : "bg-muted text-muted-foreground hover:bg-muted/90"
                        )}
                      >
                        <Star
                          className={cn(
                            "w-4 h-4",
                            event.status === "important" && "fill-current"
                          )}
                        />
                      </button>
                      <button
                        onClick={() => onToggleStatus(event.id, "chevron")}
                        className="p-1 rounded-full transition-colors bg-muted text-muted-foreground hover:bg-muted/90"
                      >
                        {event.status === "irrelevant" ? (
                          <ChevronUp className="w-4 h-4" />
                        ) : (
                          <ChevronDown className="w-4 h-4" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div
                    className={cn(
                      "font-bold mb-1",
                      selectedEventId === event.id && "text-accent"
                    )}
                  >
                    {event.title}
                  </div>
                  <p className="text-sm text-gray-600">
                    {removeGroundingSyntax(event.description)}
                  </p>
                </div>
              </motion.div>
            )}
          </React.Fragment>
        ))}
      </AnimatePresence>
    </div>
  );
};
