import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { CreateUser } from "@/store/userSettingsStore";
import { CreateUserData, UserRole } from "@/types/shared";
import { motion } from "framer-motion";
import React, { useState } from "react";

interface UserCreationFormProps {
  createUser: CreateUser;
  tenantName: string | undefined;
  isCreatingTenant?: boolean;
}

const UserCreationForm: React.FC<UserCreationFormProps> = ({
  createUser,
  tenantName,
  isCreatingTenant = false,
}) => {
  const [newUser, setNewUser] = useState<CreateUserData>({
    user: {
      email: "",
      tenant_name: tenantName || "",
    },
    new_params: {
      first_name: "",
      last_name: "",
      password: "",
      role: "tenant_user",
    },
  });
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "email") {
      setNewUser((prev) => ({ ...prev, user: { ...prev.user, email: value } }));
    } else {
      setNewUser((prev) => ({
        ...prev,
        new_params: { ...prev.new_params, [name]: value },
      }));
    }
  };

  const handleSelectChange = (value: UserRole) => {
    setNewUser((prev) => ({
      ...prev,
      new_params: { ...prev.new_params, role: value },
    }));
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    if (!newUser.new_params.first_name) {
      newErrors.first_name = "First Name is required.";
    }
    if (!newUser.new_params.last_name) {
      newErrors.last_name = "Last Name is required.";
    }
    if (!newUser.user.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(newUser.user.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!newUser.new_params.password) {
      newErrors.password = "Password is required.";
    } else if (newUser.new_params.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    } else if (
      !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(
        newUser.new_params.password
      )
    ) {
      newErrors.password = "Password must contain both letters and numbers.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleCreateUser = async () => {
    setLoading(true);
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setLoading(false);
      return;
    }
    try {
      await createUser(newUser);
      toast({
        title: "Success",
        description: isCreatingTenant
          ? "Tenant created successfully with admin user."
          : "User created successfully.",
      });
      // Reset form after successful creation
      setNewUser({
        user: {
          email: "",
          tenant_name: tenantName || "",
        },
        new_params: {
          first_name: "",
          last_name: "",
          password: "",
          role: "tenant_user",
        },
      });
    } catch (error) {
      console.error("Failed to create user:", error);
      toast({
        title: "Error",
        description: "Failed to create user. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCreateUser();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="grid grid-cols-1 md:grid-cols-2 gap-4"
    >
      <div className="space-y-2">
        <Label htmlFor="first_name">First Name</Label>
        <Input
          id="first_name"
          name="first_name"
          placeholder="First Name"
          value={newUser.new_params.first_name}
          onChange={handleInputChange}
          className={errors.first_name ? "border-red-500" : ""}
        />
        {errors.first_name && (
          <motion.span
            className="text-red-500 text-sm"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errors.first_name}
          </motion.span>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="last_name">Last Name</Label>
        <Input
          id="last_name"
          name="last_name"
          placeholder="Last Name"
          value={newUser.new_params.last_name}
          onChange={handleInputChange}
          className={errors.last_name ? "border-red-500" : ""}
        />
        {errors.last_name && (
          <motion.span
            className="text-red-500 text-sm"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errors.last_name}
          </motion.span>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          value={newUser.user.email}
          onChange={handleInputChange}
          className={errors.email ? "border-red-500" : ""}
        />
        {errors.email && (
          <motion.span
            className="text-red-500 text-sm"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errors.email}
          </motion.span>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="password">Password</Label>
        <Input
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          value={newUser.new_params.password}
          onChange={handleInputChange}
          className={errors.password ? "border-red-500" : ""}
        />
        {errors.password && (
          <motion.span
            className="text-red-500 text-sm"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errors.password}
          </motion.span>
        )}
      </div>
      {!isCreatingTenant && (
        <Select
          onValueChange={(value) => handleSelectChange(value as UserRole)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select Role" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="tenant_admin">Tenant Admin</SelectItem>
            <SelectItem value="tenant_user">Tenant User</SelectItem>
          </SelectContent>
        </Select>
      )}
      <Button
        type="submit"
        disabled={loading}
        className="bg-accent hover:bg-accent/90 text-white flex items-center justify-center"
      >
        {loading ? <div className="loader mr-2" /> : null}
        {isCreatingTenant ? "Create Tenant with Admin" : "Create User"}
      </Button>
    </form>
  );
};

export default UserCreationForm;
