import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useCaseUiStore } from "../case/caseUiStore";

const CaseSettings: React.FC = () => {
  const { setSecondaryHeader } = useCaseUiStore();

  useEffect(() => {
    setSecondaryHeader("Settings");
  }, [setSecondaryHeader]);

  return <Outlet />;
};

export default CaseSettings;
