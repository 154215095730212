import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBreadcrumbStore } from "@/store/breadcrumbStore";
import { useStore } from "@/store/caseStore";
import { FavoritesSection } from "./FavoritesSection";
import { KeyDocumentsCard } from "./KeyDocumentsCard";
import { KeyEntitiesCard } from "./KeyEntitiesCard";
import { KeyEventsCard } from "./KeyEventsCard";
import { RecentlyViewedSection } from "./RecentlyViewedSection";
import { useCaseUiStore } from "../case/caseUiStore";

export const WikiOverview: React.FC = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { getCaseById } = useStore();
  const setBreadcrumbs = useBreadcrumbStore((state) => state.setBreadcrumbs);

  const caseData = getCaseById(caseId as string);

  const { setSecondaryHeader } = useCaseUiStore();

  useEffect(() => {
    setSecondaryHeader("Wiki");
  }, [setSecondaryHeader]);

  useEffect(() => {
    setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Cases", path: "/cases" },
      {
        label: caseData?.name || decodeURIComponent(caseId!),
        path: `/cases/${caseId}`,
      },
      { label: "Wiki", path: `/cases/${caseId}/wiki` },
    ]);
  }, [setBreadcrumbs, caseId, caseData?.name]);

  if (!caseData) {
    return <div className="text-red-500">Error: Case not found</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
        {/* Left column */}
        <div className="lg:col-span-2">
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-1">
            <KeyEventsCard caseId={caseId!} />
            <KeyEntitiesCard
              title="Key People"
              entities={caseData.entities.people}
              icon="Users"
              caseId={caseId as string}
              entityType="people"
            />
            <KeyEntitiesCard
              title="Key Locations"
              entities={caseData.entities.locations}
              icon="MapPin"
              caseId={caseId as string}
              entityType="locations"
            />
            <KeyEntitiesCard
              title="Key Organizations"
              entities={caseData.entities.organizations}
              icon="Building"
              caseId={caseId as string}
              entityType="organizations"
            />
            <KeyDocumentsCard caseData={caseData} />
          </div>
        </div>

        {/* Right column */}
        <div className="space-y-6">
          <FavoritesSection caseId={caseId as string} />
          <RecentlyViewedSection caseId={caseId as string} />
        </div>
      </div>
    </div>
  );
};
