import { create } from "zustand";
import type { ChatState, ChatStateActions } from "./types";
import { createChatActions } from "./storeActions";

const initialState: ChatState = {
  conversations: [],
  currentConversationId: null,
  isLoading: false,
  error: null,
  isOpen: false,
};

export const useChatStore = create<ChatState & ChatStateActions>(
  (set, get) => ({
    ...initialState,
    ...createChatActions(set, get),
  })
);
