import { Card, CardContent } from "@/components/ui/card";
import { useApi } from "@/lib/utils";
import { motion } from "framer-motion";
import React from "react";

interface DocumentPreviewProps {
  fileId: string;
  previewWidth: number;
}

interface PreviewResponse {
  one_page_overview: string;
}

const LoadingPlaceholder = () => {
  // Animation variants for the gradient overlay
  const overlayVariants = {
    initial: { x: "-100%" },
    animate: {
      x: "100%",
      transition: {
        repeat: Infinity,
        duration: 2,
        ease: "linear",
      },
    },
  };

  // Subtle pulse animation for the container
  const pulseVariants = {
    initial: { opacity: 0.5 },
    animate: {
      opacity: 0.8,
      transition: {
        repeat: Infinity,
        repeatType: "reverse" as const,
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      className="relative w-full h-full rounded overflow-hidden bg-light-gray"
      initial="initial"
      animate="animate"
      variants={pulseVariants}
    >
      {/* Base gradient */}
      <div className="absolute inset-0 bg-gradient-to-r from-primary/5 via-accent/5 to-primary/5" />

      {/* Animated overlay */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
        variants={overlayVariants}
      />
    </motion.div>
  );
};

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  fileId,
  previewWidth,
}) => {
  const { data, loading, error } = useApi<PreviewResponse>(
    `/ocr/one_page_overview?file_id=${fileId}`
  );

  const containerStyles = {
    maxWidth: `${previewWidth}px`,
  };

  if (loading) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <div className="flex flex-col items-center space-y-4">
            <div
              className="relative w-full flex justify-center"
              style={containerStyles}
            >
              <div className="w-full h-[300px]">
                <LoadingPlaceholder />
              </div>
            </div>
            <div className="h-4 w-32">
              <LoadingPlaceholder />
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error || !data?.one_page_overview) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <motion.div
            className="w-full h-[300px] bg-light-gray flex items-center justify-center rounded"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <p className="text-destructive">Failed to load preview</p>
          </motion.div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardContent className="p-6">
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.4,
            ease: "easeOut",
          }}
        >
          <div
            className="relative w-full flex justify-center"
            style={containerStyles}
          >
            <img
              src={`data:image/jpeg;base64,${data.one_page_overview}`}
              alt="Document preview"
              className="w-full h-auto rounded shadow-sm"
            />
          </div>
          <motion.p
            className="text-sm text-muted-foreground mt-4 italic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            First page preview
          </motion.p>
        </motion.div>
      </CardContent>
    </Card>
  );
};

export default DocumentPreview;
