import AccessDenied from "@/components/AccessDenied";
import GravixLoader from "@/components/GravixLoader";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/hooks/use-toast";
import { useAuthStore } from "@/store/authStore";
import { useCasesMetadataStore } from "@/store/casesMetadataStore";
import { CasePermission, useSettingsStore } from "@/store/userSettingsStore";
import { Loader2, UserPlus, X } from "lucide-react";
import React, { useEffect, useState } from "react";

const Permissions: React.FC = () => {
  const {
    users,
    casePermissions,
    fetchUsers,
    fetchCasePermissions,
    addCasePermission,
    updateCasePermission,
    removeCasePermission,
    isLoading,
  } = useSettingsStore();

  const { cases, fetchCases } = useCasesMetadataStore();

  const [selectedCase, setSelectedCase] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [selectedPermission, setSelectedPermission] = useState<
    "read" | "write"
  >("read");
  const { isInitializing, user } = useAuthStore();
  const [isInitializingPermissions, setIsInitializingPermissions] =
    useState(true);
  const [editingPermissions, setEditingPermissions] = useState<
    CasePermission[]
  >([]);
  const [isEditing, setIsEditing] = useState(false);
  const [permissionsToDelete, setPermissionsToDelete] = useState<string[]>([]);
  const [permissionToDelete, setPermissionToDelete] = useState<string | null>(
    null
  );
  const [originalPermissions, setOriginalPermissions] = useState<
    CasePermission[]
  >([]);
  const { toast } = useToast();

  useEffect(() => {
    fetchCases();
    fetchUsers();
  }, [fetchCases, fetchUsers]);

  useEffect(() => {
    if (!isInitializing && user) {
      setIsInitializingPermissions(false);
    }
  }, [isInitializing, user]);

  useEffect(() => {
    if (selectedCase) {
      fetchCasePermissions(selectedCase);
    }
  }, [selectedCase, fetchCasePermissions]);

  useEffect(() => {
    setEditingPermissions(casePermissions);
    setOriginalPermissions(casePermissions);
  }, [casePermissions]);

  const handleAddPermission = () => {
    if (selectedCase && selectedUser && selectedPermission) {
      addCasePermission({
        permission: {
          email: selectedUser,
          case_name: selectedCase,
          tenant_name: user?.tenantName || "",
        },
        new_params: {
          permission_type: selectedPermission,
        },
      });
    }
  };

  const handlePermissionChange = (
    userEmail: string,
    newPermission: "read" | "write"
  ) => {
    setEditingPermissions((prevPermissions) =>
      prevPermissions.map((p) =>
        p.user.email === userEmail ? { ...p, permission: newPermission } : p
      )
    );
  };

  const handleRemovePermission = (userId: string) => {
    setPermissionsToDelete((prev) => [...prev, userId]);
  };

  const handleSaveChanges = async () => {
    try {
      for (const editedPermission of editingPermissions) {
        if (editedPermission.user.email && editedPermission.permission) {
          const payload = {
            permission: {
              email: editedPermission.user.email,
              case_name: editedPermission.case.name,
              tenant_name: user?.tenantName || "",
            },
            new_params: {
              permission_type: editedPermission.permission,
            },
          };

          // Check if this is a new permission or an existing one
          const existingPermission = originalPermissions.find(
            (p) =>
              p.user.email === editedPermission.user.email &&
              p.case.id === editedPermission.case.id
          );

          if (existingPermission) {
            await updateCasePermission(payload);
          } else {
            await addCasePermission(payload);
          }
        }
      }
      for (const userId of permissionsToDelete) {
        await removeCasePermission(selectedCase, userId);
      }
      toast({
        title: "Success",
        description: "Permission changes saved successfully.",
      });
      setIsEditing(false);
      setPermissionsToDelete([]);
      fetchCasePermissions(selectedCase);
    } catch (error) {
      console.error("Failed to save permission changes:", error);
      toast({
        title: "Error",
        description: "Failed to save permission changes. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleUndoChanges = () => {
    setEditingPermissions(originalPermissions);
    setPermissionsToDelete([]);
  };

  if (isInitializing || isInitializingPermissions) {
    return <GravixLoader />;
  }

  if (
    (user?.role !== "tenant_admin" && user?.role !== "sys_admin") ||
    isInitializingPermissions
  ) {
    return <AccessDenied />;
  }

  return (
    <Card className="w-full max-w-4xl mx-auto mt-8">
      <CardHeader className="bg-primary text-primary-foreground rounded-b-none rounded-t-lg">
        <CardTitle className="text-2xl font-bold">Case Permissions</CardTitle>
      </CardHeader>
      <CardContent className="p-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Select onValueChange={setSelectedCase}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select Case" />
            </SelectTrigger>
            <SelectContent>
              {cases.map((c) => (
                <SelectItem key={c.name} value={c.name}>
                  {c.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select onValueChange={setSelectedUser}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select User" />
            </SelectTrigger>
            <SelectContent>
              {users.map((u) => (
                <SelectItem key={u.email} value={u.email || ""}>
                  {`${u.firstName} (${u.email})`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            onValueChange={(value) =>
              setSelectedPermission(value as "read" | "write")
            }
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select Permission" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="read">Read</SelectItem>
              <SelectItem value="write">Write</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button
          onClick={handleAddPermission}
          disabled={
            isLoading || !selectedCase || !selectedUser || !selectedPermission
          }
          className="w-full md:w-auto bg-accent hover:bg-accent/90 text-white"
        >
          {isLoading ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <UserPlus className="mr-2 h-4 w-4" />
          )}
          Add Permission
        </Button>
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Case Permissions</h3>
          <Button onClick={() => setIsEditing(!isEditing)} variant="outline">
            {isEditing ? "Cancel" : "Edit Permissions"}
          </Button>
        </div>

        {isEditing && (
          <div className="flex items-center mb-4">
            <Button
              onClick={handleSaveChanges}
              className="bg-accent hover:bg-accent/90 text-white"
            >
              Save Changes
            </Button>
            <Button
              onClick={handleUndoChanges}
              variant="outline"
              className="ml-2"
            >
              Undo Changes
            </Button>
          </div>
        )}

        <Table>
          <TableHeader>
            <TableRow className="bg-light-gray">
              <TableHead className="font-bold text-primary">User</TableHead>
              <TableHead className="font-bold text-primary">Case</TableHead>
              <TableHead className="font-bold text-primary">
                Permission
              </TableHead>
              {isEditing && (
                <TableHead className="font-bold text-primary">
                  Actions
                </TableHead>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {editingPermissions.map((cp) => (
              <TableRow
                key={`${cp.user.email}-${cp.case.id}`}
                className={
                  permissionsToDelete.includes(cp.user.email)
                    ? "opacity-50"
                    : ""
                }
              >
                <TableCell>
                  {cp.user.first_name} {cp.user.last_name} ({cp.user.email})
                </TableCell>
                <TableCell>{cp.case.name}</TableCell>
                <TableCell>
                  {isEditing ? (
                    <Select
                      value={cp.permission}
                      onValueChange={(value: "read" | "write") =>
                        handlePermissionChange(cp.user.email, value)
                      }
                      disabled={permissionsToDelete.includes(cp.user.email)}
                    >
                      <SelectTrigger className="w-[100px]">
                        <SelectValue placeholder="Select permission" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="read">Read</SelectItem>
                        <SelectItem value="write">Write</SelectItem>
                      </SelectContent>
                    </Select>
                  ) : (
                    <Badge
                      variant={
                        cp.permission === "write" ? "default" : "secondary"
                      }
                      className={
                        cp.permission === "write"
                          ? "bg-accent text-white"
                          : "bg-light-gray text-charcoal"
                      }
                    >
                      {cp.permission}
                    </Badge>
                  )}
                </TableCell>
                {isEditing && (
                  <TableCell>
                    <div className="flex items-center space-x-2">
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            onClick={() => setPermissionToDelete(cp.user.email)}
                            variant="destructive"
                            size="sm"
                            disabled={permissionsToDelete.includes(
                              cp.user.email
                            )}
                          >
                            {permissionsToDelete.includes(cp.user.email)
                              ? "Pending Deletion"
                              : "Remove"}
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                              This action will mark the permission for deletion.
                              The change will be applied when you save all
                              changes.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() =>
                                handleRemovePermission(permissionToDelete!)
                              }
                            >
                              Confirm
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                      {permissionsToDelete.includes(cp.user.email) && (
                        <Button
                          onClick={() =>
                            setPermissionsToDelete((prev) =>
                              prev.filter((id) => id !== cp.user.email)
                            )
                          }
                          variant="ghost"
                          size="sm"
                          className="p-0"
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Permissions;
