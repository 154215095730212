import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
} from "@/components/ui/alert-dialog";
import { Quote } from "lucide-react";

interface GroundedTextProps {
  text: string;
  source: string;
}

export const GroundedText: React.FC<GroundedTextProps> = ({ text, source }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <span
        className="inline-flex items-center gap-1 cursor-pointer group relative"
        onClick={() => setIsOpen(true)}
      >
        {/* <Quote size={16} className="text-accent absolute -left-5 top-0" /> */}
        <span className="text-primary font-medium italic bg-light-gray px-1 rounded border-b-2 border-accent group-hover:bg-accent/10 transition-colors duration-200">
          {text}
        </span>
        <Quote size={14} className="text-accent rotate-180 mt-3 fill-accent" />
      </span>
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogContent className="bg-white border-2 border-accent rounded-lg shadow-lg max-w-2xl">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-2xl font-bold text-primary flex items-center gap-2">
              <Quote size={24} className="text-accent" />
              Source Information
            </AlertDialogTitle>
            <AlertDialogDescription className="mt-4 text-foreground">
              <div className="bg-light-gray p-4 rounded-md border-l-4 border-accent">
                <p className="font-semibold mb-2 text-primary">Quoted Text:</p>
                <p className="text-charcoal italic">&ldquo;{text}&rdquo;</p>
              </div>
            </AlertDialogDescription>
            <AlertDialogDescription className="mt-4">
              <div className="bg-light-gray p-4 rounded-md">
                <p className="font-semibold mb-2 text-primary">Source:</p>
                <p className="text-charcoal">{source}</p>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="bg-primary text-white hover:bg-primary/90 transition-colors duration-200">
              Close
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
