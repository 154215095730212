import React from "react";
import { cn } from "@/lib/utils";

interface TextSegmentProps {
  id: string;
  text: string;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const TextSegment: React.FC<TextSegmentProps> = ({
  id,
  text,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={cn(
        "p-2 rounded-md cursor-pointer transition-all duration-200",
        isSelected ? "bg-primary text-primary-foreground" : "hover:bg-accent/20"
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}
    </div>
  );
};

export default TextSegment;
