import React from "react";

interface DocumentTagsProps {
  tags: string[];
  status: string;
}

const DocumentTags: React.FC<DocumentTagsProps> = ({ tags, status }) => {
  return (
    <div className="mb-4">
      {tags.map((tag) => (
        <span
          key={tag}
          className="inline-block bg-accent text-white text-xs px-2 py-1 rounded mr-2"
        >
          {tag}
        </span>
      ))}
      <span className="font-bold text-accent">Status: {status}</span>
    </div>
  );
};

export default DocumentTags;
