import { Badge } from "@/components/ui/badge";
import useFeedStore from "@/store/feedStore";
import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useState } from "react";

const MediaFeed: React.FC = () => {
  const { mediaItems, fetchItems, markItemAsSeen } = useFeedStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMediaItems = async () => {
      setIsLoading(true);
      setError(null);
      try {
        await fetchItems("media");
      } catch (err) {
        console.error(err);
        setError("Failed to load media items. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    loadMediaItems();
  }, [fetchItems]);

  const handleClick = (id: string, href: string) => {
    markItemAsSeen("media", id);
    window.open(href, "_blank", "noopener,noreferrer");
  };

  if (isLoading) return <p>Loading media items...</p>;
  if (error) return <p className="text-destructive">{error}</p>;

  return (
    <ul className="space-y-4">
      {mediaItems.map((item) => (
        <li key={item.id} className="border-b pb-2">
          <div className="flex justify-between items-start">
            <h3
              className="font-semibold cursor-pointer hover:underline text-accent"
              onClick={() => handleClick(item.id, item.href)}
            >
              {item.title}
            </h3>
            <div className="flex items-center">
              {!item.seen && (
                <Badge
                  variant="outline"
                  className="mr-2 text-accent border-accent"
                >
                  New
                </Badge>
              )}
            </div>
          </div>
          <p className="text-sm text-charcoal">
            <span
              className="text-accent cursor-pointer hover:underline"
              onClick={() =>
                window.open(item.href, "_blank", "noopener,noreferrer")
              }
            >
              {item.source}
            </span>
            {" - "}
            {formatDistanceToNow(new Date(item.date), { addSuffix: true })}
          </p>
          <p className="mt-1 text-foreground">{item.summary}</p>
        </li>
      ))}
    </ul>
  );
};

export default MediaFeed;
