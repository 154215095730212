import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavLinks: React.FC = () => {
  const location = useLocation();

  const isActive = (path: string) => {
    if (path === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(path);
  };

  return (
    <ul className="flex space-x-4">
      <li>
        <Link
          to="/"
          className={`text-white hover:text-accent transition-colors duration-200 ${
            isActive("/") ? "font-bold border-b-2 border-accent" : ""
          }`}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          to="/cases"
          className={`text-white hover:text-accent transition-colors duration-200 ${
            isActive("/cases") ? "font-bold border-b-2 border-accent" : ""
          }`}
        >
          Cases
        </Link>
      </li>
    </ul>
  );
};

export default NavLinks;
