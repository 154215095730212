import React from "react";
import { Textarea } from "@/components/ui/textarea";

interface CaseStrategyProps {
  strategy: string;
  handleStrategyChange: (value: string) => void;
}

const CaseStrategy: React.FC<CaseStrategyProps> = ({
  strategy,
  handleStrategyChange,
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Case Strategy
      </label>
      <Textarea
        value={strategy}
        onChange={(e) => handleStrategyChange(e.target.value)}
        placeholder="Outline the strategy for this case"
        className="w-full"
        rows={10}
      />
    </div>
  );
};

export default CaseStrategy;
