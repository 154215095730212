import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import React, { useEffect } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import MediaFeed from "./MediaFeed";
import NewFiles from "./NewFiles";
import SocialMediaFeed from "./SocialMediaFeed";
import { Globe, House } from "lucide-react";
import { useCaseUiStore } from "../case/caseUiStore";

const FeedPage: React.FC = () => {
  const { feedType } = useParams<{ feedType?: string }>();
  const navigate = useNavigate();
  const { setSecondaryHeader } = useCaseUiStore();

  useEffect(() => {
    setSecondaryHeader(
      `Feed: ${
        feedType ? feedType.charAt(0).toUpperCase() + feedType.slice(1) : "Feed"
      }`
    );
  }, [setSecondaryHeader, feedType]);

  useEffect(() => {
    if (!feedType) {
      navigate("external", { replace: true });
    }
  }, [feedType, navigate]);

  if (!feedType || (feedType !== "external" && feedType !== "internal")) {
    return <Navigate to="external" replace />;
  }

  const handleFeedToggle = (feed: "external" | "internal") => {
    setSecondaryHeader(`Feed: ${feed.charAt(0).toUpperCase() + feed.slice(1)}`);
    navigate(`./${feed}`, { relative: "path" });
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center space-y-4 mb-4">
        <div className="p-1 bg-secondary-foreground rounded-lg w-full max-w-md">
          <div className="flex rounded-md overflow-hidden">
            <Button
              variant="ghost"
              className={`flex-1 px-4 py-2 transition-colors duration-200 ${
                feedType === "external"
                  ? "bg-primary text-primary-foreground cursor-default hover:bg-primary"
                  : "bg-card text-foreground hover:bg-muted hover:text-foreground"
              }`}
              onClick={() => handleFeedToggle("external")}
            >
              <Globe className="w-4 h-4 mr-2" />
              External
            </Button>
            <Button
              variant="ghost"
              className={`ml-2 flex-1 px-4 py-2 transition-colors duration-200 ${
                feedType === "internal"
                  ? "bg-primary text-primary-foreground cursor-default hover:bg-primary"
                  : "bg-card text-foreground hover:bg-muted hover:text-foreground"
              }`}
              onClick={() => handleFeedToggle("internal")}
            >
              <House className="w-4 h-4 mr-2" />
              Internal
            </Button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {feedType === "external" && (
          <>
            <Card className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <CardTitle>Media Updates</CardTitle>
              </CardHeader>
              <CardContent>
                <MediaFeed />
              </CardContent>
            </Card>

            <Card className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <CardTitle>Social Media Updates</CardTitle>
              </CardHeader>
              <CardContent>
                <SocialMediaFeed />
              </CardContent>
            </Card>
          </>
        )}

        {feedType === "internal" && (
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle>New Case Files</CardTitle>
            </CardHeader>
            <CardContent>
              <NewFiles />
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default FeedPage;
