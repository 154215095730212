import { StateCreator } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { chatApi } from "./storeApi";
import {
  handleApiError,
  mapServerConversation,
  parseServerMessage,
  type ServerMessage,
} from "./storeUtils";
import type {
  ChatConversation,
  ChatMessage,
  ChatState,
  ChatStateActions,
} from "./types";

type ChatStore = ChatState & ChatStateActions;

export const createChatActions = (
  set: Parameters<StateCreator<ChatStore>>[0],
  get: () => ChatStore
): ChatStateActions => ({
  openChat: () => {
    const { currentConversationId } = get();
    if (currentConversationId) {
      get().syncConversationWithServer(currentConversationId);
    }
    set({ isOpen: true, error: null });
  },

  closeChat: () => set({ isOpen: false, error: null }),

  setError: (error: string | null) => set({ error }),

  createConversation: async (caseId: string, title?: string) => {
    const defaultTitle = `Chat ${uuidv4().slice(0, 8)}`;
    const newConversation = {
      id: "",
      caseId,
      title: title || defaultTitle,
      messages: [],
      lastActivity: new Date(),
      syncStatus: "pending" as const,
    };

    try {
      set({ isLoading: true, error: null });
      const response = await chatApi.createChat(caseId, newConversation.title);
      newConversation.id = response.chat_id;

      set((state: ChatState) => ({
        conversations: [
          ...state.conversations,
          { ...newConversation, syncStatus: "synced" },
        ],
        currentConversationId: newConversation.id,
      }));

      return newConversation.id;
    } catch (error) {
      console.error("Error creating conversation:", error);
      set((state: ChatState) => ({
        error: handleApiError(error as Error),
        conversations: [
          ...state.conversations,
          { ...newConversation, syncStatus: "failed" },
        ],
      }));
      return newConversation.id;
    } finally {
      set({ isLoading: false });
    }
  },

  setCurrentConversation: async (conversationId: string) => {
    try {
      const { conversations } = get();
      const conversation = conversations.find(
        (conv: ChatConversation) => conv.id === conversationId
      );

      if (!conversation) {
        throw new Error("Invalid conversation ID");
      }

      set({ currentConversationId: conversationId, error: null });

      if (conversation.syncStatus !== "synced") {
        await get().syncConversationWithServer(conversationId);
      } else {
        await get().fetchConversationHistory(conversationId);
      }
    } catch (error) {
      set({ error: handleApiError(error as Error) });
    }
  },

  sendMessage: async (content: string) => {
    const {
      currentConversationId,
      addMessageToCurrentConversation,
      conversations,
    } = get();

    if (!content.trim() || !currentConversationId) return;

    const currentConversation = conversations.find(
      (conv: ChatConversation) => conv.id === currentConversationId
    );
    if (!currentConversation) return;

    const message: ChatMessage = {
      id: uuidv4(),
      role: "user",
      content,
      timestamp: new Date(),
      syncStatus: "pending",
    };

    addMessageToCurrentConversation(message);

    try {
      set({ isLoading: true, error: null });

      const response = await chatApi.sendMessage(
        currentConversationId,
        content,
        currentConversation.caseId
      );

      // Update message sync status
      set((state: ChatState) => ({
        conversations: state.conversations.map((conv: ChatConversation) =>
          conv.id === currentConversationId
            ? {
                ...conv,
                messages: conv.messages.map((msg: ChatMessage) =>
                  msg.id === message.id ? { ...msg, syncStatus: "synced" } : msg
                ),
              }
            : conv
        ),
      }));

      const assistantMessage = parseServerMessage({
        ...response,
        id: uuidv4(),
        timestamp: new Date(),
      });

      addMessageToCurrentConversation(assistantMessage);
    } catch (error) {
      console.error("Error sending message:", error);
      set((state: ChatState) => ({
        error: handleApiError(error as Error),
        conversations: state.conversations.map((conv: ChatConversation) =>
          conv.id === currentConversationId
            ? {
                ...conv,
                messages: conv.messages.map((msg: ChatMessage) =>
                  msg.id === message.id ? { ...msg, syncStatus: "failed" } : msg
                ),
              }
            : conv
        ),
      }));
    } finally {
      set({ isLoading: false });
    }
  },

  getChatHistory: (conversationId: string) => {
    const conversation = get().conversations.find(
      (conv: ChatConversation) => conv.id === conversationId
    );
    return conversation ? conversation.messages : [];
  },

  clearChatHistory: async (conversationId: string) => {
    try {
      set({ isLoading: true, error: null });
      await chatApi.deleteConversation(conversationId);

      set((state: ChatState) => ({
        conversations: state.conversations.map((conv) =>
          conv.id === conversationId
            ? {
                ...conv,
                messages: [],
                lastActivity: new Date(),
                syncStatus: "synced",
              }
            : conv
        ),
      }));
    } catch (error) {
      set({ error: handleApiError(error as Error) });
    } finally {
      set({ isLoading: false });
    }
  },

  getConversations: async (caseId?: string) => {
    try {
      set({ isLoading: true, error: null });
      const response = await chatApi.getConversations(caseId);
      const conversations = response.map(mapServerConversation);

      set({ conversations });

      return conversations;
    } catch (error) {
      set({ error: handleApiError(error as Error) });
      return get().conversations.filter(
        (conv: ChatConversation) => !caseId || conv.caseId === caseId
      );
    } finally {
      set({ isLoading: false });
    }
  },

  deleteConversation: async (conversationId: string) => {
    try {
      set({ isLoading: true, error: null });
      await chatApi.deleteConversation(conversationId);

      set((state: ChatState) => {
        const updatedConversations = state.conversations.filter(
          (conv) => conv.id !== conversationId
        );

        const newCurrentConversationId =
          state.currentConversationId === conversationId
            ? updatedConversations.length > 0
              ? updatedConversations[0].id
              : null
            : state.currentConversationId;

        return {
          conversations: updatedConversations,
          currentConversationId: newCurrentConversationId,
        };
      });
    } catch (error) {
      set({ error: handleApiError(error as Error) });
    } finally {
      set({ isLoading: false });
    }
  },

  addMessageToCurrentConversation: (message: ChatMessage) =>
    set((state: ChatState) => {
      const { currentConversationId, conversations } = state;
      if (!currentConversationId) return state;

      return {
        conversations: conversations.map((conv) =>
          conv.id === currentConversationId
            ? {
                ...conv,
                messages: [...conv.messages, message],
                lastActivity: new Date(),
              }
            : conv
        ),
      };
    }),

  updateConversationTitle: async (conversationId: string, title: string) => {
    try {
      set({ isLoading: true, error: null });
      await chatApi.updateConversationTitle(conversationId, title);

      set((state: ChatState) => ({
        conversations: state.conversations.map((conv) =>
          conv.id === conversationId ? { ...conv, title } : conv
        ),
      }));
    } catch (error) {
      set({ error: handleApiError(error as Error) });
    } finally {
      set({ isLoading: false });
    }
  },

  syncConversationWithServer: async (conversationId: string) => {
    const { conversations } = get();
    const conversation = conversations.find(
      (conv: ChatConversation) => conv.id === conversationId
    );

    if (!conversation) return;

    try {
      set({ isLoading: true, error: null });

      if (conversation.syncStatus !== "synced") {
        await chatApi.createChat(conversation.caseId, conversation.title);
      }

      const pendingMessages = conversation.messages
        .filter((msg: ChatMessage) => msg.syncStatus !== "synced")
        .sort(
          (a: ChatMessage, b: ChatMessage) =>
            a.timestamp.getTime() - b.timestamp.getTime()
        );

      for (const message of pendingMessages) {
        await chatApi.sendMessage(
          conversationId,
          message.content,
          conversation.caseId
        );
      }

      set((state: ChatState) => ({
        conversations: state.conversations.map((conv) =>
          conv.id === conversationId
            ? {
                ...conv,
                syncStatus: "synced",
                messages: conv.messages.map((msg) => ({
                  ...msg,
                  syncStatus: "synced",
                })),
              }
            : conv
        ),
      }));

      await get().fetchConversationHistory(conversationId);
    } catch (error) {
      set({ error: handleApiError(error as Error) });
    } finally {
      set({ isLoading: false });
    }
  },

  fetchConversationHistory: async (conversationId: string) => {
    try {
      set({ isLoading: true, error: null });
      const messages = await chatApi.getConversationHistory(conversationId);

      set((state: ChatState) => ({
        conversations: state.conversations.map((conv) =>
          conv.id === conversationId
            ? {
                ...conv,
                messages: messages.map((msg: ServerMessage) => ({
                  ...parseServerMessage(msg),
                  syncStatus: "synced",
                })),
                lastActivity: new Date(),
                syncStatus: "synced",
              }
            : conv
        ),
      }));
    } catch (error) {
      set({ error: handleApiError(error as Error) });
    } finally {
      set({ isLoading: false });
    }
  },

  retryFailedSync: async (conversationId: string) => {
    await get().syncConversationWithServer(conversationId);
  },
});
