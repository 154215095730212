import { LoaderBubble } from "@/components/LoaderBubble";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Textarea } from "@/components/ui/textarea";
import { useChatStore } from "@/features/chat/chatStore";
import { cn } from "@/lib/utils";
import {
  AlertCircle,
  ChevronDown,
  ChevronUp,
  Loader2,
  Send,
} from "lucide-react";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import MarkdownContent from "./ChatMarkdownContent";
import ChatSuggestions from "./ChatSuggestions";
import { ChatMessage } from "./types";

const parseResponse = (content: string) => {
  try {
    // Strip away markdown JSON formatting if present
    let jsonContent = content;
    if (content.startsWith("```json")) {
      jsonContent = content.replace(/```json\n|\n```/g, "");
    }

    const parsed = JSON.parse(jsonContent);
    if (parsed.operation && parsed.parameters) {
      return parsed.parameters.operation_result.results;
    }
  } catch (error) {
    // If parsing fails, return original content
    console.log("Error parsing response:", error);
    return content;
  }
  return content;
};

export const MessageBubble = ({
  message,
  isUser,
}: {
  message: ChatMessage;
  isUser: boolean;
}) => {
  const isSystem = message.role === "system";
  const parsedContent = parseResponse(message.content);

  return (
    <div
      className={cn(
        "group flex w-full",
        isUser ? "justify-end" : "justify-start"
      )}
    >
      <div
        className={cn(
          "max-w-[85%] transition-all",
          isUser ? "items-end" : "items-start"
        )}
      >
        <div
          className={cn(
            "relative px-4 py-3 rounded-2xl shadow-sm",
            "transition-all duration-200",
            isUser
              ? [
                  "bg-primary text-primary-foreground",
                  "rounded-br-sm",
                  "hover:bg-primary/90",
                ]
              : isSystem
              ? [
                  "bg-muted text-muted-foreground",
                  "rounded-bl-sm",
                  "hover:bg-muted/90",
                ]
              : [
                  "bg-accent text-accent-foreground",
                  "rounded-bl-sm",
                  "hover:bg-accent/90",
                ]
          )}
        >
          <MarkdownContent
            content={parsedContent}
            className={cn(
              "prose prose-sm max-w-none",
              isUser
                ? "prose-invert prose-pre:bg-primary-foreground/5"
                : "prose-accent prose-pre:bg-accent-foreground/5"
            )}
          />

          {message.operation_type && (
            <div className="mt-2 pt-2 text-xs border-t border-current/10">
              Operation: {message.operation_type}
            </div>
          )}

          {message.metadata && message.metadata.case_id && (
            <div className="mt-2 pt-2 text-xs border-t border-current/10 space-y-1">
              <div className="opacity-80">
                {`case_id: ${message.metadata.case_id}`}
              </div>
            </div>
          )}
        </div>

        <div
          className={cn(
            "text-xs text-muted-foreground mt-1 px-1",
            "opacity-0 group-hover:opacity-100 transition-opacity",
            isUser ? "text-right" : "text-left"
          )}
        >
          {new Date(message.timestamp).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
    </div>
  );
};

export const ChatComponent = ({
  caseId,
  chatId,
  initialTitle,
  className,
}: {
  caseId: string;
  chatId: string;
  initialTitle: string;
  className: string;
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const {
    currentConversationId,
    sendMessage,
    getChatHistory,
    createConversation,
    fetchConversationHistory,
    error,
  } = useChatStore();

  useEffect(() => {
    const initChat = async () => {
      if (!currentConversationId) {
        createConversation(chatId, initialTitle || `Case ${caseId} Chat`);
      }
      await fetchConversationHistory(chatId);
    };

    initChat();
  }, [
    currentConversationId,
    chatId,
    caseId,
    initialTitle,
    createConversation,
    fetchConversationHistory,
  ]);

  const chatHistory = getChatHistory(currentConversationId || "");

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  useEffect(() => {
    if (inputRef.current && !isWaitingForResponse) {
      inputRef.current.focus();
    }
  }, [isWaitingForResponse]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "" && !isWaitingForResponse) {
      setIsWaitingForResponse(true);
      try {
        await sendMessage(inputMessage);
        setInputMessage("");
        // Reset textarea height after sending
        if (inputRef.current) {
          inputRef.current.style.height = "auto";
        }
      } finally {
        setIsWaitingForResponse(false);
      }
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Auto-resize textarea as content grows
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    setInputMessage(textarea.value);

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = "auto";
    // Set new height based on scrollHeight, with a max height
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInputMessage(suggestion);
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Card className={cn("flex flex-col h-full border-0", className)}>
      {error && (
        <Alert variant="destructive" className="mx-4 mt-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <ScrollArea
        className={cn(
          "flex-grow px-4 py-6",
          showSuggestions && "h-[calc(100%-450px)]"
        )}
      >
        <div className="space-y-6">
          {chatHistory.length === 0 && !isWaitingForResponse && (
            <div className="flex items-center justify-center h-32 text-muted-foreground">
              <div className="text-center">
                <p className="text-sm">No messages yet</p>
                <p className="text-xs mt-1">Start a conversation!</p>
              </div>
            </div>
          )}

          {chatHistory.map((message, i) => (
            <MessageBubble
              key={`${message.id}-${i}`}
              message={message}
              isUser={message.role === "user"}
            />
          ))}

          {isWaitingForResponse && <LoaderBubble />}
          <div ref={messagesEndRef} />
        </div>
      </ScrollArea>

      <div className="relative">
        <div
          className={cn(
            "absolute bottom-full left-0 right-0 transition-all duration-300 ease-in-out",
            showSuggestions
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-4 pointer-events-none"
          )}
        >
          <ChatSuggestions onSuggestionClick={handleSuggestionClick} />
        </div>

        <div className="p-4 border-t bg-card/50 backdrop-blur supports-[backdrop-filter]:bg-card/50">
          <Button
            variant="ghost"
            size="sm"
            className={cn(
              "w-full h-7 mb-2",
              "border border-dashed border-muted-foreground/25",
              "hover:bg-muted/50 hover:border-muted-foreground/40",
              "transition-all duration-200"
            )}
            onClick={() => setShowSuggestions(!showSuggestions)}
          >
            <div className="flex items-center gap-2 text-xs text-muted-foreground/80">
              {showSuggestions ? (
                <>
                  <ChevronDown className="h-3.5 w-3.5" />
                  <span>Hide message suggestions</span>
                </>
              ) : (
                <>
                  <ChevronUp className="h-3.5 w-3.5" />
                  <span>Show message suggestions</span>
                </>
              )}
            </div>
          </Button>

          <div className="flex space-x-2">
            <Textarea
              ref={inputRef}
              value={inputMessage}
              onChange={handleInput}
              onKeyDown={handleKeyPress}
              placeholder="Type your message... (Shift+Enter for new line)"
              disabled={isWaitingForResponse}
              className="flex-grow min-h-[40px] max-h-[200px] resize-none"
              rows={1}
            />
            <Button
              onClick={handleSendMessage}
              disabled={inputMessage.trim() === "" || isWaitingForResponse}
              size="icon"
              className="self-end h-10"
            >
              {isWaitingForResponse ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Send className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ChatComponent;
