import { apiCall } from "@/lib/utils";
import { CaseData, FavoriteItem, RecentlyViewedItem } from "@/types/shared";
import { create } from "zustand";

interface CaseState {
  cases: Record<string, CaseData>;
  fetchCaseById: (id: string) => Promise<void>;
  getCaseById: (id: string) => CaseData | undefined;
  getCaseTimeline: (id: string) => CaseData["timeline"] | undefined;
  getCaseHighlights: (id: string) => CaseData["highlights"] | undefined;
  getCaseTasks: (id: string) => CaseData["tasks"] | undefined;
  getChatHistory: (id: string) => CaseData["chatHistory"] | undefined;
  toggleTaskCompletion: (caseId: string, taskId: string) => void;
  addTask: (caseId: string, description: string) => void;
  addHighlight: (
    caseId: string,
    highlight: CaseData["highlights"][number]
  ) => void;
  addToFavorites: (caseId: string, item: FavoriteItem) => void;
  removeFromFavorites: (caseId: string, itemId: string) => void;
  addToRecentlyViewed: (
    caseId: string,
    item: Omit<RecentlyViewedItem, "viewedAt">
  ) => void;
  updateCaseAgenda: (caseId: string, agenda: CaseData["agenda"]) => void;
  addAgendaTask: (
    caseId: string,
    task: CaseData["agenda"]["tasks"][number]
  ) => void;
  updateAgendaTask: (
    caseId: string,
    taskId: string,
    updates: Partial<CaseData["agenda"]["tasks"][number]>
  ) => void;
  addAgendaDocument: (
    caseId: string,
    document: CaseData["agenda"]["documents"][number]
  ) => void;
  updateAgendaDocument: (
    caseId: string,
    documentId: string,
    updates: Partial<CaseData["agenda"]["documents"][number]>
  ) => void;
}

export const useStore = create<CaseState>((set, get) => ({
  cases: {},
  fetchCaseById: async (id: string) => {
    try {
      const URL = `/case-data?id=${encodeURIComponent(id)}&type=case_data`;
      const response = await apiCall(URL);
      if (!response.ok) {
        throw new Error("Failed to fetch case data");
      }
      const caseData: CaseData = await response.json();
      set((state) => ({
        cases: {
          ...state.cases,
          [id]: caseData,
        },
      }));
    } catch (error) {
      console.error("Error fetching case data:", error);
    }
  },
  getCaseById: (id) => get().cases[id],
  getCaseTimeline: (id) => get().cases[id]?.timeline,
  getCaseHighlights: (id) => get().cases[id]?.highlights,
  getCaseTasks: (id) => get().cases[id]?.tasks,
  getChatHistory: (id) => get().cases[id]?.chatHistory,

  toggleTaskCompletion: (caseId, taskId) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          tasks: state.cases[caseId].tasks.map((task) =>
            task.id === taskId ? { ...task, completed: !task.completed } : task
          ),
        },
      },
    })),

  addTask: (caseId, description) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          tasks: [
            ...state.cases[caseId].tasks,
            { id: Date.now().toString(), description, completed: false },
          ],
        },
      },
    })),

  addHighlight: (caseId, highlight) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          highlights: [...state.cases[caseId].highlights, highlight],
        },
      },
    })),

  addToFavorites: (caseId, item) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          favorites: [...state.cases[caseId].favorites, item],
        },
      },
    })),

  removeFromFavorites: (caseId, itemId) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          favorites: state.cases[caseId].favorites.filter(
            (item) => item.id !== itemId
          ),
        },
      },
    })),

  addToRecentlyViewed: (caseId, item) =>
    set((state) => {
      const newItem = { ...item, viewedAt: new Date().toISOString() };
      const updatedRecentlyViewed = [
        newItem,
        ...state.cases[caseId].recentlyViewed.filter((i) => i.id !== item.id),
      ].slice(0, 10); // Keep only the 10 most recent items

      return {
        cases: {
          ...state.cases,
          [caseId]: {
            ...state.cases[caseId],
            recentlyViewed: updatedRecentlyViewed,
          },
        },
      };
    }),

  updateCaseAgenda: (caseId, agenda) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          agenda: {
            ...state.cases[caseId].agenda,
            ...agenda,
          },
        },
      },
    })),

  addAgendaTask: (caseId, task) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          agenda: {
            ...state.cases[caseId].agenda,
            tasks: [...state.cases[caseId].agenda.tasks, task],
          },
        },
      },
    })),

  updateAgendaTask: (caseId, taskId, updates) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          agenda: {
            ...state.cases[caseId].agenda,
            tasks: state.cases[caseId].agenda.tasks.map((task) =>
              task.id === taskId ? { ...task, ...updates } : task
            ),
          },
        },
      },
    })),

  addAgendaDocument: (caseId, document) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          agenda: {
            ...state.cases[caseId].agenda,
            documents: [...state.cases[caseId].agenda.documents, document],
          },
        },
      },
    })),

  updateAgendaDocument: (caseId, documentId, updates) =>
    set((state) => ({
      cases: {
        ...state.cases,
        [caseId]: {
          ...state.cases[caseId],
          agenda: {
            ...state.cases[caseId].agenda,
            documents: state.cases[caseId].agenda.documents.map((doc) =>
              doc.id === documentId ? { ...doc, ...updates } : doc
            ),
          },
        },
      },
    })),
}));
