import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useStore } from "@/store/caseStore";
import { useChatStore } from "@/features/chat/chatStore";
import { Plus } from "lucide-react";

interface FavoritesSectionProps {
  caseId: string;
}

export const FavoritesSection: React.FC<FavoritesSectionProps> = ({
  caseId,
}) => {
  const { cases, removeFromFavorites } = useStore();
  const { openChat, sendMessage, clearChatHistory } = useChatStore();
  const favorites = cases[caseId]?.favorites || [];

  const handleAddFavorite = () => {
    clearChatHistory(caseId);
    openChat();
    sendMessage("What would you like to add to favorites?");
  };

  return (
    <Card className="mb-6 hover:shadow-lg transition-shadow">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Favorites</CardTitle>
        <Button
          variant="outline"
          size="sm"
          onClick={handleAddFavorite}
          className="flex items-center"
        >
          <Plus className="w-4 h-4 mr-1" /> Add
        </Button>
      </CardHeader>
      <CardContent>
        {favorites.length === 0 ? (
          <p>No favorites added yet.</p>
        ) : (
          <ul className="-my-1">
            {favorites.map((item) => (
              <li
                key={item.id}
                className="group flex justify-between items-center py-1 px-2 hover:bg-gray-100 rounded-md transition-colors duration-200"
              >
                <Link
                  to={`/cases/${caseId}/wiki/${item.id}`}
                  className="text-accent hover:underline flex-grow truncate mr-2"
                >
                  {item.title}
                </Link>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeFromFavorites(caseId, item.id)}
                  className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 min-w-[70px]"
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        )}
      </CardContent>
    </Card>
  );
};
