import { create } from "zustand";

interface CaseUiStore {
  secondaryHeader: string;
  setSecondaryHeader: (secondaryHeader: string) => void;
  reset: () => void;
}

export const useCaseUiStore = create<CaseUiStore>((set) => ({
  secondaryHeader: "",

  setSecondaryHeader: (secondaryHeader: string) => set({ secondaryHeader }),
  reset: () => set({ secondaryHeader: "" }),
}));
