import React from "react";
import { Search, Filter } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

const CasesListSkeleton: React.FC = () => {
  return (
    <div className="space-y-6" data-testid="cases-list-skeleton">
      {/* Search and filter section */}
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <Search className="text-gray-400" />
          <Input
            type="text"
            placeholder="Search cases..."
            disabled
            className="flex-grow"
          />
        </div>
        <div className="flex items-center space-x-4">
          <Filter className="text-gray-400" />
          <Skeleton className="h-10 w-[180px]" />
          <Skeleton className="h-10 w-[180px]" />
          <Button variant="outline" disabled>
            Clear Filters
          </Button>
        </div>
      </div>

      {/* Cases grid */}
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {/* Add Case Card Skeleton */}
        <Skeleton className="h-[200px] w-full rounded-lg" />

        {/* Case Card Skeletons */}
        {[...Array(5)].map((_, index) => (
          <div key={index} className="space-y-3">
            <Skeleton className="h-[200px] w-full rounded-lg" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CasesListSkeleton;
