import { useCaseUiStore } from "@/features/case/caseUiStore";
import { useBreadcrumbStore } from "@/store/breadcrumbStore";
import { useSpecificDocumentStore } from "@/store/specificDocumentStore";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PDFViewerV2 from "../PDFViewer_V2/PDFViewerV2";
import SearchSegmentCard from "../PDFViewer_V2/SearchSegmentCard";
import DocumentMainContent from "./DocumentMainContent";
import DocumentSidebar from "./DocumentSidebar";
import { useStore } from "@/store/caseStore";

const AnimatedContainer = ({ children }: { children: React.ReactNode }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{
      type: "spring",
      stiffness: 100,
      damping: 15,
      mass: 1,
    }}
  >
    {children}
  </motion.div>
);

export const DocumentDetails = () => {
  const { caseId, documentId } = useParams<{
    caseId: string;
    documentId: string;
  }>();

  const {
    caseDocument,
    error,
    previewBlob,
    fetchDocumentDetails,
    clearDocumentDetails,
  } = useSpecificDocumentStore();

  const setBreadcrumbs = useBreadcrumbStore((state) => state.setBreadcrumbs);
  const [showPdfViewerV2, setShowPdfViewerV2] = useState(false);
  const [isSearchCardVisible, setIsSearchCardVisible] = useState(false);
  const { setSecondaryHeader } = useCaseUiStore();
  const { getCaseById } = useStore();
  const caseData = getCaseById(caseId as string);

  useEffect(() => {
    setSecondaryHeader(`Document: ${caseDocument?.title || ""}`);
  }, [caseDocument, setSecondaryHeader]);

  useEffect(() => {
    if (caseId && documentId) {
      fetchDocumentDetails(caseId, documentId);
    }
    return () => {
      clearDocumentDetails();
      setIsSearchCardVisible(false);
    };
  }, [caseId, documentId, fetchDocumentDetails, clearDocumentDetails]);

  // Effect to handle the search card visibility with a slight delay
  useEffect(() => {
    let timer: number;
    if (caseDocument && previewBlob) {
      timer = window.setTimeout(() => {
        setIsSearchCardVisible(true);
      }, 300);
    } else {
      setIsSearchCardVisible(false);
    }
    return () => clearTimeout(timer);
  }, [caseDocument, previewBlob]);

  useEffect(() => {
    if (caseDocument) {
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Cases", path: "/cases" },
        {
          label: caseData?.name || decodeURIComponent(caseId!),
          path: `/cases/${caseId}`,
        },
        { label: "Documents", path: `/cases/${caseId}/documents` },
        {
          label: decodeURIComponent(caseDocument.title),
          path: `/cases/${caseId}/documents/${documentId}`,
        },
      ]);
    }
  }, [caseId, documentId, caseDocument, setBreadcrumbs, caseData?.name]);

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <div className="bg-destructive/10 text-destructive p-4 rounded-md">
          Error: {error}
        </div>
      </div>
    );
  }

  if (!caseDocument) {
    return (
      <div className="container mx-auto p-4">
        <div className="bg-destructive/10 text-destructive p-4 rounded-md">
          Document not found
        </div>
      </div>
    );
  }

  const canShowPDF = caseDocument && !!previewBlob;

  return (
    <div className="container mx-auto h-full flex flex-col">
      <div className="flex flex-col lg:flex-row gap-6 flex-grow overflow-hidden">
        <div className="flex-grow">
          <AnimatePresence mode="wait">
            {isSearchCardVisible && canShowPDF && (
              <AnimatedContainer>
                <SearchSegmentCard
                  onOpenPDFViewer={() => setShowPdfViewerV2(true)}
                />
              </AnimatedContainer>
            )}
          </AnimatePresence>

          <DocumentMainContent
            caseDocument={caseDocument}
            caseId={caseId!}
            documentId={documentId!}
            previewBlob={previewBlob}
            onViewPDFV2={() => canShowPDF && setShowPdfViewerV2(true)}
            canShowPDF={canShowPDF}
          />
        </div>
        <DocumentSidebar caseId={caseId!} />
      </div>

      {showPdfViewerV2 && canShowPDF && (
        <PDFViewerV2
          onClose={() => {
            setShowPdfViewerV2(false);
          }}
        />
      )}
    </div>
  );
};

export default DocumentDetails;
