import React from "react";
import { Filter } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface FilterBarProps {
  typeFilter: string | null;
  setTypeFilter: (type: string | null) => void;
  statusFilter: string | null;
  setStatusFilter: (status: string | null) => void;
  caseTypes: string[];
  caseStatuses: string[];
  clearFilters: () => void;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  typeFilter,
  setTypeFilter,
  statusFilter,
  setStatusFilter,
  caseTypes,
  caseStatuses,
  clearFilters,
}) => {
  return (
    <div className="flex items-center space-x-4">
      <Filter className="text-gray-400" />
      <Select
        onValueChange={(value) => setTypeFilter(value || null)}
        value={typeFilter || undefined}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Filter by type" />
        </SelectTrigger>
        <SelectContent>
          {caseTypes.map((type) => (
            <SelectItem key={type} value={type}>
              {type}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select
        onValueChange={(value) => setStatusFilter(value || null)}
        value={statusFilter || undefined}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Filter by status" />
        </SelectTrigger>
        <SelectContent>
          {caseStatuses.map((status) => (
            <SelectItem key={status} value={status}>
              {status}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button variant="outline" onClick={clearFilters}>
        Clear Filters
      </Button>
    </div>
  );
};
