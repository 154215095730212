import { Block, DocumentData } from "./types";

interface Position {
  x: number;
  y: number;
}

function getBlockPosition(block: Block): Position {
  const vertices = block.layout.boundingPoly.vertices;
  // Use the top-left vertex as the position reference
  return {
    x: vertices[0]?.x || 0,
    y: vertices[0]?.y || 0,
  };
}

function sortBlocksByReadingOrder(blocks: Block[]): Block[] {
  // Define reading order threshold (adjust based on your needs)
  const LINE_HEIGHT_THRESHOLD = 10; // pixels

  return [...blocks].sort((a, b) => {
    const posA = getBlockPosition(a);
    const posB = getBlockPosition(b);

    // If blocks are roughly on the same line
    if (Math.abs(posA.y - posB.y) <= LINE_HEIGHT_THRESHOLD) {
      // Sort left to right
      return posA.x - posB.x;
    }
    // Sort top to bottom
    return posA.y - posB.y;
  });
}

function processDocumentData(documentData: DocumentData): DocumentData {
  return {
    ...documentData,
    pages: documentData.pages.map((page) => ({
      ...page,
      blocks: sortBlocksByReadingOrder(page.blocks),
    })),
  };
}

export { processDocumentData, sortBlocksByReadingOrder };
