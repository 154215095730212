import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  AlertTriangle,
  Bot,
  FileSpreadsheet,
  History,
  Search,
} from "lucide-react";

const ChatSuggestions = ({
  onSuggestionClick,
}: {
  onSuggestionClick: (suggestion: string) => void;
}) => {
  const suggestionCategories = [
    {
      icon: <Search className="w-4 h-4" />,
      label: "Evidence Analysis",
      suggestions: [
        "Find all evidence related to [key fact]",
        "Analyze the chronological sequence of evidence",
        "Identify conflicting statements in testimonies of [witness name]",
        "Extract key facts from [document name]",
      ],
    },
    {
      icon: <History className="w-4 h-4" />,
      label: "Timeline & Patterns",
      suggestions: [
        "Create a detailed timeline of all documented events",
        "Identify patterns in communication records",
        "Map relationships between key individuals",
        "Track changes in witness statements over time",
      ],
    },
    {
      icon: <AlertTriangle className="w-4 h-4" />,
      label: "Risk Assessment",
      suggestions: [
        "Identify potential factual inconsistencies",
        "Find gaps in the evidence chain",
        "Analyze document authenticity concerns",
        "Review metadata consistency across files",
      ],
    },
    {
      icon: <FileSpreadsheet className="w-4 h-4" />,
      label: "Data Analysis",
      suggestions: [
        "Analyze communication frequency patterns",
        "Generate statistical summary of [data type]",
        "Compare document versions",
        "Extract key metrics from financial records",
      ],
    },
  ];

  return (
    <Card className="p-4 bg-background/50 backdrop-blur">
      <ScrollArea className="h-[300px]">
        <div className="space-y-4">
          {suggestionCategories.map((category, idx) => (
            <div key={idx} className="space-y-2">
              <div className="flex items-center gap-2 text-primary font-medium">
                {category.icon}
                <span>{category.label}</span>
              </div>
              <div className="flex flex-wrap gap-2">
                {category.suggestions.map((suggestion, index) => (
                  <Badge
                    key={index}
                    variant="secondary"
                    className="cursor-pointer hover:bg-accent hover:text-accent-foreground transition-colors"
                    onClick={() => onSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </Badge>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ScrollArea>

      <div className="mt-4 pt-3 border-t border-border">
        <div className="flex items-center gap-2 text-xs text-muted-foreground">
          <Bot className="w-3 h-3" />
          <span>
            AI-powered suggestions based on case context and evidence patterns
          </span>
        </div>
      </div>
    </Card>
  );
};

export default ChatSuggestions;
