import { Case, CasesApiResponse } from "@/features/case/types";
import { apiCall } from "@/lib/utils";
import { create } from "zustand";

interface CaseAddPayload {
  case: {
    name: string;
    tenant_name: string;
  };
  new_params: {
    details: {
      year?: string;
      status?: string;
      type?: string;
      description?: string;
      summary?: string;
    };
  };
}

interface CasesMetadataStore {
  cases: Case[];
  isLoading: boolean;
  error: string | null;
  fetchCases: () => Promise<void>;
  createCase: (caseData: CaseAddPayload) => Promise<void>;
}

export const useCasesMetadataStore = create<CasesMetadataStore>((set) => ({
  cases: [],
  isLoading: false,
  error: null,
  fetchCases: async () => {
    set({ isLoading: true, error: null });
    try {
      const response = await apiCall("/case/all");
      if (!response.ok) {
        throw new Error("Failed to fetch cases");
      }
      const data: CasesApiResponse = await response.json();
      set({ cases: data.cases, isLoading: false });
    } catch (error) {
      set({ error: (error as Error).message, isLoading: false });
    }
  },
  createCase: async (caseData: CaseAddPayload) => {
    set({ isLoading: true, error: null });
    try {
      const payload = {
        ...caseData,
        new_params: {
          ...caseData.new_params,
          details: {
            ...caseData.new_params.details,
            description: caseData.case.name, // Set description to the same value as name
          },
        },
      };

      const response = await apiCall("/case", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to create case");
      }

      // Refresh the cases list after creating a new case
      await useCasesMetadataStore.getState().fetchCases();
    } catch (error) {
      set({ error: (error as Error).message, isLoading: false });
    } finally {
      set({ isLoading: false });
    }
  },
}));
