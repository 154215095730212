import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { cn } from "@/lib/utils";
import { Download, FileText, Loader2 } from "lucide-react";

const LoadingSpinner = () => (
  <div className="flex items-center justify-center w-full h-full">
    <Loader2 className="h-8 w-8 animate-spin text-accent" />
  </div>
);

const LoadingProgress = ({ value = 0 }) => (
  <div className="w-full space-y-2">
    <Progress value={value} className="w-full h-2" />
    <p className="text-sm text-charcoal text-center">{value}% complete</p>
  </div>
);

interface DocumentLoadingButtonProps {
  isLoading: boolean;
  onClick: () => void;
  loadingText?: string;
  children: React.ReactNode;
  variant?: "default" | "outline";
  className?: string;
  disabled?: boolean;
}

const DocumentLoadingButton = ({
  isLoading,
  onClick,
  loadingText = "Loading...",
  children,
  variant = "default",
  className = "",
}: DocumentLoadingButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      variant={variant}
      className={cn(
        "relative min-w-[160px] transition-all duration-200",
        isLoading && "pl-8",
        className
      )}
    >
      {isLoading && (
        <Loader2 className="absolute left-3 h-4 w-4 animate-spin" />
      )}
      {isLoading ? loadingText : children}
    </Button>
  );
};

const DocumentActionButtons = ({
  onView,
  onDownload,
  isViewLoading,
  isDownloading,
  canShowPDF,
}: {
  onView: () => void;
  onDownload: () => void;
  isViewLoading: boolean;
  isDownloading: boolean;
  canShowPDF: boolean;
}) => (
  <div className="flex gap-4">
    <DocumentLoadingButton
      onClick={onView}
      isLoading={isViewLoading}
      loadingText="Loading Document..."
      disabled={!canShowPDF}
    >
      <FileText className="mr-2 h-4 w-4" />
      View Full Document
    </DocumentLoadingButton>

    <DocumentLoadingButton
      onClick={onDownload}
      isLoading={isDownloading}
      loadingText="Downloading..."
      variant="outline"
      disabled={isDownloading || !canShowPDF}
    >
      <Download className="mr-2 h-4 w-4" />
      Download PDF
    </DocumentLoadingButton>
  </div>
);

export {
  DocumentActionButtons,
  DocumentLoadingButton,
  LoadingProgress,
  LoadingSpinner,
};
