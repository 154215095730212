import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/hooks/use-toast";
import { useSettingsStore } from "@/store/userSettingsStore";
import { User } from "@/types/shared";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { Eye, EyeOff } from "lucide-react";

const UserSettings: React.FC = () => {
  const { userSettings, fetchUserSettings, updateUserSettings } =
    useSettingsStore();
  const [profile, setProfile] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    role: "tenant_user",
    tenantName: "",
    avatarUrl: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);

  useEffect(() => {
    if (userSettings) {
      setProfile(userSettings);
    }
  }, [userSettings]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validatePasswords(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    validatePasswords(password, e.target.value);
  };

  const validatePasswords = (pass: string, confirm: string) => {
    if (pass !== confirm) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password && passwordError) {
      toast({
        title: "Error",
        description: "Please fix the password errors before submitting.",
        variant: "destructive",
      });
      return;
    }
    try {
      await updateUserSettings({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        ...(password && { password }),
      });
      setIsEditing(false);
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update your profile. Please try again.",
        variant: "destructive",
      });
      console.error(error);
    }
  };

  if (!userSettings) {
    return <div>Loading user settings...</div>;
  }

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-primary flex justify-between items-center">
          User Profile
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsEditing(!isEditing)}
            className="text-accent"
          >
            <FiEdit2 className="mr-2" />
            {isEditing ? "Cancel" : "Edit"}
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="firstName">First Name</Label>
            <Input
              id="firstName"
              name="firstName"
              value={profile.firstName}
              onChange={handleInputChange}
              required
              disabled={!isEditing}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              id="lastName"
              name="lastName"
              value={profile.lastName}
              onChange={handleInputChange}
              required
              disabled={!isEditing}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={profile.email}
              onChange={handleInputChange}
              required
              disabled={!isEditing}
            />
          </div>
          {isEditing && (
            <div className="space-y-2">
              <Label htmlFor="password">New Password</Label>
              <div className="relative">
                <Input
                  id="password"
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  disabled={!isEditing}
                />
                <button
                  type="button"
                  className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const input = document.getElementById(
                      "password"
                    ) as HTMLInputElement;
                    const cursorPosition = input?.selectionStart || 0;
                    setIsPasswordVisible(true);
                    // Restore cursor position after state update
                    setTimeout(() => {
                      input?.setSelectionRange(cursorPosition, cursorPosition);
                    }, 0);
                  }}
                  onMouseUp={() => {
                    const input = document.getElementById(
                      "password"
                    ) as HTMLInputElement;
                    const cursorPosition = input?.selectionStart || 0;
                    setIsPasswordVisible(false);
                    // Restore cursor position after state update
                    setTimeout(() => {
                      input?.setSelectionRange(cursorPosition, cursorPosition);
                    }, 0);
                  }}
                  onMouseLeave={() => {
                    const input = document.getElementById(
                      "password"
                    ) as HTMLInputElement;
                    const cursorPosition = input?.selectionStart || 0;
                    setIsPasswordVisible(false);
                    // Restore cursor position after state update
                    setTimeout(() => {
                      input?.setSelectionRange(cursorPosition, cursorPosition);
                    }, 0);
                  }}
                >
                  {isPasswordVisible ? (
                    <Eye className="h-4 w-4" />
                  ) : (
                    <EyeOff className="h-4 w-4" />
                  )}
                </button>
              </div>
            </div>
          )}
          <AnimatePresence>
            {isEditing && password && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="space-y-2"
              >
                <Label htmlFor="confirmPassword">Confirm New Password</Label>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={isPasswordVisible ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  disabled={!isEditing}
                />
                {passwordError && (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-red-500 text-sm"
                  >
                    {passwordError}
                  </motion.p>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <div className="space-y-2">
            <Label>Role</Label>
            <Select disabled value={profile.role}>
              <SelectTrigger>
                <SelectValue>{profile.role}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={profile.role}>{profile.role}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label>Tenant</Label>
            <Input value={profile.tenantName} disabled />
          </div>
          {isEditing && (
            <Button
              type="submit"
              className="w-full bg-accent hover:bg-accent/90 text-white"
              disabled={!!passwordError}
            >
              Update Profile
            </Button>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default UserSettings;
