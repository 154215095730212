import { apiCall } from "@/lib/utils";
import type { ServerMessage, ServerConversation } from "./storeUtils";

export interface ApiResponse<T> {
  data: T;
  status: "success" | "error";
  message?: string;
}

export interface CreateChatResponse {
  chat_id: string;
  status: "success";
}

export const chatApi = {
  async createChat(caseId: string, title: string): Promise<CreateChatResponse> {
    const response = await apiCall("/ai/chat", {
      method: "POST",
      body: JSON.stringify({ case_id: caseId, title }),
      headers: { "Content-Type": "application/json" },
    });
    return response.json();
  },

  async sendMessage(
    chatId: string,
    content: string,
    caseId: string
  ): Promise<ServerMessage> {
    const response = await apiCall(`/ai/message/${chatId}`, {
      method: "POST",
      body: JSON.stringify({ role: "user", content, caseId }),
      headers: { "Content-Type": "application/json" },
    });
    return response.json();
  },

  async getConversations(caseId?: string): Promise<ServerConversation[]> {
    const url = `/ai/case/chats?case_id=${caseId}`;
    const response = await apiCall(url);
    return response.json();
  },

  async getConversationHistory(
    conversationId: string
  ): Promise<ServerMessage[]> {
    const response = await apiCall(`/ai/conversation/${conversationId}`);
    return response.json();
  },

  async deleteConversation(conversationId: string): Promise<ApiResponse<null>> {
    const response = await apiCall(`/ai/conversation/${conversationId}`, {
      method: "DELETE",
    });
    return response.json();
  },

  async updateConversationTitle(
    conversationId: string,
    title: string
  ): Promise<ApiResponse<null>> {
    const response = await apiCall(`/ai/conversation/${conversationId}/title`, {
      method: "PATCH",
      body: JSON.stringify({ title }),
      headers: { "Content-Type": "application/json" },
    });
    return response.json();
  },
};
