import Nav from "@/components/Navigation/Nav";
import { BrowserRouter as Router } from "react-router-dom";

import AuthWrapper from "@/components/AuthWrapper";
import AppRoutes from "./components/AppRoutes";
import Footer from "@/components/Footer";
import { Toaster } from "@/components/ui/toaster";

function App() {
  return (
    <Router>
      <AuthWrapper>
        <div className="flex flex-col min-h-screen bg-light-gray">
          <Nav />
          <main className="flex flex-grow">
            <AppRoutes />
          </main>
          <Footer />
          <Toaster />
        </div>
      </AuthWrapper>
    </Router>
  );
}

export default App;
