import React, { useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import useFeedStore from "@/store/feedStore";
import { formatDistanceToNow } from "date-fns";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaTiktok,
} from "react-icons/fa";

const SocialMediaFeed: React.FC = () => {
  const { socialMediaItems, fetchItems, markItemAsSeen } = useFeedStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadSocialMediaItems = async () => {
      setIsLoading(true);
      setError(null);
      try {
        await fetchItems("social");
      } catch (err) {
        setError(
          `Failed to load new files. Please try again. Message: ${
            (err as Error).message
          }`
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadSocialMediaItems();
  }, [fetchItems]);

  const handleClick = (id: string) => {
    markItemAsSeen("social", id);
  };

  const getPlatformIcon = (platform: string) => {
    switch (platform.toLowerCase()) {
      case "twitter":
        return <FaTwitter size={16} />;
      case "facebook":
        return <FaFacebook size={16} />;
      case "instagram":
        return <FaInstagram size={16} />;
      case "linkedin":
        return <FaLinkedin size={16} />;
      case "tiktok":
        return <FaTiktok size={16} />;
      default:
        return null;
    }
  };

  if (isLoading) return <p>Loading social media updates...</p>;
  if (error) return <p className="text-destructive">{error}</p>;

  return (
    <ul className="space-y-4">
      {socialMediaItems.map((item) => (
        <li key={item.id} className="border-b pb-2">
          <div className="flex justify-between items-start">
            <div className="flex items-center">
              {getPlatformIcon(item.platform)}
              <span className="ml-2 text-sm text-charcoal">{item.author}</span>
            </div>
            <div className="flex items-center">
              {!item.seen && (
                <Badge
                  variant="outline"
                  className="mr-2 text-accent border-accent"
                >
                  New
                </Badge>
              )}
            </div>
          </div>
          <p
            className="mt-1 text-accent cursor-pointer hover:underline transition-colors"
            onClick={() => handleClick(item.id)}
          >
            {item.content}
          </p>
          <p className="text-xs text-muted-foreground mt-1">
            {formatDistanceToNow(new Date(item.date), { addSuffix: true })}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default SocialMediaFeed;
