import React from "react";
import { Button } from "@/components/ui/button";

interface LoginButtonProps {
  onClick: () => void;
}

const LoginButton: React.FC<LoginButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="ghost"
      onClick={onClick}
      className="text-white hover:text-primary hover:bg-white"
      type="button"
    >
      Log in
    </Button>
  );
};

export default LoginButton;
