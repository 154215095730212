import { toast } from "@/hooks/use-toast";
import { apiCall, renameUsersFromServerResponse } from "@/lib/utils";
import {
  CreateUserData,
  Tenant,
  User,
  UserFromServerResponse,
  UserSettingsErrors,
} from "@/types/shared";
import { create } from "zustand";
import { useAuthStore } from "./authStore";

export interface Case {
  id: string;
  name: string;
}

export interface CasePermission {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    id: string;
  };
  case: {
    id: string;
    name: string;
  };
  permission: "read" | "write";
}

export type CreateUser = (userData: CreateUserData) => Promise<void>;

export interface SettingsStore {
  userSettings: User | null;
  tenants: Tenant[];
  cases: Case[];
  users: Partial<User>[];
  casePermissions: CasePermission[];
  isLoading: boolean;
  errors: UserSettingsErrors;
  fetchUserSettings: () => Promise<void>;
  updateUserSettings: (
    settings: Partial<User> & { password?: string }
  ) => Promise<void>;
  fetchTenants: () => Promise<void>;
  createTenant: (payload: {
    tenant: { name: string };
    new_params: {
      admin_user: {
        email: string;
        first_name: string;
        last_name: string;
        password: string;
      };
    };
  }) => Promise<void>;
  updateTenant: (oldName: string, newName: string) => Promise<void>;
  deleteTenant: (name: string) => Promise<void>;
  createUser: CreateUser;
  setErrors: (errors: Partial<UserSettingsErrors>) => void;
  clearErrors: () => void;
  fetchCases: () => Promise<void>;
  fetchUsers: () => Promise<void>;
  fetchCasePermissions: (caseId: string) => Promise<void>;
  addCasePermission: (payload: {
    permission: {
      email: string;
      case_name: string;
      tenant_name: string;
    };
    new_params: {
      permission_type: "read" | "write";
    };
  }) => Promise<void>;
  updateCasePermission: (payload: {
    permission: {
      email: string;
      case_name: string;
      tenant_name: string;
    };
    new_params: {
      permission_type: "read" | "write";
    };
  }) => Promise<void>;
  removeCasePermission: (caseId: string, userEmail: string) => Promise<void>;
  updateUser: (
    userEmail: string,
    userData: Pick<User, "role">
  ) => Promise<void>;
  removeUser: (userEmail: string) => Promise<void>;
}

export const useSettingsStore = create<SettingsStore>((set, get) => ({
  userSettings: null,
  tenants: [],
  cases: [],
  users: [],
  casePermissions: [],
  isLoading: false,
  errors: {
    firstName: "",
    lastName: "",
    email: "",
    general: "",
  },

  fetchUserSettings: async () => {
    set({
      isLoading: true,
      errors: { firstName: "", lastName: "", email: "", general: "" },
    });
    try {
      const userData = useAuthStore.getState().user; // Get user from authStore
      if (userData) {
        set({ userSettings: userData, isLoading: false });
      } else {
        throw new Error("User data not found in authStore");
      }
    } catch (error) {
      console.error("Failed to fetch user settings:", error);
      set((state) => ({
        isLoading: false,
        errors: {
          ...state.errors,
          general: "Failed to fetch user settings. Please try again.",
        },
      }));
      toast({
        title: "Error",
        description: "Failed to fetch user settings. Please try again.",
        variant: "destructive",
      });
    }
  },

  updateUserSettings: async (
    settings: Partial<User> & { password?: string }
  ) => {
    set({
      isLoading: true,
      errors: { firstName: "", lastName: "", email: "", general: "" },
    });

    try {
      const payload = {
        user: {
          email: get().userSettings?.email,
        },
        new_params: {
          email: settings.email,
          first_name: settings.firstName,
          last_name: settings.lastName,
          ...(settings.password && { password: settings.password }),
        },
      };

      const response = await apiCall("/user", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const updatedUser = await response.json();
        set({ userSettings: updatedUser, isLoading: false });
        useAuthStore.setState({ user: updatedUser });
        toast({
          title: "Success",
          description: "User settings updated successfully.",
        });
      } else {
        return Promise.reject("Failed to update user settings");
      }
    } catch (error) {
      console.error("Failed to update user settings:", error);
      set((state) => ({
        isLoading: false,
        errors: {
          ...state.errors,
          general:
            error instanceof Error
              ? error.message
              : "An unexpected error occurred. Please try again.",
        },
      }));
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "Failed to update user settings. Please try again.",
        variant: "destructive",
      });
    }
  },

  fetchTenants: async () => {
    set({ isLoading: true });
    try {
      const response = await apiCall("/tenant/all");
      if (response.ok) {
        const tenantsData = await response.json();
        set({ tenants: tenantsData, isLoading: false });
      } else {
        throw new Error("Failed to fetch tenants");
      }
    } catch (error) {
      console.error("Failed to fetch tenants:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to fetch tenants. Please try again.",
        variant: "destructive",
      });
    }
  },

  createTenant: async (payload: {
    tenant: { name: string };
    new_params: {
      admin_user: {
        email: string;
        first_name: string;
        last_name: string;
        password: string;
      };
    };
  }) => {
    set({ isLoading: true });
    try {
      const response = await apiCall("/tenant", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        await get().fetchTenants();
        set({ isLoading: false });
        return;
      } else {
        set({ isLoading: false });
        toast({
          title: "Error",
          description: "Failed to create tenant. Please try again.",
          variant: "destructive",
        });
        return Promise.reject("Failed to create tenant");
      }
    } catch (error) {
      console.error("Failed to create tenant:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to create tenant. Please try again.",
        variant: "destructive",
      });
    }
  },

  updateTenant: async (oldName: string, newName: string) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/tenant`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenant: { name: oldName },
          new_params: { name: newName },
        }),
      });
      if (response.ok) {
        await get().fetchTenants();
        toast({
          title: "Success",
          description: "Tenant updated successfully.",
        });
      } else {
        throw new Error("Failed to update tenant");
      }
    } catch (error) {
      console.error("Failed to update tenant:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to update tenant. Please try again.",
        variant: "destructive",
      });
    }
  },

  deleteTenant: async (name: string) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/tenant`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tenant: { name } }),
      });
      if (response.ok) {
        await get().fetchTenants();
        toast({
          title: "Success",
          description: "Tenant deleted successfully.",
        });
      } else {
        throw new Error("Failed to delete tenant");
      }
    } catch (error) {
      console.error("Failed to delete tenant:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to delete tenant. Please try again.",
        variant: "destructive",
      });
    }
  },

  createUser: async (userData) => {
    set({ isLoading: true });
    try {
      const response = await apiCall("/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the Content-Type header
        },
        body: JSON.stringify(userData),
      });
      if (response.ok) {
        toast({ title: "Success", description: "User created successfully." });
      } else {
        return Promise.reject("Failed to create user");
      }
    } catch (error) {
      console.error("Failed to create user:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: `${error}`,
        variant: "destructive",
      });
    }
  },

  setErrors: (newErrors: Partial<UserSettingsErrors>) => {
    set((state) => ({ errors: { ...state.errors, ...newErrors } }));
  },

  clearErrors: () => {
    set({ errors: { firstName: "", lastName: "", email: "", general: "" } });
  },

  fetchCases: async () => {
    set({ isLoading: true });
    try {
      const response = await apiCall("/cases");
      if (response.ok) {
        const casesData = await response.json();
        set({ cases: casesData, isLoading: false });
      } else {
        throw new Error("Failed to fetch cases");
      }
    } catch (error) {
      console.error("Failed to fetch cases:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to fetch cases. Please try again.",
        variant: "destructive",
      });
    }
  },

  fetchUsers: async () => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/user/all`);
      if (response.ok) {
        const users: UserFromServerResponse[] = await response.json();

        set({ users: renameUsersFromServerResponse(users), isLoading: false });
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to fetch users. Please try again.",
        variant: "destructive",
      });
    }
  },

  fetchCasePermissions: async (caseId: string) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(
        `/case/${encodeURIComponent(caseId)}/permissions`
      ); // Adjust the endpoint as necessary
      if (response.ok) {
        const permissionsData = await response.json();
        set({ casePermissions: permissionsData.permissions, isLoading: false });
      } else {
        throw new Error("Failed to fetch case permissions");
      }
    } catch (error) {
      console.error("Failed to fetch case permissions:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to fetch case permissions. Please try again.",
        variant: "destructive",
      });
    }
  },

  addCasePermission: async (payload: {
    permission: {
      email: string;
      case_name: string;
      tenant_name: string;
    };
    new_params: {
      permission_type: "read" | "write";
    };
  }) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/permissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        await get().fetchCasePermissions(payload.permission.case_name);
        toast({
          title: "Success",
          description: "Permission added successfully.",
        });
      } else {
        throw new Error("Failed to add permission");
      }
    } catch (error) {
      console.error("Failed to add permission:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to add permission. Please try again.",
        variant: "destructive",
      });
    }
  },

  updateCasePermission: async (payload: {
    permission: {
      email: string;
      case_name: string;
      tenant_name: string;
    };
    new_params: {
      permission_type: "read" | "write";
    };
  }) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/permissions`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        await get().fetchCasePermissions(payload.permission.case_name);
        toast({
          title: "Success",
          description: "Permission updated successfully.",
        });
      } else {
        return Promise.reject("Failed to update permission");
      }
    } catch (error) {
      console.error("Failed to update permission:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to update permission. Please try again.",
        variant: "destructive",
      });
    }
  },

  removeCasePermission: async (caseId: string, userEmail: string) => {
    set({ isLoading: true });
    try {
      const tenantName = useAuthStore.getState().user?.tenantName;
      if (!tenantName) {
        throw new Error("Tenant name not found");
      }

      const response = await apiCall(`/permissions`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          permission: {
            email: userEmail,
            case_name: caseId,
            tenant_name: tenantName,
          },
        }),
      });

      if (response.ok) {
        await get().fetchCasePermissions(caseId);
        toast({
          title: "Success",
          description: "Permission removed successfully.",
        });
      } else {
        return Promise.reject("Failed to remove permission");
      }
    } catch (error) {
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to remove permission. Please try again.",
        variant: "destructive",
      });
      return Promise.reject(`Failed to remove permission: ${error}`);
    }
  },

  updateUser: async (userEmail: string, userData: Partial<User>) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: {
            email: userEmail,
          },
          new_params: {
            role: userData.role,
          },
        }),
      });
      if (response.ok) {
        await get().fetchUsers();
        toast({
          title: "Success",
          description: "User updated successfully.",
        });
      } else {
        return Promise.reject("Failed to update user");
      }
    } catch (error) {
      console.error("Failed to update user:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to update user. Please try again.",
        variant: "destructive",
      });
    }
  },

  removeUser: async (userEmail: string) => {
    set({ isLoading: true });
    try {
      const response = await apiCall(`/user`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: {
            email: userEmail,
          },
        }),
      });
      if (response.ok) {
        await get().fetchUsers();
        toast({
          title: "Success",
          description: "User removed successfully.",
        });
      } else {
        throw new Error("Failed to remove user");
      }
    } catch (error) {
      console.error("Failed to remove user:", error);
      set({ isLoading: false });
      toast({
        title: "Error",
        description: "Failed to remove user. Please try again.",
        variant: "destructive",
      });
    }
  },
}));
