import { useAuthStore } from "@/store/authStore";
import { User, UserFromServerResponse } from "@/types/shared";
import { clsx, type ClassValue } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const apiCall = async (endpoint: string, options: RequestInit = {}) => {
  const API_URL = process.env.API_URL;
  const { refreshToken } = useAuthStore.getState();

  const makeRequest = async () => {
    // Development logging
    if (process.env.NODE_ENV === "development") {
      console.log(`[API] ${options.method || "GET"} ${endpoint}`);
    }

    const token = localStorage.getItem("access_token");
    const fetchURL = `${API_URL}${endpoint}`;
    const response = await fetch(fetchURL, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      await refreshToken();
      return makeRequest();
    }

    return response;
  };

  return makeRequest();
};

// Custom hook to handle API calls with Strict Mode safety
export function useApi<T>(endpoint: string, options?: RequestInit) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  const optionsString = JSON.stringify(options);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const response = await apiCall(endpoint, options);
        const result = await response.json();

        if (mounted) {
          setData(result);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err : new Error("An error occurred"));
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [endpoint, optionsString]);

  return { data, error, loading };
}

export function renameUsersFromServerResponse(
  users: UserFromServerResponse[]
): Partial<User>[] {
  return users.map((user) => {
    const { first_name, last_name, ...rest } = user;
    return {
      ...rest,
      firstName: first_name,
      lastName: last_name,
    };
  });
}

export function base64ToBlob(b64Data: string, contentType: string): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
