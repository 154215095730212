import { apiCall } from "@/lib/utils";
import { create } from "zustand";

export interface CaseDocument {
  id: string;
  title: string;
  type: string;
  dateProduced: string;
  producedBy: string;
  status: string;
  content: string;
  tags: string[];
}

interface UploadResult {
  filename: string;
  status: "success" | "failed";
  path?: string;
  error?: string;
}

interface DocumentState {
  documents: Record<string, CaseDocument>;
  isUploading: boolean;
  uploadResults: UploadResult[];
  getDocumentById: (
    id: string,
    caseId: string
  ) => Promise<CaseDocument | undefined>;
  getDocumentsByCaseId: (caseId: string) => Promise<CaseDocument[]>;
  addDocument: (document: CaseDocument) => Promise<void>;
  updateDocument: (id: string, updates: Partial<CaseDocument>) => Promise<void>;
  uploadFiles: (files: File[], caseId: string) => Promise<void>;
}

export const useDocumentStore = create<DocumentState>((set, get) => ({
  documents: {},
  isUploading: false,
  uploadResults: [],

  getDocumentById: async (id: string, caseId: string) => {
    const { documents } = get();
    if (documents[id]) {
      return documents[id];
    }

    try {
      const response = await apiCall(
        `/documents?case_id=${caseId}&file_id=${id}`,
        {
          method: "GET",
        }
      );
      const document: CaseDocument = await response.json();
      set((state) => ({
        documents: { ...state.documents, [id]: document },
      }));
      return document;
    } catch (error) {
      console.error("Failed to fetch document:", error);
      return undefined;
    }
  },

  getDocumentsByCaseId: async (caseId: string) => {
    try {
      const response = await apiCall(`/documents?case_id=${caseId}`, {
        method: "GET",
      });
      const documents: Record<string, CaseDocument> = await response.json();
      const documentArray = Object.values(documents);
      set((state) => ({
        documents: { ...state.documents, ...documents },
      }));
      return documentArray;
    } catch (error) {
      console.error("Failed to fetch documents for case:", error);
      return [];
    }
  },

  addDocument: async (document: CaseDocument) => {
    try {
      const response = await apiCall("/documents", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(document),
      });
      const newDocument = await response.json();
      set((state) => ({
        documents: { ...state.documents, [newDocument.id]: newDocument },
      }));
    } catch (error) {
      console.error("Failed to add document:", error);
    }
  },

  updateDocument: async (id: string, updates: Partial<CaseDocument>) => {
    try {
      const response = await apiCall(`/documents/${id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updates),
      });
      const updatedDocument = await response.json();
      set((state) => ({
        documents: { ...state.documents, [id]: updatedDocument },
      }));
    } catch (error) {
      console.error("Failed to update document:", error);
    }
  },

  uploadFiles: async (files: File[], caseId: string) => {
    set({ isUploading: true });
    const formData = new FormData();
    formData.append("case_id", caseId);

    files.forEach((file) => {
      if (file.type === "application/pdf") {
        formData.append("files", file);
        console.log(`Appending file: ${file.name}`);
      }
    });

    try {
      const response = await apiCall("/documents/upload_files", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      set({ uploadResults: data.results });

      // Refresh the documents list after upload
      const { getDocumentsByCaseId } = get();
      await getDocumentsByCaseId(caseId);
    } catch (error) {
      console.error("Upload error:", error);
      set({
        uploadResults: [
          { filename: "Upload", status: "failed", error: "Upload failed" },
        ],
      });
    } finally {
      set({ isUploading: false });
    }
  },
}));
