import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "@/store/authStore";
import GravixLoader from "./GravixLoader";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoggedIn, isInitializing } = useAuthStore();
  const location = useLocation();

  if (isInitializing) {
    // You might want to show a loading spinner here
    return <GravixLoader />;
  }

  if (!isLoggedIn) {
    // Redirect to login page, but save the current location
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
