import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

type MicroLoaderProps = {
  size?: "sm" | "md" | "lg";
  className?: string;
};

type Node = {
  id: number;
  x: number;
  y: number;
  direction: number;
  speed: number;
};

type Connection = {
  id: string;
  source: { x: number; y: number };
  target: { x: number; y: number };
  strength: number;
};

const GravixMicroLoader = ({ size = "sm", className }: MicroLoaderProps) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);

  // Enhanced size configurations
  const sizeConfigs = {
    sm: {
      container: "w-6 h-6",
      nodeSize: "w-1.5 h-1.5",
      strokeWidth: 0.75,
      numNodes: 4,
    },
    md: {
      container: "w-8 h-8",
      nodeSize: "w-2 h-2",
      strokeWidth: 1,
      numNodes: 4,
    },
    lg: {
      container: "w-10 h-10",
      nodeSize: "w-2.5 h-2.5",
      strokeWidth: 1.25,
      numNodes: 5,
    },
  };

  const createNode = useCallback(
    (id: number): Node => ({
      id,
      x: Math.random() * 80 + 10, // Keep within 10-90% range
      y: Math.random() * 80 + 10,
      direction: Math.random() * Math.PI * 2,
      speed: Math.random() * 0.8 + 0.4, // Slightly slower for small space
    }),
    []
  );

  const updateConnections = useCallback((currentNodes: Node[]) => {
    const newConnections: Connection[] = [];
    for (let i = 0; i < currentNodes.length; i++) {
      for (let j = i + 1; j < currentNodes.length; j++) {
        const nodeA = currentNodes[i];
        const nodeB = currentNodes[j];
        const distance = Math.sqrt(
          Math.pow(nodeA.x - nodeB.x, 2) + Math.pow(nodeA.y - nodeB.y, 2)
        );
        if (distance < 40) {
          // Increased connection threshold for micro version
          newConnections.push({
            id: `${nodeA.id}-${nodeB.id}`,
            source: { x: nodeA.x, y: nodeA.y },
            target: { x: nodeB.x, y: nodeB.y },
            strength: Math.max(0.2, 1 - distance / 40),
          });
        }
      }
    }
    setConnections(newConnections);
  }, []);

  useEffect(() => {
    // Initialize nodes
    const initialNodes = Array.from(
      { length: sizeConfigs[size].numNodes },
      (_, i) => createNode(i)
    );
    setNodes(initialNodes);

    const interval = setInterval(() => {
      setNodes((prevNodes) => {
        const updatedNodes = prevNodes.map((node) => {
          const dx = Math.cos(node.direction) * node.speed;
          const dy = Math.sin(node.direction) * node.speed;

          let newX = node.x + dx;
          let newY = node.y + dy;
          let newDirection = node.direction;

          // Bounce off boundaries with small random angle change
          if (newX < 10 || newX > 90) {
            newDirection = Math.PI - newDirection + (Math.random() * 0.2 - 0.1);
            newX = Math.max(10, Math.min(90, newX));
          }
          if (newY < 10 || newY > 90) {
            newDirection = -newDirection + (Math.random() * 0.2 - 0.1);
            newY = Math.max(10, Math.min(90, newY));
          }

          return { ...node, x: newX, y: newY, direction: newDirection };
        });

        updateConnections(updatedNodes);
        return updatedNodes;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [size, createNode, updateConnections]);

  return (
    <div className={cn("relative", sizeConfigs[size].container, className)}>
      <svg className="absolute inset-0 w-full h-full">
        <AnimatePresence>
          {connections.map((connection) => (
            <motion.line
              key={connection.id}
              initial={{ opacity: 0 }}
              animate={{
                opacity: connection.strength * 0.5,
                transition: { duration: 0.2 },
              }}
              exit={{ opacity: 0 }}
              x1={`${connection.source.x}%`}
              y1={`${connection.source.y}%`}
              x2={`${connection.target.x}%`}
              y2={`${connection.target.y}%`}
              stroke="#1A365D"
              strokeWidth={sizeConfigs[size].strokeWidth}
            />
          ))}
        </AnimatePresence>
      </svg>

      {nodes.map((node) => (
        <motion.div
          key={node.id}
          className={cn(
            "absolute rounded-full bg-primary",
            sizeConfigs[size].nodeSize
          )}
          style={{
            left: `${node.x}%`,
            top: `${node.y}%`,
            transform: "translate(-50%, -50%)",
          }}
          initial={{ scale: 0 }}
          animate={{
            scale: 1,
            transition: { duration: 0.2 },
          }}
        />
      ))}
    </div>
  );
};

export default GravixMicroLoader;
