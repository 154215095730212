import AccessDenied from "@/components/AccessDenied";
import GravixLoader from "@/components/GravixLoader";
import PasswordPrompter from "@/components/PasswordPrompter";
import UserCreationForm from "@/components/UserCreationForm";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { toast } from "@/hooks/use-toast";
import { useAuthStore } from "@/store/authStore";
import { useSettingsStore } from "@/store/userSettingsStore";
import { CreateUserData } from "@/types/shared";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FiEdit2, FiSave, FiTrash2, FiX } from "react-icons/fi";

const TenantSettings: React.FC = () => {
  const { tenants, fetchTenants, createTenant, updateTenant, deleteTenant } =
    useSettingsStore();
  const [newTenantName, setNewTenantName] = useState("");
  const [editingTenantId, setEditingTenantId] = useState<string | null>(null);
  const [editingTenantName, setEditingTenantName] = useState("");
  const { isInitializing, user, login } = useAuthStore();
  const [isInitializingTenants, setIsInitializingTenants] = useState(true);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  useEffect(() => {
    if (!isInitializing && user) {
      setIsInitializingTenants(false);
    }
  }, [isInitializing, user]);

  const handleCreateTenant = async (userData: CreateUserData) => {
    if (!newTenantName.trim()) {
      toast({
        title: "Error",
        description: "Tenant name cannot be empty.",
        variant: "destructive",
      });
      return;
    }
    try {
      await createTenant({
        tenant: {
          name: newTenantName,
        },
        new_params: {
          admin_user: {
            email: userData.user.email,
            first_name: userData.new_params.first_name,
            last_name: userData.new_params.last_name,
            password: userData.new_params.password,
          },
        },
      });
      setNewTenantName("");
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to create tenant. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleUpdateTenant = async () => {
    if (editingTenantId) {
      try {
        await updateTenant(editingTenantId, editingTenantName);
        setEditingTenantId(null);
        setEditingTenantName("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDeleteTenant = async (name: string) => {
    if (window.confirm("Are you sure you want to delete this tenant?")) {
      try {
        await deleteTenant(name);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleLoginAsTenant = async (tenantName: string) => {
    setSelectedTenant(tenantName);
    setIsLoginDialogOpen(true);
  };

  const handleLoginSubmit = async (password: string) => {
    if (!selectedTenant || !user) return;

    try {
      await login(user.email, password);
      toast({
        title: "Login Successful",
        description: `You are now logged in as ${selectedTenant}`,
      });
      setIsLoginDialogOpen(false);
      setSelectedTenant(null);
    } catch (error) {
      console.error(error);
      toast({
        title: "Login Failed",
        description:
          "Failed to login as tenant. Please check your password and try again.",
        variant: "destructive",
      });
    }
  };

  if (isInitializing || isInitializingTenants) {
    return <GravixLoader />;
  }

  if (user?.role !== "sys_admin") {
    return <AccessDenied />;
  }

  return (
    <>
      <Card className="w-full max-w-4xl mx-auto mt-8">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-primary">
            Tenant Management
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="newTenantName">New Tenant Name</Label>
            <Input
              id="newTenantName"
              placeholder="New Tenant Name"
              value={newTenantName}
              onChange={(e) => setNewTenantName(e.target.value)}
              className="flex-grow"
            />
          </div>
          <AnimatePresence>
            {newTenantName && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <UserCreationForm
                  createUser={handleCreateTenant}
                  tenantName={newTenantName}
                  isCreatingTenant={true}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead className="w-1/3">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {tenants.map((tenant) => (
                  <TableRow key={tenant.name} className="group relative">
                    <TableCell>
                      {editingTenantId === tenant.name ? (
                        <Input
                          value={editingTenantName}
                          onChange={(e) => setEditingTenantName(e.target.value)}
                          className="w-full"
                        />
                      ) : (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <span
                                className="cursor-pointer text-accent hover:underline"
                                onClick={() => handleLoginAsTenant(tenant.name)}
                              >
                                {tenant.name}
                              </span>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Login as {tenant.name}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute right-4 top-1/2 transform -translate-y-1/2 flex space-x-2">
                        {editingTenantId === tenant.name ? (
                          <>
                            <Button
                              onClick={handleUpdateTenant}
                              size="sm"
                              className="bg-accent hover:bg-accent/90 text-accent-foreground"
                            >
                              <FiSave className="mr-2" />
                              Save
                            </Button>
                            <Button
                              variant="outline"
                              onClick={() => {
                                setEditingTenantId(null);
                                setEditingTenantName("");
                              }}
                              size="sm"
                            >
                              <FiX className="mr-2" />
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outline"
                              onClick={() => {
                                setEditingTenantId(tenant.name);
                                setEditingTenantName(tenant.name);
                              }}
                              size="sm"
                            >
                              <FiEdit2 className="mr-2" />
                              Edit
                            </Button>
                            <Button
                              variant="destructive"
                              onClick={() => handleDeleteTenant(tenant.name)}
                              size="sm"
                            >
                              <FiTrash2 className="mr-2" />
                              Delete
                            </Button>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>

      <PasswordPrompter
        isOpen={isLoginDialogOpen}
        onOpenChange={setIsLoginDialogOpen}
        onSubmit={handleLoginSubmit}
        title="Login as Tenant"
        description={`Enter your password to login as ${selectedTenant}`}
      />
    </>
  );
};

export default TenantSettings;
