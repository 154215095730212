import React from "react";

interface TimelineEvent {
  date: string;
  title: string;
  description: string;
}

interface DocumentTimelineProps {
  events: TimelineEvent[];
}

export const DocumentTimeline: React.FC<DocumentTimelineProps> = ({
  events,
}) => (
  <div className="relative">
    <div className="absolute left-4 top-0 h-full w-0.5 bg-primary"></div>
    {events.map((event, index) => (
      <div key={index} className="mb-8 flex">
        <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary flex items-center justify-center mr-4 z-10">
          <div className="w-3 h-3 bg-white rounded-full"></div>
        </div>
        <div>
          <div className="font-semibold text-primary">{event.date}</div>
          <div className="font-bold mb-1">{event.title}</div>
          <p className="text-sm text-gray-600">{event.description}</p>
        </div>
      </div>
    ))}
  </div>
);
