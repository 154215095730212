import { create } from "zustand";

export interface Task {
  id: string;
  caseId: string;
  title: string;
  description: string;
  status: "pending" | "in_progress" | "completed";
  dueDate: Date;
  assignee: string;
  isAITask: boolean;
}

interface TaskState {
  tasks: Task[];
  addTask: (task: Omit<Task, "id">) => void;
  updateTask: (id: string, updates: Partial<Task>) => void;
  deleteTask: (id: string) => void;
  getTasksByCaseId: (caseId: string) => Task[];
}

const preloadedTasks: Task[] = [
  {
    id: "1",
    caseId: "depp-v-heard",
    title: "Review Washington Post op-ed",
    description:
      "Analyze the December 2018 Washington Post op-ed written by Amber Heard for potentially defamatory statements.",
    status: "completed",
    dueDate: new Date("2022-03-01"),
    assignee: "Lead Attorney",
    isAITask: false,
  },
  {
    id: "2",
    caseId: "depp-v-heard",
    title: "Compile evidence of economic damages",
    description:
      "Gather and organize evidence of Johnny Depp's economic losses, including lost film roles and endorsements.",
    status: "in_progress",
    dueDate: new Date("2022-03-15"),
    assignee: "Financial Analyst",
    isAITask: false,
  },
  {
    id: "3",
    caseId: "depp-v-heard",
    title: "Prepare witness list",
    description:
      "Identify and list potential witnesses, including former partners, co-stars, and industry professionals.",
    status: "completed",
    dueDate: new Date("2022-03-10"),
    assignee: "Paralegal",
    isAITask: false,
  },
  {
    id: "4",
    caseId: "depp-v-heard",
    title: "Analyze social media impact",
    description:
      "Assess the impact of the allegations on both parties' social media presence and public perception.",
    status: "in_progress",
    dueDate: new Date("2022-03-20"),
    assignee: "Social Media Analyst",
    isAITask: true,
  },
  {
    id: "5",
    caseId: "depp-v-heard",
    title: "Review audio recordings",
    description:
      "Analyze audio recordings of conversations between Depp and Heard for relevant evidence.",
    status: "pending",
    dueDate: new Date("2022-03-25"),
    assignee: "Legal Assistant",
    isAITask: false,
  },
  {
    id: "6",
    caseId: "depp-v-heard",
    title: "Prepare deposition questions",
    description:
      "Develop a list of questions for depositions of key witnesses.",
    status: "in_progress",
    dueDate: new Date("2022-04-01"),
    assignee: "Associate Attorney",
    isAITask: false,
  },
  {
    id: "7",
    caseId: "depp-v-heard",
    title: "Analyze previous legal proceedings",
    description:
      "Review and summarize relevant information from the UK libel case against The Sun.",
    status: "completed",
    dueDate: new Date("2022-03-05"),
    assignee: "Legal Researcher",
    isAITask: false,
  },
  {
    id: "8",
    caseId: "depp-v-heard",
    title: "Summarize key evidence",
    description:
      "Create a comprehensive summary of all key evidence collected, categorized by relevance and strength.",
    status: "pending",
    dueDate: new Date("2022-04-10"),
    assignee: "AI Assistant",
    isAITask: true,
  },
];

export const useTaskStore = create<TaskState>((set, get) => ({
  tasks: preloadedTasks,
  addTask: (task) =>
    set((state) => {
      const newTasks = [...state.tasks, { ...task, id: Date.now().toString() }];
      return { tasks: newTasks };
    }),
  updateTask: (id, updates) =>
    set((state) => {
      const newTasks = state.tasks.map((task) =>
        task.id === id ? { ...task, ...updates } : task
      );
      return { tasks: newTasks };
    }),
  deleteTask: (id) =>
    set((state) => {
      const newTasks = state.tasks.filter((task) => task.id !== id);
      return { tasks: newTasks };
    }),
  getTasksByCaseId: (caseId) => {
    const tasks = get().tasks.filter((task) => task.caseId === caseId);
    return tasks;
  },
}));
