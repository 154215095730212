export const evidentialTimelineMockData = {
  timelineEvents: [
    {
      id: "1",
      date: "2016-10",
      title: "Legal Representation Change",
      description:
        "Adam Carino began representing Johnny Depp, marking a significant shift in his legal strategy. This month also saw Depp terminate his long-time agent.",
      content:
        "[Adam Carino began representing Johnny Depp|Court records dated October 2016], marking a significant shift in his legal strategy. This month also saw [Depp terminate his long-time agent|Sworn statement from Depp's former agent], which may have influenced his public image and the ongoing legal disputes surrounding his relationship with Amber Heard.",
      status: "neutral",
      type: "evidential",
    },
    {
      id: "2",
      date: "2015-07-24",
      title: "Significant Communication Date",
      description:
        "This date is pivotal in the context of communications and interactions between Johnny Depp and Amber Heard.",
      content:
        "This date is pivotal in the context of [communications and interactions between Johnny Depp and Amber Heard|Text messages and email records submitted as evidence]. [The nature of these communications|Forensic analysis of digital communications] reflects the interpersonal dynamics and challenges faced by Depp, which are relevant to the ongoing legal proceedings.",
      status: "important",
      type: "evidential",
    },
    {
      id: "3",
      date: "2018-06-21",
      title: "Media Impact on Reputation",
      description:
        "An article published by Rolling Stone discussed Johnny Depp's reputation, serving as a critical document in the legal context.",
      content:
        "An [article published by Rolling Stone|Rolling Stone, June 21, 2018 issue] discussed [Johnny Depp's reputation|Analysis of public perception based on media reports], serving as a critical document in the legal context. The [media portrayal linked to this article|Media impact assessment report] may have had negative implications for Depp's public image and potential legal consequences.",
      status: "neutral",
      type: "evidential",
    },
    {
      id: "4",
      date: "2014-05",
      title: "The May 2014 Incident",
      description:
        "This incident is referenced in testimony by James, who had a professional relationship with Amber Heard.",
      content:
        "This incident is [referenced in testimony by James|Sworn deposition of James, dated September 2019], who had a [professional relationship with Amber Heard|Employment records and contracts]. The [details surrounding this event|Police report and witness statements] add complexity to the legal proceedings, potentially affecting the objectivity of testimonies and the court's perception of Heard's actions.",
      status: "important",
      type: "evidential",
    },
    {
      id: "5",
      date: "2016-05-21",
      title: "Domestic Violence Call",
      description:
        "A domestic violence call initiated a police response that led to an inquiry into the allegations made by Amber Heard against Johnny Depp.",
      content:
        "A [domestic violence call|911 call transcript and LAPD incident report] initiated a [police response|Body cam footage and officer statements] that led to an inquiry into the [allegations made by Amber Heard against Johnny Depp|Official police report and follow-up investigation documents]. This call signifies a critical moment in the timeline, highlighting the urgency and seriousness of the situation.",
      status: "important",
      type: "evidential",
    },
  ],
};

export const caseTimelineMockData = {
  timelineEvents: [
    {
      id: "c1",
      date: "2022-04-11",
      title: "Trial Commencement",
      description:
        "The defamation trial between Johnny Depp and Amber Heard begins in Fairfax County, Virginia.",
      content:
        "[The defamation trial between Johnny Depp and Amber Heard begins|Court docket, Case No. CL-2019-2911] in [Fairfax County, Virginia|Official court records]. This marks the start of the legal proceedings where [Depp is seeking $50 million in damages|Filed complaint document] for alleged defamation in Heard's 2018 op-ed.",
      status: "important",
      type: "case",
    },
    {
      id: "c2",
      date: "2022-04-19",
      title: "Depp's Testimony Begins",
      description:
        "Johnny Depp takes the stand for the first time in the trial.",
      content:
        "[Johnny Depp takes the stand|Court transcript, Day 5 of trial] for the first time in the trial. His testimony [covers his early life, career, and relationship with Heard|Sworn testimony transcript]. Key points include [denials of physical abuse allegations|Depp's sworn statement] and [claims of emotional and physical abuse by Heard|Depp's testimony on alleged incidents].",
      status: "important",
      type: "case",
    },
    {
      id: "c3",
      date: "2022-05-04",
      title: "Heard's Testimony Begins",
      description: "Amber Heard begins her testimony in the trial.",
      content:
        "[Amber Heard begins her testimony|Court transcript, Day 14 of trial]. Her account [includes allegations of physical and emotional abuse by Depp|Heard's sworn testimony]. Our strategy involves [cross-examination to highlight inconsistencies|Preparation notes for cross-examination] and [presenting contradictory evidence|List of evidence contradicting Heard's claims].",
      status: "neutral",
      type: "case",
    },
    {
      id: "c4",
      date: "2022-05-16",
      title: "Expert Witnesses Testify",
      description: "Various expert witnesses provide testimony on both sides.",
      content:
        "[Expert witnesses provide testimony|Court transcripts, Days 20-22 of trial] on both sides. Notable testimonies include [forensic psychologist Dr. Shannon Curry|Expert witness report] diagnosing Heard with personality disorders, and [orthopedic surgeon Dr. Richard Gilbert|Medical expert testimony] discussing Depp's finger injury. Our focus is on [leveraging expert testimonies that support Depp's case|Strategy document for expert witness integration].",
      status: "important",
      type: "case",
    },
    {
      id: "c5",
      date: "2022-05-27",
      title: "Closing Arguments",
      description:
        "Both legal teams present their closing arguments to the jury.",
      content:
        "[Both legal teams present closing arguments|Court transcript, Day 24 of trial]. Our closing argument [emphasizes key evidence supporting Depp's claims|Closing argument script], [highlights inconsistencies in Heard's testimony|Document listing inconsistencies in Heard's statements], and [reiterates the impact of defamation on Depp's career|Economic impact report on Depp's career post-2018]. We also [address and refute Heard's counterclaims|Rebuttal points for Heard's counterclaims].",
      status: "important",
      type: "case",
    },
  ],
};
