import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { TimelineEvent } from "@/store/timelineStore";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { GroundedText } from "@/components/GroundedText/GroundedText";

interface TimelineDetailCardProps {
  event: TimelineEvent | null;
}

export const TimelineDetailCard: React.FC<TimelineDetailCardProps> = ({
  event,
}) => {
  if (!event) {
    return null;
  }

  const renderGroundedContent = (content: string) => {
    const parts = content.split(/(\[.*?\])/g);
    return parts.map((part, index) => {
      if (part.startsWith("[") && part.endsWith("]")) {
        const [text, source] = part.slice(1, -1).split("|");
        return <GroundedText key={index} text={text} source={source} />;
      }
      return part;
    });
  };

  return (
    <Card className="h-full">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl font-bold">{event.title}</CardTitle>
          <Badge
            variant="outline"
            className={cn(
              "text-xs px-2 py-1",
              event.type === "evidential"
                ? "bg-primary/10 text-primary"
                : "bg-accent/10 text-accent"
            )}
          >
            {event.type.charAt(0).toUpperCase() + event.type.slice(1)}
          </Badge>
        </div>
        <p className="text-sm text-muted-foreground">{event.date}</p>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-semibold mb-1">Description:</h3>
            <p className="text-sm leading-relaxed">{event.description}</p>
          </div>
          <div>
            <h3 className="text-sm font-semibold mb-1">Detailed Content:</h3>
            <p className="text-sm leading-relaxed">
              {renderGroundedContent(event.content)}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <Badge
            variant="outline"
            className={cn(
              "text-xs px-2 py-1",
              event.status === "important"
                ? "bg-accent/10 text-accent"
                : event.status === "irrelevant"
                ? "bg-destructive/10 text-destructive"
                : "bg-secondary text-secondary-foreground"
            )}
          >
            {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
          </Badge>
        </div>
      </CardContent>
    </Card>
  );
};
