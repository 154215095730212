import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Clock, ChevronRight } from "lucide-react";
import { useStore } from "@/store/caseStore";

interface KeyEvent {
  date: string;
  event: string;
  entityId?: string;
}

interface KeyEventsCardProps {
  caseId: string;
}

export const KeyEventsCard: React.FC<KeyEventsCardProps> = ({ caseId }) => {
  const caseData = useStore((state) => state.getCaseById(caseId));

  if (!caseData) {
    return null;
  }

  return (
    <Card className="hover:shadow-lg transition-shadow duration-300">
      <CardHeader className=" text-primary rounded-t-md">
        <CardTitle className="flex items-center text-2xl">
          <Clock className="mr-2 h-6 w-6" />
          Key Events
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-primary font-bold">Date</TableHead>
              <TableHead className="text-primary font-bold">Event</TableHead>
              <TableHead className="text-primary font-bold w-10"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {caseData.keyEvents.map((event: KeyEvent, index: number) => (
              <TableRow
                key={index}
                className="hover:bg-accent/5 cursor-pointer transition-colors duration-200"
              >
                <TableCell className="font-medium">{event.date}</TableCell>
                <TableCell>{event.event}</TableCell>
                <TableCell>
                  <Link
                    to={`/cases/${caseId}/wiki/${
                      event.entityId || `event-${index}`
                    }`}
                    className="text-accent hover:text-accent-dark transition-colors duration-200"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="p-4">
          <Link to={`/cases/${caseId}/timeline`} className="block w-full">
            <Button
              variant="outline"
              className="w-full hover:bg-accent hover:text-white transition-colors duration-200"
            >
              View Full Timeline
            </Button>
          </Link>
        </div>
      </CardContent>
    </Card>
  );
};
