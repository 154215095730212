import React from "react";

interface Entity {
  id: string;
  type: string;
  name: string;
  role?: string;
  description?: string;
}

interface RelatedEntitiesProps {
  entities: Entity[];
}

const RelatedEntities: React.FC<RelatedEntitiesProps> = ({ entities }) => {
  return (
    <ul className="space-y-2">
      {entities.map((entity) => (
        <li key={entity.id} className="bg-light-gray p-2 rounded">
          <span className="inline-block bg-accent text-white text-xs px-1 rounded mr-2">
            {entity.type}
          </span>
          {entity.name} - {entity.role || entity.description}
        </li>
      ))}
    </ul>
  );
};

export default RelatedEntities;
