import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Case } from "../types";

interface CaseCardProps {
  caseItem: Case;
}

export const CaseCard: React.FC<CaseCardProps> = ({ caseItem }) => (
  <Card className="hover:shadow-lg transition-shadow">
    <CardHeader>
      <CardTitle className="flex justify-between items-center">
        <Link
          to={`/cases/${caseItem.id}`}
          className="text-lg font-semibold text-primary hover:underline"
        >
          {caseItem.details.description}
        </Link>
        <Badge
          variant={
            caseItem.details.status === "Active" ? "default" : "secondary"
          }
        >
          {caseItem.details.status}
        </Badge>
      </CardTitle>
    </CardHeader>
    <CardContent>
      <div className="flex justify-between items-center mb-2">
        <span className="text-muted-foreground">{caseItem.details.year}</span>
        <Badge variant="outline">{caseItem.details.type}</Badge>
      </div>
      <p>{caseItem.details.summary}</p>
      <Link
        to={`/cases/${caseItem.name}`}
        className="mt-4 inline-block text-accent hover:underline"
      >
        View Case Details
      </Link>
    </CardContent>
  </Card>
);
