import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Page as PageType } from "./types";
import { Document, Page, pdfjs } from "react-pdf";
import { usePDFViewerStore } from "./pdfViewerStore";
import { cn } from "@/lib/utils";
import { PageCallback } from "react-pdf/dist/esm/shared/types.js";
import { debounce } from "lodash";

interface RightPanelProps {
  pdfBlob: Blob;
}

interface PageDimension {
  width: number;
  height: number;
  offsetTop: number;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const RightPanel: React.FC<RightPanelProps> = ({ pdfBlob: pdfFile }) => {
  const {
    documentData,
    selectedSegment,
    hoveredSegment,
    zoomLevel,
    currentPage,
    controlledScrollTarget,
    setCurrentPage,
    setScrollingPanel,
  } = usePDFViewerStore();

  const [pageScale, setPageScale] = useState<number>(1);
  const [pageDimensions, setPageDimensions] = useState<
    Map<number, PageDimension>
  >(new Map());
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<Map<number, HTMLDivElement>>(new Map());
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  const updateScale = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 48;
      const baseScale = containerWidth / 612;
      const maxScale = 2;
      const newScale = Math.min(baseScale, maxScale) * zoomLevel;
      setPageScale(newScale);
    }
  }, [zoomLevel]);

  useEffect(() => {
    window.addEventListener("resize", updateScale);
    if (containerRef.current) {
      updateScale();
    }
    return () => window.removeEventListener("resize", updateScale);
  }, [updateScale, containerRef.current]);

  useEffect(() => {
    const updatePageDimensions = () => {
      pageRefs.current.forEach((pageDiv, pageNumber) => {
        const { width, height } = pageDiv.getBoundingClientRect();
        const offsetTop = pageDiv.offsetTop;
        setPageDimensions(
          (prev) => new Map(prev.set(pageNumber, { width, height, offsetTop }))
        );
      });
    };
    updatePageDimensions();
  }, [pageScale]);

  // Handle programmatic page changes
  useEffect(() => {
    if (currentPage && containerRef.current && !controlledScrollTarget) {
      const pageDiv = pageRefs.current.get(currentPage);
      if (pageDiv) {
        setIsAutoScrolling(true);
        containerRef.current.scrollTo({
          top: pageDiv.offsetTop - 20,
          behavior: "smooth",
        });

        // Reset auto-scrolling flag after animation
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
        scrollTimeoutRef.current = setTimeout(() => {
          setIsAutoScrolling(false);
        }, 1000); // Match this with your scroll animation duration
      }
    }
  }, [currentPage, controlledScrollTarget]);

  useEffect(() => {
    if (hoveredSegment) {
      const pageMatch = hoveredSegment.match(/page-(\d+)/);
      const blockMatch = hoveredSegment.match(/block-(\d+)/);

      if (pageMatch && blockMatch) {
        const pageNumber = parseInt(pageMatch[1], 10);
        const blockIndex = parseInt(blockMatch[1], 10);
        const page = documentData?.pages.find(
          (p) => p.pageNumber === pageNumber
        );

        if (page) {
          const block = page.blocks[blockIndex];
          const pageDimension = pageDimensions.get(pageNumber);

          if (block && pageDimension && containerRef.current) {
            const highlightTop =
              pageDimension.offsetTop +
              block.layout.boundingPoly.normalizedVertices[0].y *
                pageDimension.height;
            const highlightHeight =
              (block.layout.boundingPoly.normalizedVertices[2].y -
                block.layout.boundingPoly.normalizedVertices[0].y) *
              pageDimension.height;
            const containerHeight = containerRef.current.clientHeight;

            setIsAutoScrolling(true);
            containerRef.current.scrollTo({
              top: Math.max(
                0,
                highlightTop - containerHeight / 2 + highlightHeight / 2
              ),
              behavior: "smooth",
            });

            if (scrollTimeoutRef.current) {
              clearTimeout(scrollTimeoutRef.current);
            }
            scrollTimeoutRef.current = setTimeout(() => {
              setIsAutoScrolling(false);
            }, 1000);
          }
        }
      }
    }
  }, [hoveredSegment, documentData, pageDimensions]);

  const onPageRenderSuccess = (page: PageCallback) => {
    const pageDiv = pageRefs.current.get(page.pageNumber);
    if (pageDiv) {
      const { width, height } = pageDiv.getBoundingClientRect();
      const offsetTop = pageDiv.offsetTop;
      setPageDimensions(
        (prev) =>
          new Map(
            prev.set(page.pageNumber, {
              width,
              height,
              offsetTop,
            })
          )
      );
    }
  };

  const renderHighlightOverlay = (
    page: PageType,
    selectedSegment: string | null,
    hoveredSegment: string | null
  ) => {
    const segmentToRender = hoveredSegment || selectedSegment;
    if (!segmentToRender) return null;

    const pageMatch = segmentToRender.match(/page-(\d+)/);
    const blockMatch = segmentToRender.match(/block-(\d+)/);

    const selectedPageNumber = pageMatch ? parseInt(pageMatch[1], 10) : NaN;
    const selectedBlockIndex = blockMatch ? parseInt(blockMatch[1], 10) : NaN;

    if (page.pageNumber !== selectedPageNumber) return null;

    const block = page.blocks[selectedBlockIndex];
    if (!block) return null;

    const pageDimension = pageDimensions.get(page.pageNumber);
    if (!pageDimension) return null;

    const vertices = block.layout.boundingPoly.normalizedVertices;

    const highlight = {
      left: vertices[0].x * pageDimension.width,
      top: vertices[0].y * pageDimension.height,
      width: (vertices[1].x - vertices[0].x) * pageDimension.width,
      height: (vertices[2].y - vertices[0].y) * pageDimension.height,
    };

    return (
      <div
        className={cn(
          "highlighted absolute border-2 pointer-events-none transition-all duration-200",
          hoveredSegment
            ? "border-accent bg-accent/30 shadow-lg"
            : "border-primary bg-primary/30 shadow-md"
        )}
        style={{
          left: `${highlight.left - 4}px`,
          top: `${highlight.top - 4}px`,
          width: `${highlight.width + 8}px`,
          height: `${highlight.height + 8}px`,
          zIndex: 1000,
          transform: hoveredSegment ? "scale(1.05)" : "scale(1)",
        }}
      />
    );
  };

  const handleScroll = useCallback(() => {
    if (containerRef.current && !isAutoScrolling && !controlledScrollTarget) {
      const { scrollTop, clientHeight } = containerRef.current;
      const middleY = scrollTop + clientHeight / 2;

      let closestPage = 1;
      let closestDistance = Infinity;

      pageRefs.current.forEach((pageDiv, pageNumber) => {
        const { offsetTop, offsetHeight } = pageDiv;
        const distance = Math.abs(offsetTop + offsetHeight / 2 - middleY);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestPage = pageNumber;
        }
      });

      setCurrentPage(closestPage);
    }
  }, [setCurrentPage, controlledScrollTarget, isAutoScrolling]);

  const debouncedHandleScroll = useMemo(
    () => debounce(handleScroll, 150),
    [handleScroll]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", debouncedHandleScroll);
      return () => {
        container.removeEventListener("scroll", debouncedHandleScroll);
        debouncedHandleScroll.cancel();
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }
  }, [debouncedHandleScroll]);

  if (!documentData) return null;

  return (
    <div
      ref={containerRef}
      className="w-2/3 overflow-y-auto bg-background h-full"
      onMouseEnter={() => setScrollingPanel("right")}
    >
      <div className="flex flex-col items-center py-8 px-6">
        <Document file={pdfFile}>
          {documentData.pages.map((page) => (
            <div
              key={`page_${page.pageNumber}`}
              ref={(el) => {
                if (el) pageRefs.current.set(page.pageNumber, el);
              }}
              className="relative mb-8 shadow-lg"
            >
              <Page
                key={`page_${page.pageNumber}`}
                pageNumber={page.pageNumber}
                scale={pageScale}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onRenderSuccess={onPageRenderSuccess}
                className="bg-white"
              />
              <div className="absolute top-0 left-0 bg-background/80 text-foreground px-2 py-1 text-sm">
                Page {page.pageNumber}
              </div>
              {renderHighlightOverlay(page, selectedSegment, hoveredSegment)}
            </div>
          ))}
        </Document>
      </div>
    </div>
  );
};

export default React.memo(RightPanel);
