import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useChatStore } from "@/features/chat/chatStore";
import { ChatComponent } from "@/features/chat/ChatComponent";
import { ConversationSidebar } from "@/features/chat/ConversationSidebar";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Skeleton } from "@/components/ui/skeleton";
import { AlertCircle, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useCaseUiStore } from "../case/caseUiStore";

interface ChatPageProps {
  className?: string;
}

export const ChatPage: React.FC<ChatPageProps> = ({ className }) => {
  const { caseId, chatId } = useParams<{ caseId: string; chatId: string }>();
  const navigate = useNavigate();
  const {
    setCurrentConversation,
    createConversation,
    getConversations,
    currentConversationId,
    error,
    syncConversationWithServer,
    retryFailedSync,
    conversations,
  } = useChatStore();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [syncError, setSyncError] = useState<string | null>(null);

  const currentConversation = conversations.find(
    (conv) => conv.id === currentConversationId
  );

  const { setSecondaryHeader } = useCaseUiStore();

  useEffect(() => {
    setSecondaryHeader(`Chat: ${currentConversation?.title || ""}`);
  }, [setSecondaryHeader, currentConversation]);

  useEffect(() => {
    if (!caseId) return;

    const initializeChat = async () => {
      try {
        setSyncError(null);
        const caseConversations = await getConversations(caseId);

        if (chatId) {
          const existingConversation = caseConversations.find(
            (conv) => conv.id === chatId
          );

          if (existingConversation) {
            setCurrentConversation(chatId);
            if (existingConversation.syncStatus !== "synced") {
              await syncConversationWithServer(chatId);
            }
          } else {
            const newChatId = await createConversation(
              caseId,
              `Case ${caseId} Chat`
            );
            await setCurrentConversation(newChatId);
          }
        } else {
          if (caseConversations.length === 0) {
            const newChatId = await createConversation(
              caseId,
              `Case ${caseId} Chat`
            );
            navigate(`/cases/${caseId}/chat/${newChatId}`, { replace: true });
          } else {
            navigate(`/cases/${caseId}/chat/${caseConversations[0].id}`, {
              replace: true,
            });
          }
        }
      } catch (err) {
        console.error("Failed to initialize chat:", err);
        setSyncError(
          err instanceof Error ? err.message : "Failed to initialize chat"
        );
      } finally {
        setIsFirstLoad(false);
      }
    };

    initializeChat();
  }, [
    caseId,
    chatId,
    createConversation,
    getConversations,
    navigate,
    setCurrentConversation,
    syncConversationWithServer,
  ]);

  const handleRetrySync = async () => {
    if (!currentConversationId) return;

    try {
      setSyncError(null);
      await retryFailedSync(currentConversationId);
    } catch (err) {
      setSyncError(
        err instanceof Error ? err.message : "Failed to sync with server"
      );
    }
  };

  const LoadingSkeleton = () => (
    <div className="flex flex-grow bg-card rounded-lg shadow-lg overflow-hidden border">
      <div className="w-64 border-r border-border">
        <div className="p-4 border-b border-border">
          <Skeleton className="h-10 w-full" />
        </div>
        <div className="p-4 space-y-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="flex items-center space-x-4">
              <Skeleton className="h-8 w-8 rounded-full" />
              <Skeleton className="h-8 flex-1" />
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow flex flex-col lg:px-32">
        <div className="flex-grow p-6">
          <div className="space-y-6">
            {/* AI Message */}
            <div className="flex w-full justify-start">
              <div className="max-w-[85%]">
                <Skeleton className="h-24 w-96 rounded-2xl rounded-bl-sm" />
              </div>
            </div>

            {/* User Message */}
            <div className="flex w-full justify-end">
              <div className="max-w-[85%]">
                <Skeleton className="h-16 w-80 rounded-2xl rounded-br-sm bg-primary/20" />
              </div>
            </div>

            {/* AI Message */}
            <div className="flex w-full justify-start">
              <div className="max-w-[85%]">
                <Skeleton className="h-32 w-[30rem] rounded-2xl rounded-bl-sm" />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border-t border-border">
          <div className="flex space-x-2">
            <Skeleton className="h-10 flex-grow" />
            <Skeleton className="h-10 w-10" />
          </div>
        </div>
      </div>
    </div>
  );

  if (!caseId) {
    return (
      <Alert variant="destructive" className="m-4">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>Case ID is required</AlertDescription>
      </Alert>
    );
  }

  const showSyncAlert =
    currentConversation?.syncStatus === "failed" ||
    currentConversation?.messages.some((msg) => msg.syncStatus === "failed");

  return (
    <div
      className={`mx-auto flex flex-col h-[calc(100vh-24rem)] pb-0 ${className}`}
    >
      {(error || syncError || showSyncAlert) && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Synchronization Error</AlertTitle>
          <AlertDescription className="flex items-center gap-4">
            <span>
              {error || syncError || "Some messages failed to sync with server"}
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={handleRetrySync}
              className="gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              Retry Sync
            </Button>
          </AlertDescription>
        </Alert>
      )}

      {isFirstLoad ? (
        <LoadingSkeleton />
      ) : (
        <div className="flex flex-grow bg-card rounded-lg shadow-lg overflow-hidden border">
          <ConversationSidebar />
          <div className="flex-grow flex flex-col lg:px-32 overflow-hidden">
            {currentConversationId && (
              <ChatComponent
                caseId={caseId}
                chatId={currentConversationId}
                initialTitle={currentConversation?.title || ""}
                className=""
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
