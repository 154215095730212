import React, { useEffect, useRef } from "react";
import TextSegment from "./TextSegment";
import { usePDFViewerStore } from "./pdfViewerStore";
import { Block } from "./types";

const LeftPanel: React.FC = () => {
  const {
    documentData,
    selectedSegment,
    currentPage,
    scrollingPanel,
    controlledScrollTarget,
    setSelectedSegment,
    setHoveredSegment,
    setScrollingPanel,
    setControlledScrollTarget,
  } = usePDFViewerStore();

  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollToTarget = () => {
      if (controlledScrollTarget && containerRef.current) {
        const targetElement = document.getElementById(controlledScrollTarget);
        if (targetElement) {
          const containerRect = containerRef.current.getBoundingClientRect();
          const targetRect = targetElement.getBoundingClientRect();
          const scrollTop =
            targetRect.top -
            containerRect.top +
            containerRef.current.scrollTop -
            20;

          containerRef.current.scrollTo({
            top: scrollTop,
            behavior: "smooth",
          });

          // Clear the controlled scroll target after scrolling
          setControlledScrollTarget(null);
        }
      }
    };

    scrollToTarget();
  }, [controlledScrollTarget, setControlledScrollTarget]);

  useEffect(() => {
    const scrollToPage = () => {
      if (
        currentPage !== null &&
        pageRefs.current[currentPage - 1] &&
        scrollingPanel === "right" &&
        !controlledScrollTarget // Only scroll if there's no controlled scroll target
      ) {
        const pageElement = pageRefs.current[currentPage - 1];
        const scrollPosition = pageElement?.offsetTop || 0;

        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: scrollPosition - 20,
            behavior: "smooth",
          });
        }
      }
    };

    const timeoutId = setTimeout(scrollToPage, 100);

    return () => clearTimeout(timeoutId);
  }, [currentPage, scrollingPanel, controlledScrollTarget]);

  if (!documentData) return null;

  return (
    <div
      ref={containerRef}
      id="left-panel-container"
      className="w-1/3 border-r border-border bg-secondary h-full overflow-y-auto"
      onMouseEnter={() => setScrollingPanel("left")}
    >
      <div className="p-4 space-y-6">
        {documentData.pages.map((page, pageIndex) => (
          <div
            key={pageIndex}
            ref={(el) => (pageRefs.current[pageIndex] = el)}
            className="bg-card rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow"
          >
            <h2 className="text-xl font-bold mb-4 text-primary flex items-center">
              <span className="mr-2">Page {page.pageNumber}</span>
              <span className="text-sm text-muted-foreground">
                ({page.blocks.length} segments)
              </span>
            </h2>
            <div className="space-y-2">
              {page.blocks.map((block, blockIndex) => {
                const segmentId = getSegmentId(page.pageNumber, blockIndex);
                return (
                  <TextSegment
                    key={blockIndex}
                    id={segmentId}
                    text={extractTextFromBlock(block, documentData.text)}
                    isSelected={selectedSegment === segmentId}
                    onClick={() => {
                      setSelectedSegment(segmentId);
                    }}
                    onMouseEnter={() => {
                      setHoveredSegment(segmentId);
                    }}
                    onMouseLeave={() => {
                      setHoveredSegment(null);
                    }}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function extractTextFromBlock(block: Block, fullText: string): string {
  const textSegments = block.layout.textAnchor.textSegments;
  const startIndex = parseInt(textSegments[0].startIndex || "0", 10);
  const endIndex = parseInt(textSegments[0].endIndex, 10);
  return fullText.substring(startIndex, endIndex).trim();
}

function getSegmentId(pageNumber: number, blockIndex: number): string {
  return `page-${pageNumber}-block-${blockIndex}`;
}

export default LeftPanel;
