import { create } from "zustand";

export interface AgendaDocument {
  id: string;
  name: string;
  title: string;
  importance: string;
}

export interface AgendaDetails {
  clientName: string;
  description: string;
  mainClaim: string;
  strategy: string;
  documents: AgendaDocument[];
}

interface AgendaStore {
  agendaDetails: AgendaDetails;
  isLoading: boolean;
  error: string | null;
  fetchAgenda: (caseId: string) => Promise<void>;
  updateAgendaDetails: (field: keyof AgendaDetails, value: string) => void;
  updateDocument: (
    id: string,
    field: keyof AgendaDocument,
    value: string
  ) => void;
  addDocument: () => void;
  saveAgenda: () => Promise<void>;
  removeDocument: (id: string) => void;
}

const mockAgendaData: AgendaDetails = {
  clientName: "Johnny Depp",
  description:
    "Defamation lawsuit against ex-wife Amber Heard regarding her 2018 op-ed in The Washington Post.",
  mainClaim: "Defamation causing reputational and financial damages",
  strategy:
    "Demonstrate falsehood of allegations, present evidence of Depp as victim, and quantify damages to career and reputation.",
  documents: [
    {
      id: "1",
      name: "WaPo_OpEd.pdf",
      title: "Amber Heard's Washington Post Op-Ed",
      importance: "Critical",
    },
    {
      id: "2",
      name: "Audio_Recordings.mp3",
      title: "Audio Evidence of Verbal Exchanges",
      importance: "High",
    },
    {
      id: "3",
      name: "Witness_Statements.docx",
      title: "Statements from Staff and Associates",
      importance: "High",
    },
    {
      id: "4",
      name: "Medical_Records.pdf",
      title: "Medical Reports of Alleged Injuries",
      importance: "Medium",
    },
    {
      id: "5",
      name: "Financial_Analysis.xlsx",
      title: "Career Impact and Financial Losses",
      importance: "High",
    },
  ],
};

export const useAgendaStore = create<AgendaStore>((set, get) => ({
  agendaDetails: mockAgendaData,
  isLoading: false,
  error: null,

  fetchAgenda: async (caseId: string) => {
    set({ isLoading: true, error: null });
    try {
      // Simulate API call
      console.log(caseId);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      set({ agendaDetails: mockAgendaData, isLoading: false });
    } catch (error) {
      console.error(error);
      set({ error: "Failed to fetch agenda", isLoading: false });
    }
  },

  updateAgendaDetails: (field, value) => {
    set((state) => ({
      agendaDetails: { ...state.agendaDetails, [field]: value },
    }));
  },

  updateDocument: (id, field, value) => {
    set((state) => ({
      agendaDetails: {
        ...state.agendaDetails,
        documents: state.agendaDetails.documents.map((doc) =>
          doc.id === id ? { ...doc, [field]: value } : doc
        ),
      },
    }));
  },

  addDocument: () => {
    const newDocument: AgendaDocument = {
      id: Date.now().toString(),
      name: "New Document",
      title: "",
      importance: "",
    };
    set((state) => ({
      agendaDetails: {
        ...state.agendaDetails,
        documents: [...state.agendaDetails.documents, newDocument],
      },
    }));
  },

  saveAgenda: async () => {
    set({ isLoading: true, error: null });
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      set({ isLoading: false });
      console.log("Agenda saved:", get().agendaDetails);
    } catch (error) {
      console.error(error);
      set({ error: "Failed to save agenda", isLoading: false });
    }
  },

  removeDocument: (id: string) => {
    set((state) => ({
      agendaDetails: {
        ...state.agendaDetails,
        documents: state.agendaDetails.documents.filter((doc) => doc.id !== id),
      },
    }));
  },
}));
