import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Users,
  MapPin,
  Building,
  AlertCircle,
  ChevronRight,
} from "lucide-react";

const icons = {
  Users,
  MapPin,
  Building,
  AlertCircle,
};

interface Entity {
  id: string;
  name: string;
  role?: string;
  description?: string;
  date?: string;
}

interface KeyEntitiesCardProps {
  title: string;
  entities: Entity[];
  icon: keyof typeof icons;
  caseId: string;
  entityType: string;
}

export const KeyEntitiesCard: React.FC<KeyEntitiesCardProps> = ({
  title,
  entities,
  icon,
  caseId,
  entityType,
}) => {
  const Icon = icons[icon];
  return (
    <Card className="hover:shadow-lg transition-shadow">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span className="flex items-center">
            <Icon className="mr-2 h-5 w-5" />
            {title}
          </span>
          <Link
            to={`/cases/${caseId}/wiki/entities/${entityType}`}
            className="text-xs text-accent hover:underline flex items-center"
          >
            View all
            <ChevronRight className="h-3 w-3 ml-1" />
          </Link>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {entities.map((entity) => (
            <li key={entity.id}>
              <Link
                to={`/cases/${caseId}/wiki/${entity.id}`}
                className="text-accent hover:underline"
              >
                {entity.name}
              </Link>
              <span className="text-sm text-muted-foreground ml-2">
                ({entity.role || entity.description || entity.date})
              </span>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};
