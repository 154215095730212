import React from "react";
import { usePDFViewerStore } from "./pdfViewerStore";
import { motion } from "framer-motion";
import { FileText } from "lucide-react";

const SelectedSegmentDisplay: React.FC = () => {
  const { selectedSegment, documentData, setCurrentPage } = usePDFViewerStore();

  if (!selectedSegment || !documentData) return null;

  const [, pageNumber, , blockIndex] = selectedSegment.split("-");
  const page = documentData.pages.find(
    (p) => p.pageNumber === parseInt(pageNumber, 10)
  );
  const block = page?.blocks[parseInt(blockIndex, 10)];

  if (!block) return null;

  const handleClick = () => {
    setCurrentPage(parseInt(pageNumber, 10));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="flex items-center space-x-2 bg-secondary text-foreground rounded-md px-3 py-1.5 text-sm"
    >
      <FileText className="w-4 h-4 text-muted-foreground" />
      <span className="text-muted-foreground">Selected:</span>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={handleClick}
        className="font-medium text-accent hover:text-primary transition-colors duration-200 focus:outline-none focus:underline"
      >
        Page {pageNumber}, Block {parseInt(blockIndex, 10) + 1}
      </motion.button>
    </motion.div>
  );
};

export default SelectedSegmentDisplay;
