import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useChatStore } from "@/features/chat/chatStore";
import { MessageSquare, Plus, Search, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

export const ConversationSidebar: React.FC = () => {
  const {
    getConversations,
    createConversation,
    currentConversationId,
    conversations,
    deleteConversation,
    clearChatHistory,
  } = useChatStore();

  const navigate = useNavigate();
  const { caseId } = useParams<{ caseId: string }>();

  const [searchTerm, setSearchTerm] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState<
    string | null
  >(null);

  useEffect(() => {
    const fetchConversations = async () => {
      if (!caseId) {
        return;
      }
      await getConversations(caseId);
    };

    fetchConversations();
  }, [getConversations, caseId]);

  const handleNewConversation = async () => {
    const newChatId = await createConversation(caseId!);
    navigate(`/cases/${caseId}/chat/${newChatId}`);
  };

  const handleDeleteClick = (id: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setConversationToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (conversationToDelete) {
      await clearChatHistory(conversationToDelete);
      deleteConversation(conversationToDelete);
      setDeleteDialogOpen(false);
      setConversationToDelete(null);
    }
  };

  const filteredConversations = conversations.filter((conv) =>
    conv.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="w-64 border-r border-gray-200 flex flex-col h-full bg-white">
        <div className="p-4 border-b border-gray-200">
          <Button
            onClick={handleNewConversation}
            className="w-full bg-primary hover:bg-primary/90 text-white transition-colors"
          >
            <Plus className="mr-2 h-4 w-4" /> New Chat
          </Button>
        </div>

        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search chats..."
              className="w-full pl-10 pr-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
        </div>

        <div className="flex-grow overflow-y-auto">
          <ul className="divide-y divide-gray-200">
            {filteredConversations.map((conv) => (
              <li key={conv.id} className="group">
                <Link
                  to={`/cases/${caseId}/chat/${conv.id}`}
                  className={`flex items-center px-4 py-3 hover:bg-gray-50 ${
                    conv.id === currentConversationId ? "bg-gray-100" : ""
                  }`}
                >
                  <MessageSquare className="mr-3 h-5 w-5 text-gray-400" />
                  <span className="text-sm text-gray-700 font-medium truncate flex-grow">
                    {conv.title}
                  </span>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => handleDeleteClick(conv.id, e)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity p-2 hover:bg-gray-200 rounded-full"
                  >
                    <Trash2 className="h-4 w-4 text-gray-400 group-hover:text-gray-600" />
                  </Button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Conversation</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this conversation? This action
              cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteConfirm}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
