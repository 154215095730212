import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { useSpecificDocumentStore } from "@/store/specificDocumentStore";
import { Minus, Plus, RotateCcw } from "lucide-react";
import React, { useEffect, useState } from "react";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import SelectedSegmentDisplay from "./SelectedSegmentDisplay";
import { processDocumentData } from "./documentLayoutUtils";
import { usePDFViewerStore } from "./pdfViewerStore";

interface PDFViewer2Props {
  onClose: () => void;
}

const PDFViewerV2: React.FC<PDFViewer2Props> = ({ onClose }) => {
  const {
    documentData,
    zoomLevel,
    setDocumentData,
    setZoomLevel,
    setCurrentPage,
  } = usePDFViewerStore();

  const { previewBlob, transcriptData, error } = useSpecificDocumentStore();
  const [pageInput, setPageInput] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    if (transcriptData) {
      try {
        setDocumentData(processDocumentData(transcriptData));
      } catch (error) {
        toast({
          title: "Error processing document",
          description:
            error instanceof Error
              ? error.message
              : "Failed to process document data",
          variant: "destructive",
        });
      }
    }
  }, [transcriptData, setDocumentData, toast]);

  const handleZoomIn = () => {
    setZoomLevel(Math.min(zoomLevel + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoomLevel(Math.max(zoomLevel - 0.1, 0.5));
  };

  const handleResetZoom = () => {
    setZoomLevel(1);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (
      documentData &&
      pageNumber >= 1 &&
      pageNumber <= documentData.pages.length
    ) {
      setCurrentPage(pageNumber);
      setPageInput("");
    } else {
      toast({
        title: "Invalid page number",
        description: `Please enter a page number between 1 and ${
          documentData?.pages.length || 1
        }`,
        variant: "destructive",
      });
    }
  };

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <Alert variant="destructive">
            <AlertTitle>Error Loading Document</AlertTitle>
            <AlertDescription className="mt-2">
              {error}
              <div className="mt-4 flex justify-end">
                <Button onClick={onClose} variant="outline">
                  Close
                </Button>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      </div>
    );
  }

  if (!previewBlob || !transcriptData) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <Alert>
            <AlertTitle>No Document Data</AlertTitle>
            <AlertDescription className="mt-2">
              Document data is not available.
              <div className="mt-4 flex justify-end">
                <Button onClick={onClose} variant="outline">
                  Close
                </Button>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-11/12 h-5/6 rounded-lg shadow-lg flex flex-col border-2">
        <div className="flex justify-between items-center p-2 border-b-2">
          <div className="flex items-center space-x-2">
            <Button onClick={handleZoomOut} variant="outline" size="icon">
              <Minus className="h-4 w-4" />
            </Button>
            <span className="text-sm font-medium">
              {Math.round(zoomLevel * 100)}%
            </span>
            <Button onClick={handleZoomIn} variant="outline" size="icon">
              <Plus className="h-4 w-4" />
            </Button>
            <Button onClick={handleResetZoom} variant="outline" size="icon">
              <RotateCcw className="h-4 w-4" />
            </Button>
            <SelectedSegmentDisplay />
          </div>
          <div className="flex items-center space-x-2">
            <Input
              type="number"
              placeholder={`Page (1-${documentData?.pages.length || 1})`}
              value={pageInput}
              onChange={(e) => setPageInput(e.target.value)}
              className="w-32"
              min={1}
              max={documentData?.pages.length}
            />
            <Button onClick={handleGoToPage} variant="outline">
              Go
            </Button>
          </div>
          <Button onClick={onClose}>Close</Button>
        </div>
        <div className="flex flex-grow overflow-hidden">
          <LeftPanel />
          <RightPanel pdfBlob={previewBlob} />
        </div>
      </div>
    </div>
  );
};

export default PDFViewerV2;
