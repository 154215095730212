import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

// Define the Connection type
type Connection = {
  id: string;
  source: { x: number; y: number };
  target: { x: number; y: number };
  strength: number;
};

// Define the Node type
type Node = {
  id: number;
  x: number;
  y: number;
  size: number;
  speed: number;
  direction: number;
  lifespan: number;
  created: number;
};

const GravixAdvancedLoader = () => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);

  const createNode = useCallback(
    (id: number) => ({
      id,
      x: Math.random() * 80 + 10,
      y: Math.random() * 80 + 10,
      size: Math.random() * 8 + 12, // 12-20px
      speed: Math.random() * 1.5 + 0.5,
      direction: Math.random() * Math.PI * 2,
      lifespan: Math.random() * 3000 + 4000,
      created: Date.now(),
    }),
    []
  );

  // Update connections separately from nodes
  const updateConnections = useCallback((currentNodes: Node[]) => {
    const newConnections = [];
    for (let i = 0; i < currentNodes.length; i++) {
      for (let j = i + 1; j < currentNodes.length; j++) {
        const nodeA = currentNodes[i];
        const nodeB = currentNodes[j];
        const distance = Math.sqrt(
          Math.pow(nodeA.x - nodeB.x, 2) + Math.pow(nodeA.y - nodeB.y, 2)
        );
        if (distance < 35) {
          // Reduced distance threshold for fewer but more meaningful connections
          newConnections.push({
            id: `${nodeA.id}-${nodeB.id}`,
            source: { x: nodeA.x, y: nodeA.y },
            target: { x: nodeB.x, y: nodeB.y },
            strength: Math.max(0.8, 1 - distance / 35),
          });
        }
      }
    }
    setConnections(newConnections);
  }, []);

  useEffect(() => {
    // Initialize nodes
    const initialNodes = Array.from({ length: 10 }, (_, i) => createNode(i));
    setNodes(initialNodes);

    // Node lifecycle and movement
    const nodeInterval = setInterval(() => {
      setNodes((prevNodes) => {
        const now = Date.now();
        const survivingNodes = prevNodes.filter(
          (node) => now - node.created < node.lifespan
        );

        const newNodes = [];
        while (survivingNodes.length + newNodes.length < 10) {
          newNodes.push(createNode(now + newNodes.length));
        }

        const updatedNodes = [...survivingNodes, ...newNodes].map((node) => {
          const dx = Math.cos(node.direction) * node.speed * 0.1;
          const dy = Math.sin(node.direction) * node.speed * 0.1;

          let newX = node.x + dx;
          let newY = node.y + dy;
          let newDirection = node.direction;

          if (newX < 10 || newX > 90) {
            newDirection = Math.PI - newDirection;
            newX = Math.max(10, Math.min(90, newX));
          }
          if (newY < 10 || newY > 90) {
            newDirection = -newDirection;
            newY = Math.max(10, Math.min(90, newY));
          }

          return { ...node, x: newX, y: newY, direction: newDirection };
        });

        updateConnections(updatedNodes);
        return updatedNodes;
      });
    }, 50);

    return () => clearInterval(nodeInterval);
  }, [createNode, updateConnections]);

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="relative w-96 h-96 rounded-lg overflow-hidden">
        {/* SVG Layer for Connections */}
        <svg className="absolute inset-0 w-full h-full" style={{ zIndex: 1 }}>
          <AnimatePresence>
            {connections.map((connection) => (
              <motion.line
                key={connection.id}
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{
                  pathLength: 1,
                  opacity: connection.strength,
                  transition: { duration: 0.2 },
                }}
                exit={{ opacity: 0 }}
                x1={`${connection.source.x}%`}
                y1={`${connection.source.y}%`}
                x2={`${connection.target.x}%`}
                y2={`${connection.target.y}%`}
                stroke="#1A365D"
                strokeWidth="2"
                strokeOpacity={connection.strength}
              />
            ))}
          </AnimatePresence>
        </svg>

        {/* Node Layer */}
        {nodes.map((node) => (
          <motion.div
            key={node.id}
            initial={{ scale: 0, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.3 },
            }}
            exit={{ scale: 0, opacity: 0 }}
            className="absolute rounded-full bg-primary"
            style={{
              left: `${node.x}%`,
              top: `${node.y}%`,
              width: node.size,
              height: node.size,
              marginLeft: -node.size / 2,
              marginTop: -node.size / 2,
              zIndex: 2,
            }}
          />
        ))}
      </div>

      {/* Text elements */}
      <motion.div
        className="mt-8 text-4xl font-bold text-primary tracking-wider"
        animate={{
          opacity: [0.7, 1, 0.7],
          transition: {
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          },
        }}
      >
        GRAVIX
      </motion.div>
      {/* <motion.div
        className="mt-2 text-sm text-charcoal tracking-widest"
        animate={{
          opacity: [0.5, 0.8, 0.5],
          transition: {
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          },
        }}
      >
        ANALYZING PATTERNS
      </motion.div> */}
    </div>
  );
};

export default GravixAdvancedLoader;
