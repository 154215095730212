import React from "react";

interface LinkedDocument {
  id: string;
  title: string;
  type: string;
}

interface LinkedDocumentsProps {
  documents: LinkedDocument[];
  caseId: string;
}

const LinkedDocuments: React.FC<LinkedDocumentsProps> = ({
  documents,
  caseId,
}) => {
  return (
    <ul className="space-y-2">
      {documents.map((doc) => (
        <li key={doc.id} className="bg-light-gray p-2 rounded">
          <a
            href={`/cases/${caseId}/documents/${doc.id}`}
            className="text-primary hover:underline"
          >
            {doc.title} ({doc.type})
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LinkedDocuments;
