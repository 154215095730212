import React from "react";

interface Task {
  id: string;
  title: string;
}

interface CurrentActionsProps {
  tasks: Task[];
}

const CurrentActions: React.FC<CurrentActionsProps> = ({ tasks }) => {
  return (
    <ul className="space-y-2">
      {tasks.map((task) => (
        <li key={task.id} className="bg-light-gray p-2 rounded">
          {task.title}
        </li>
      ))}
    </ul>
  );
};

export default CurrentActions;
