import GravixLoader from "@/components/GravixLoader";
import { useAuthStore } from "@/store/authStore";
import { motion } from "framer-motion";
import { Building2, Shield, User, Users } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Navigate, NavLink, Outlet } from "react-router-dom";

interface MenuItem {
  path: string;
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const menuItems: MenuItem[] = [
  { path: "/settings/user", label: "User Profile", icon: User },
  { path: "/settings/tenant", label: "Tenant", icon: Building2 },
  { path: "/settings/user-management", label: "User Management", icon: Users },
  {
    path: "/settings/case-permissions",
    label: "Case Permissions",
    icon: Shield,
  },
];

const SettingsLayout: React.FC = () => {
  const { isLoggedIn, isInitializing, user } = useAuthStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isInitializing) {
      setIsLoading(false);
    }
  }, [isInitializing]);

  if (isInitializing || isLoading) {
    return <GravixLoader />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex flex-grow bg-light-gray">
      <nav className="w-64 bg-white p-6 shadow-lg">
        <h2 className="text-2xl font-bold text-primary mb-6">Settings</h2>
        <ul className="space-y-2">
          {menuItems.map((item) => {
            // Only show Tenant and User Management for sys_admin and tenant_admin
            if (
              (item.label === "Tenant" || item.label === "User Management") &&
              user?.role === "tenant_user"
            ) {
              return null;
            }

            // Only show Tenants for sys_admin
            if (item.label === "Tenant" && user?.role !== "sys_admin") {
              return null;
            }

            return (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center p-3 rounded-lg transition-all duration-200 ${
                      isActive
                        ? "bg-accent text-white shadow-md"
                        : "text-charcoal hover:bg-primary hover:text-white"
                    }`
                  }
                >
                  <item.icon className="w-5 h-5 mr-3" />
                  <span>{item.label}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="flex-1 p-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Outlet />
        </motion.div>
      </div>
    </div>
  );
};

export default SettingsLayout;
