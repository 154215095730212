import { create } from "zustand";
import { toast } from "@/hooks/use-toast";
import { User } from "@/types/shared";

export const TOKEN_KEYS = {
  ACCESS: "access_token",
  REFRESH: "refresh_token",
} as const;

export const getToken = (key: keyof typeof TOKEN_KEYS) =>
  localStorage.getItem(TOKEN_KEYS[key]);
const setToken = (key: keyof typeof TOKEN_KEYS, value: string) =>
  localStorage.setItem(TOKEN_KEYS[key], value);
const removeToken = (key: keyof typeof TOKEN_KEYS) =>
  localStorage.removeItem(TOKEN_KEYS[key]);

interface AuthStore {
  isLoggedIn: boolean;
  user: User | null;
  isLoggingIn: boolean;
  isInitializing: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshToken: () => Promise<void>;
  initialize: () => Promise<void>;
  canAccessTenantSettings: () => boolean;
  signup: (params: SignupParams) => Promise<void>;
}

const API_URL = process.env.API_URL;

interface LoginUser {
  email: string;
  password: string;
}

interface LoginRequestBody {
  user: LoginUser;
}

interface SignupParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  isLoggedIn: false,
  user: null,
  isLoggingIn: false,
  isInitializing: true,

  initialize: async () => {
    set({ isInitializing: true });
    const refreshTokenExists = getToken("REFRESH");
    if (refreshTokenExists) {
      try {
        await get().refreshToken();
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    }
    set({ isInitializing: false });
  },

  login: async (email: string, password: string) => {
    set({ isLoggingIn: true });
    try {
      const body: LoginRequestBody = {
        user: { email, password },
      };

      const response = await fetch(`${API_URL}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      setToken("ACCESS", data.access_token);
      setToken("REFRESH", data.refresh_token);
      set({
        isLoggedIn: true,
        user: {
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          avatarUrl: data.user.avatar_url,
          role: data.user.role,
        },
        isLoggingIn: false,
      });
    } catch (error) {
      set({ isLoggingIn: false });
      let errorMessage = "Authentication failed. Please try again.";
      if (error instanceof Error) {
        if (error.message === "Failed to fetch") {
          errorMessage =
            "Unable to connect to the server. Please check your internet connection and try again.";
        } else {
          errorMessage = error.message;
        }
      }
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
      throw error;
    }
  },

  logout: async () => {
    try {
      const response = await fetch(`${API_URL}/auth/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken("ACCESS")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      removeToken("ACCESS");
      removeToken("REFRESH");
      set({ isLoggedIn: false, user: null });
    } catch (error) {
      console.error("Logout failed:", error);
      toast({
        title: "Error",
        description: "Logout failed. Please try again.",
        variant: "destructive",
      });
    }
  },

  refreshToken: async () => {
    try {
      const refreshToken = getToken("REFRESH");
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const response = await fetch(`${API_URL}/auth/refresh`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Token refresh failed");
      }

      const data = await response.json();
      setToken("ACCESS", data.access_token);
      if (data.refresh_token) {
        setToken("REFRESH", data.refresh_token);
      }

      if (data.user) {
        set({
          isLoggedIn: true,
          user: {
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            avatarUrl: data.user.avatar_url,
            role: data.user.role,
          },
        });
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      set({ isLoggedIn: false, user: null });
      toast({
        title: "Session Expired",
        description: "Please log in again.",
        variant: "destructive",
      });
    }
  },

  canAccessTenantSettings: () => {
    const { user } = get();
    return user?.role === "tenant_admin" || user?.role === "sys_admin";
  },

  signup: async ({ firstName, lastName, email, password }: SignupParams) => {
    try {
      const response = await fetch(`${API_URL}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: {
            email,
          },
          new_params: {
            first_name: firstName,
            last_name: lastName,
            password,
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();

      // Set tokens after successful signup
      setToken("ACCESS", data.access_token);
      setToken("REFRESH", data.refresh_token);

      // Update store state
      set({
        isLoggedIn: true,
        user: {
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          avatarUrl: data.user.avatar_url,
          role: data.user.role,
        },
      });

      toast({
        title: "Success",
        description: "Account created successfully.",
      });
    } catch (error) {
      let errorMessage = "Registration failed. Please try again.";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
      throw error;
    }
  },
}));
